import { SHORT_DATE_FORMAT } from "lib/constants";
import { dayjs } from "utils";
import prettyBytes from "pretty-bytes";

const documentsResolver = (documents) => {
  const getSourceType = (document) => {
    if (document.pdf_key) {
      return "kfiPdfDownload";
    }

    return "offerLetterPdfDownload";
  };

  const documentHash = (document) => {
    return {
      title: document.label,
      size:
        document.document_size && `(${prettyBytes(document.document_size)})`,
      href: document.href,
      createdAt: dayjs.utc(document.created_at).format(SHORT_DATE_FORMAT),
      documentId: document.id,
      sourceType: getSourceType(document),
      pdfKey: document.pdf_key,
      referenceNo: document.illustration_reference,
      hmlAccountNumber: document.hml_account_number,
    };
  };

  return documents.map(documentHash);
};

export default documentsResolver;
