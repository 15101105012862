import { forwardRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames/bind";
import FormField from "../";
import styles from "./RadioGroup.module.scss";

const cx = classNames.bind(styles);

const Radio = forwardRef((props, ref) => {
  const handleBlur = (e) => {
    onBlur && onBlur(e);
    setIsFocused(false);
  };

  const handleFocus = (e) => {
    onFocus && onFocus(e);
    setIsFocused(true);
  };

  const {
    checked,
    group,
    hasError,
    isDisabled,
    isFrozen,
    isRequired,
    label,
    onBlur,
    onChange,
    onFocus,
    value,
  } = props;

  const [isFocused, setIsFocused] = useState(false);

  const attrs = {
    checked,
    className: cx("input", {
      checked,
      hasError,
      isDisabled,
      isFrozen,
    }),
    disabled: isDisabled || isFrozen,
    id: `${group}_${value}`,
    name: group,
    onBlur: handleBlur,
    onChange,
    onFocus: handleFocus,
    required: isRequired,
    type: "radio",
    value,
    ref,
  };

  return (
    <div
      className={cx("container", {
        checked,
        hasError,
        isDisabled,
        isFocused,
        isFrozen,
      })}
    >
      <input {...attrs} />
      <label htmlFor={attrs.id}>{label}</label>
    </div>
  );
});

Radio.propTypes = {
  checked: PropTypes.bool,
  group: PropTypes.string.isRequired,
  hasError: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isFrozen: PropTypes.bool,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  value: PropTypes.string.isRequired,
};

Radio.displayName = "Radio";

let RadioGroup = (props, ref) => {
  const { children, controlledValue, initialValue, onChange, radios } = props;

  const handleChange = (e) => {
    onChange && onChange(e);
    setChecked(e.target.value);
  };

  const [checked, setChecked] = useState(initialValue);

  useEffect(() => {
    // Allows us to use "" as a value
    if (typeof controlledValue === "string") {
      setChecked(controlledValue);
    }
  }, [controlledValue]);

  const radioProps = (radio) => ({
    ...props,
    ...radio,
    checked: checked === radio.value,
    onChange: handleChange,
    ref,
  });

  return (
    <FormField {...props} role="radiogroup">
      {radios.map((radio) => (
        <Radio {...radioProps(radio)} key={radio.value} />
      ))}
      {children}
    </FormField>
  );
};

RadioGroup = forwardRef(RadioGroup);

RadioGroup.propTypes = {
  children: PropTypes.node,
  controlledValue: PropTypes.string,
  group: PropTypes.string.isRequired,
  hasError: PropTypes.bool,
  initialValue: PropTypes.string,
  isDisabled: PropTypes.bool,
  isFrozen: PropTypes.bool,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func,
  radios: PropTypes.array.isRequired,
};

export { RadioGroup as default, Radio };
