import { PropsWithChildren } from "react";
import { useSession } from "next-auth/react";
import { useRouter } from "next/router";
import { linkFor } from "utils";
import classNames from "classnames/bind";
import ContentLoader from "react-content-loader";
import AuthenticatedLayout from "layouts/Authenticated";
import UnauthenticatedLayout from "layouts/Unauthenticated";
import Footer from "./Footer";
import Header from "./Header";
import Meta from "./Meta";
import PreloadFonts from "./PreloadFonts";
import styles from "./Layout.module.scss";

const cx = classNames.bind(styles);

const unprotectedRoutes = [
  "/login",
  "/login-help",
  "/login-admin",
  "/forgot-password",
  "/accept-invitation",
  "/unlock-account",
];

const fonts = [
  {
    href: "/fonts/subset-SourceSansPro-Regular.woff2",
    type: "font/woff2",
  },
  {
    href: "/fonts/subset-SourceSansPro-Bold.woff2",
    type: "font/woff2",
  },
];

export interface LayoutProps {
  base: {
    header: object;
    meta: object;
    footer: object;
  };
  isError?: boolean;
}

const Layout = (props: PropsWithChildren<LayoutProps>) => {
  const { base, isError, children } = props;
  const { data: session, status } = useSession();
  const loading = status === "loading";
  const router = useRouter();

  if (loading) {
    return (
      <ContentLoader uniqueKey="layout" viewBox="0 0 1440 221">
        <circle cx="1069" cy="17" r="9" />
        <circle cx="1209" cy="17" r="9" />
        <circle cx="1324" cy="17" r="9" />
        <rect x="1092" y="10" width="71" height="14" />
        <rect x="1232" y="10" width="46" height="14" />
        <rect x="1347" y="10" width="77" height="14" />
        <rect x="20" y="10" width="335" height="14" />
        <rect x="140" y="148" width="189" height="45" />
        <rect x="1347" y="76" width="37" height="14" />
        <rect x="1398" y="76" width="20" height="2" />
        <rect x="1397" y="82" width="20" height="2" />
        <rect x="1397" y="88" width="20" height="2" />
        <rect x="20" y="64" width="99" height="38" />
        <rect y="32" width="100%" height="1" />
        <rect y="220" width="100%" height="1" />
      </ContentLoader>
    );
  }

  if (
    !session &&
    router.isReady && // Client-side only
    !unprotectedRoutes.includes(router.pathname)
  ) {
    router.push(linkFor("sessionExpired", { returnPath: router.asPath }));
  }

  const isAuthenticated = !!(
    session && !unprotectedRoutes.includes(router.pathname)
  );

  const LayoutContainer = isAuthenticated
    ? AuthenticatedLayout
    : UnauthenticatedLayout;

  return (
    <div
      className={cx(styles.layout, {
        isAuthenticated,
        isError: isError,
      })}
    >
      {base && <Meta {...base.meta} />}
      <PreloadFonts fonts={fonts} />
      <a className={styles.skipLink} href="#main">
        Skip to main content
      </a>
      {base && <Header {...base.header} isAuthenticated={isAuthenticated} />}
      <main id="main">
        <LayoutContainer {...props}>{children}</LayoutContainer>
      </main>
      {base && <Footer {...base.footer} isAuthenticated={isAuthenticated} />}
    </div>
  );
};

export default Layout;
