import { useState } from "react";
import { useRouter } from "next/router";
import classNames from "classnames/bind";
import { Button, FormField, Panel } from "components";
import { linkFor } from "utils";
import styles from "./SearchPanel.module.scss";

const cx = classNames.bind(styles);

const SearchPanel = () => {
  const [value, setValue] = useState("");
  const router = useRouter();

  const inputChangeHandler = (e) => {
    setValue(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    router.push({
      pathname: linkFor("clients"),
      query: {
        search: encodeURIComponent(value),
      },
    });
  };

  return (
    <Panel size="small">
      <div className={cx("container")}>
        <header>
          <h3 className={cx("title")}>Search</h3>
        </header>
        <form onSubmit={handleSubmit}>
          <p>Quickly find clients, illustrations and applications</p>
          <FormField.Search
            value={value}
            onChange={inputChangeHandler}
            labelledby="searchPanelLabel"
          />
          <p className={cx("hint")}>
            Search by name, postcode, illustration number or application number
          </p>
          <Button>Search</Button>
        </form>
      </div>
    </Panel>
  );
};

export default SearchPanel;
