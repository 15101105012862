import {
  ageNextBirthdayResolver,
  productListPageMetaResolver,
  productsResolver,
} from "resolvers";

const illustrationProductListPageResolver = ({ productResponse }) => {
  return {
    products: productsResolver(productResponse),
    ageNextBirthday: ageNextBirthdayResolver(
      productResponse.data.client.active_application.applicants_data
    ),
    pageMeta: productListPageMetaResolver(productResponse),
  };
};

export default illustrationProductListPageResolver;
