import PropTypes from "prop-types";
import { useState } from "react";
import classNames from "classnames/bind";
import { oneOfProps } from "utils/propTypeValidations";
import DropdownControl from "../DropdownControl";
import styles from "./SortControl.module.scss";

const cx = classNames.bind(styles);

const SortControl = ({
  align,
  buttonAriaLabel = "Sort",
  buttonText = "Sort",
  buttonTheme = "secondary",
  dropdownTitle,
  formAriaLabel = "Sort Form",
  fullWidth,
  initialValue = "",
  isDisabled,
  options,
  optionGroups,
  onSubmit,
}) => {
  const [value, setValue] = useState(initialValue);

  const optionItem = (option, index) => {
    return (
      <li key={index} className={cx("listItem")}>
        <input
          type="radio"
          id={option.key}
          key={`sortControl-${option.key}`}
          data-testid={`sortControlInput-${option.key}`}
          checked={value === option.key}
          onChange={() => {
            setValue(option.key);
          }}
        />
        <label className={cx("radioLabel")} htmlFor={option.key}>
          {option.label}
        </label>
      </li>
    );
  };

  const optionsList = (options) => {
    return <ul className={cx("controlsWrapper")}>{options.map(optionItem)}</ul>;
  };

  const optionsMarkup = () => {
    if (!optionGroups) {
      return optionsList(options);
    }
    return optionGroups.map((group, index) => (
      <div key={`sortControl-group${index}`}>
        <h4 className={cx("optionGroupTitle")}>{group.title}</h4>
        {optionsList(group.options)}
      </div>
    ));
  };

  const submitHandler = () => {
    const queryObject = {
      sort: value,
    };

    onSubmit(queryObject);
  };

  return (
    <DropdownControl
      align={align}
      buttonAriaLabel={buttonAriaLabel}
      buttonText={buttonText}
      buttonTheme={buttonTheme}
      formAriaLabel={formAriaLabel}
      fullWidth={fullWidth}
      icon="Sort"
      dropdownTitle={dropdownTitle}
      isDisabled={isDisabled}
      onSubmit={submitHandler}
      optionItems={optionsMarkup()}
      submitIsDisabled={!value}
    />
  );
};

SortControl.propTypes = {
  optionsOrOptionGroups: (...args) =>
    oneOfProps(args, ["options", "optionGroups"], true),
  align: PropTypes.string,
  buttonAriaLabel: PropTypes.string,
  buttonText: PropTypes.string,
  buttonTheme: PropTypes.oneOf(["primary", "secondary"]),
  dropdownTitle: PropTypes.string.isRequired,
  formAriaLabel: PropTypes.string,
  fullWidth: PropTypes.bool,
  initialValue: PropTypes.string,
  isDisabled: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({ key: PropTypes.string, label: PropTypes.string })
  ),
  optionGroups: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          key: PropTypes.string,
          label: PropTypes.string,
        })
      ),
    })
  ),
  onSubmit: PropTypes.func.isRequired,
};

export default SortControl;
