import {
  FALSY_STRING,
  ILLUSTRATION_DETAILS_TYPE_AMENDMENT,
  MAXIMUM_AMOUNT_AVAILABLE,
  ZERO_AMOUNT,
} from "lib/constants";
import { displayAmountWithCurrency } from "utils";
import { formatCurrency } from "utils";

const editIllustrationPageResolver = ({ application }, type) => {
  // Applications created in KFIv1 won't have client loan information
  // default values will be used in that case
  const defaultLoanInfo = {
    cash_reserve_maximum: false,
    cash_reserve: ZERO_AMOUNT,
    loan_amount_maximum: true,
  };
  const loanInfo = application.client.loan_information || defaultLoanInfo;

  const cashReserve = loanInfo.cash_reserve_maximum
    ? MAXIMUM_AMOUNT_AVAILABLE
    : loanInfo.cash_reserve === ZERO_AMOUNT
    ? FALSY_STRING
    : formatCurrency(loanInfo.cash_reserve, {
        withDecimal: false,
      });

  const initialAdvanceAmount = loanInfo.loan_amount_maximum
    ? MAXIMUM_AMOUNT_AVAILABLE
    : formatCurrency(loanInfo.loan_amount, {
        withDecimal: false,
      });

  const propertyValue = application.valuation.property_valuation
    ? application.valuation.property_valuation
    : application.illustration.property_value;

  const resolvedData = {
    adviceFee: formatCurrency(application.illustration.commission_fee, {
      withDecimal: false,
    }),
    cashReserve,
    clientId: application.client.id,
    initialAdvanceAmount,
    propertyValue: propertyValue.toString(),
    tableData: [
      {
        postcode: application.property.postcode,
        region: application.region,
        residenceStatus: application.property.residence_status,
      },
    ],
    recommended: application.illustration.recommended,
  };

  if (type === ILLUSTRATION_DETAILS_TYPE_AMENDMENT) {
    resolvedData.tableData[0].valuedAt = displayAmountWithCurrency(
      propertyValue.toString()
    );
  }

  return resolvedData;
};

export default editIllustrationPageResolver;
