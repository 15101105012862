import Head from "next/head";
import PropTypes from "prop-types";
import { META_TITLE_FALLBACK } from "lib/constants";

const Meta = ({ title = META_TITLE_FALLBACK, description }) => (
  <Head>
    <title>{title}</title>
    {description && <meta name="description" content={description} />}

    <link
      rel="apple-touch-icon"
      sizes="180x180"
      href="/favicons/apple-touch-icon.png"
    />
    <link
      rel="icon"
      type="image/png"
      sizes="32x32"
      href="/favicons/favicon-32x32.png"
    />
    <link
      rel="icon"
      type="image/png"
      sizes="16x16"
      href="/favicons/favicon-16x16.png"
    />
    <link rel="shortcut icon" href="/favicons/favicon.ico" />
    <link rel="manifest" href="/site.webmanifest" />
    <link
      rel="mask-icon"
      href="/favicons/safari-pinned-tab.svg"
      color="var(--cl-canadaLifeRed, #ba0c2f)"
    />
    <meta name="msapplication-TileColor" content="#000000" />
    <meta name="theme-color" content="#000000" />
  </Head>
);

Meta.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};

export default Meta;
