import { useRef, useState } from "react";
import PropTypes from "prop-types";
import FormField from "../";
import classNames from "classnames/bind";
import { labelOrLabelledby } from "utils/propTypeValidations";
import styles from "./Switch.module.scss";

const cx = classNames.bind(styles);

const Switch = (props) => {
  const handleBlur = (e) => {
    onBlur && onBlur(e);
    setIsFocused(false);
  };

  const handleFocus = (e) => {
    onFocus && onFocus(e);
    setIsFocused(true);
  };

  const handleChange = (e) => {
    onChange && onChange(e);
    setChecked(e.target.checked);
  };

  const {
    checked: initialValue,
    fullWidth = false,
    hasError = false,
    isDisabled = false,
    isRequired = false,
    label,
    labelledby,
    offLabel,
    onBlur,
    onChange,
    onFocus,
    onLabel,
    placeholder,
  } = props;

  const inputRef = useRef(null);
  const [checked, setChecked] = useState(!!initialValue);
  const [isFocused, setIsFocused] = useState(false);

  const attrs = {
    ["aria-labelledby"]: labelledby,
    checked,
    className: "input",
    disabled: isDisabled,
    onBlur: handleBlur,
    onChange: handleChange,
    onFocus: handleFocus,
    placeholder,
    required: isRequired,
    type: "checkbox",
  };

  return (
    <FormField {...props} label={null}>
      <label
        className={cx("container", {
          checked,
          fullWidth,
          hasError,
          isDisabled,
          isFocused,
        })}
      >
        <input {...attrs} ref={inputRef} />
        {label && <span className={cx("label")}>{label}</span>}
        <div className={cx("switchWrapper")}>
          {offLabel && <span className={cx("offLabel")}>{offLabel}</span>}
          <div className={cx("switch")} />
          {onLabel && <span className={cx("onLabel")}>{onLabel}</span>}
        </div>
      </label>
    </FormField>
  );
};

Switch.propTypes = {
  labelOrLabelledby,
  checked: PropTypes.bool,
  fullWidth: PropTypes.bool,
  hasError: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  labelledby: PropTypes.string,
  offLabel: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onLabel: PropTypes.string,
  placeholder: PropTypes.string,
};

export default Switch;
