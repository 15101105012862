import { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames/bind";
import FormField from "../";
import { labelOrLabelledby } from "utils/propTypeValidations";
import styles from "./Percentage.module.scss";

const cx = classNames.bind(styles);

const Percentage = (props) => {
  const handleChange = (e) => {
    onBeforeChange && onBeforeChange(e);

    // Prevent the browser UI from showing unsupported values as you type
    if (e.target.value > 100) {
      e.target.value = 100;
    }

    if (e.target.value < 0) {
      e.target.value = 0;
    }

    setValue(e.target.value);

    onAfterChange && onAfterChange(e);
  };

  const {
    children,
    hasError = false,
    isDisabled = false,
    isRequired = false,
    labelledby,
    onAfterChange,
    onBeforeChange,
    placeholder,
    value: initialValue = "",
  } = props;

  const [value, setValue] = useState(initialValue);

  const attrs = {
    ["aria-labelledby"]: labelledby,
    className: cx("input", {
      hasError,
      hasValue: value,
      isDisabled,
    }),
    defaultValue: value,
    disabled: isDisabled,
    min: 1,
    max: 100,
    onChange: handleChange,
    placeholder,
    required: isRequired,
    type: "number",
  };

  return (
    <FormField {...props}>
      <div className={cx("container")}>
        <input {...attrs} />
      </div>
      {children}
    </FormField>
  );
};

Percentage.propTypes = {
  labelOrLabelledby,
  children: PropTypes.node,
  hasError: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  labelledby: PropTypes.string,
  onAfterChange: PropTypes.func,
  onBeforeChange: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.number,
};

export default Percentage;
