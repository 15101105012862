// Page-level Resolvers
export { default as acceptInvitationPageResolver } from "./page/acceptInvitation";
export { default as adviserDetailsPageResolver } from "./page/client/adviserDetails";
export { default as applicantsResolver } from "./page/mortgageApplication/applicants";
export { default as applicationDetailsResolver } from "./page/mortgageApplication/applicationDetails";
export { default as applicationsPageResolver } from "./page/applications";
export { default as changePasswordPageResolver } from "./page/changePassword";
export { default as clientDetailsPageResolver } from "./page/client/clientDetails";
export { default as clientOverviewPageResolver } from "./page/clientOverview";
export { default as clientsPageResolver } from "./page/clients";
export { default as dashboardPageResolver } from "./page/dashboard";
export { default as additionalDocumentsResolver } from "./page/mortgageApplication/additionalDocuments";
export { default as documentsResolver } from "./page/mortgageApplication/documents";
export { default as editIllustrationSummaryPageResolver } from "./page/editIllustrationSummary";
export { default as forgotPasswordPageResolver } from "./page/forgotPassword";
export { default as illustrationDetailsResolver } from "./page/mortgageApplication/illustrationDetails";
export { default as illustrationSummaryPageResolver } from "./page/illustrationSummary";
export { default as illustrationProductListPageResolver } from "./page/illustrationProductList";
export { default as lendingCheckPageResolver } from "./page/client/lendingCheck";
export { default as loanDetailsPageResolver } from "./page/client/loanDetails";
export { default as productPageResolver } from "./page/product";
export { default as productListPageResolver } from "./page/productList";
export { default as propertyDetailsPageResolver } from "./page/client/propertyDetails";
export { default as propertyDetailsResolver } from "./page/mortgageApplication/propertyDetails";
export { default as stagesResolver } from "./page/mortgageApplication/stages";
export { default as editIllustrationPageResolver } from "./page/editIllustration";
export { default as profilePageResolver } from "./page/profile";
export { default as furtherAdvancesListPageResolver } from "./page/furtherAdvancesList";
export { default as furtherAdvanceDetailsPageResolver } from "./page/furtherAdvanceDetails";

// Base Resolvers
export { default as baseResolver } from "./base";
export { default as footerResolver } from "./base/footer";
export { default as headerResolver } from "./base/header";
export { default as metaResolver } from "./base/meta";

// Settings Resolvers
export { default as settingsResolver } from "./settings";

// Data Resolvers
export { default as adviserResolver } from "./data/adviser";
export { default as ageNextBirthdayResolver } from "./data/ageNextBirthday";
export { default as applicationResolver } from "./data/application";
export { default as applicationsResolver } from "./data/applications";
export { default as applicationSummaryResolver } from "./data/applicationSummary";
export { default as applicationSummaryItemResolver } from "./data/applicationSummaryItem";
export { default as blogDataResolver } from "./data/blogData";
export { default as borrowerResolver } from "./data/borrower";
export { default as borrowersResolver } from "./data/borrowers";
export { default as clientResolver } from "./data/client";
export { default as clientLoanResolver } from "./data/clientLoan";
export { default as clientPropertyResolver } from "./data/clientProperty";
export { default as clientsResolver } from "./data/clients";
export { default as clubsResolver } from "./data/clubs";
export { default as documentResolver } from "./data/document";
export { default as dateResolver } from "./data/date";
export { default as filtersResolver } from "./data/filters";
export { default as illustrationResolver } from "./data/illustration";
export { default as illustrationsResolver } from "./data/illustrations";
export { default as pagedDataMetaResolver } from "./data/pagedDataMetaResolver";
export { default as postcodesLendingCriteriaCheckResolver } from "./data/postcodesLendingCriteriaCheck";
export { default as productListPageMetaResolver } from "./data/productListPageMetaResolver";
export { default as productResolver } from "./data/product";
export { default as productCashbackResolver } from "./data/productCashback";
export { default as productSummaryResolver } from "./data/productSummary";
export { default as productsResolver } from "./data/products";
export { default as profileResolver } from "./data/profile";
export { default as rollupResolver } from "./data/rollup";
export { default as rollupsResolver } from "./data/rollups";

// Present for API
export { default as presentBorrowerForApiResolver } from "./forApi/presentBorrower";
export { default as presentIllustrationRequestForApi } from "./forApi/presentIllustrationRequest";
export { default as presentLoanInformationForApiResolver } from "./forApi/presentLoanInformation";
export { default as presentPropertyForApiResolver } from "./forApi/presentProperty";
