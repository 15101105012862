import { booleanToText, dayjs, displayAmountWithCurrency } from "utils";

const illustrationResolver = (data) => ({
  id: data.id,
  referenceNo: data.illustration_reference,
  date: {
    dateTime: data.created_on,
    formatted: dayjs(data.created_on).format("DD MMM YYYY - HH:mm"),
  },
  doNotAllowToLoadInOls: data.do_not_allow_to_load_in_ols,
  product: data.product_name,
  propertyValue: data.property_value,
  interestRate: `${data.loan_rate_mer}%`,
  initialAdvance: displayAmountWithCurrency(data.initial_advance),
  cashReserveFacility: parseFloat(data.cash_reserve)
    ? `Yes - ${displayAmountWithCurrency(data.cash_reserve)}`
    : "No - £0",
  inheritanceGuarantee: data.inheritance_guarantee
    ? `Yes - ${data.inheritance_guarantee}%`
    : "No - 0%",
  recommended: booleanToText(data.recommended),
});

export default illustrationResolver;
