import { borrowerResolver } from "resolvers";

const borrowersResolver = (data) => {
  const ordered = data.sort((_a, b) => (b.primary_borrower ? 1 : -1));
  const borrowers = ordered.map(borrowerResolver);

  const borrowerNames = borrowers.map(({ fullName }) => fullName);
  borrowers.fullName = borrowerNames.join(" and ");

  return borrowers;
};

export default borrowersResolver;
