import { SHORT_DATE_FORMAT } from "lib/constants";
import { dayjs, displayAmountWithCurrency } from "utils";

const illustrationDetailsResolver = (application) => {
  const illustrationHash = (illustration) => {
    return {
      referenceNo: illustration.id.toString(),
      status: illustration.status,
      createdAt: dayjs.utc(illustration.created_on).format(SHORT_DATE_FORMAT),
      product: illustration.product_name,
      loanAmount: displayAmountWithCurrency(illustration.initial_advance),
      pdfKey: illustration.pdf_key,
    };
  };

  const addStatusToIllustration = (illustration, status) => {
    illustration.status = status;
    return illustration;
  };

  const illustrations = application.client.illustrations.map((illustration) => {
    if (illustration.id === application.illustration.id)
      addStatusToIllustration(illustration, "Active");

    if (illustration.id === application.previous_illustration?.id)
      addStatusToIllustration(illustration, "Previous");

    return illustration;
  });

  return illustrations.map(illustrationHash);
};

export default illustrationDetailsResolver;
