import { headerResolver, metaResolver, footerResolver } from "resolvers";

const baseResolver = (data = {}) => {
  return {
    header: headerResolver(data.header),
    footer: footerResolver(data.footer),
    meta: metaResolver(data.meta),
  };
};

export default baseResolver;
