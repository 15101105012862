import { PropsWithChildren } from "react";
import Link from "next/link";
import classNames from "classnames/bind";
import { isExternalLink } from "utils";
import * as icons from "lib/icons";
import { ICONS } from "lib/constants";
import styles from "./Button.module.scss";

export const SIZES = ["large", "small"];
export const THEMES = [
  "primary",
  "secondary",
  "tertiary",
  "negative",
  "text",
  "alternative",
];
export { ICONS };

const cx = classNames.bind(styles);

interface ButtonAttributes {
  "aria-label"?: ButtonProps["ariaLabel"];
  className?: string;
  disabled?: ButtonProps["isDisabled"];
  onClick?: ButtonProps["onClick"];
  "data-testid"?: ButtonProps["testId"];
  type?: ButtonProps["type"];
  rel?: string;
  target?: "_self" | "_blank" | "_parent" | "_top";
}

interface ButtonProps {
  additionalClasses?: string;
  ariaLabel?: string;
  customClass?: string;
  fullWidth?: boolean;
  href?: string | Record<any, any>;
  icon?: string;
  isDisabled?: boolean;
  onClick?: (...args: any) => any;
  prependIcon?: boolean;
  size?: (typeof SIZES)[number];
  testId?: string;
  theme?: (typeof THEMES)[number];
  type?: "button" | "submit" | "reset";
  target?: ButtonAttributes["target"];
}

const Button = ({
  ariaLabel,
  children,
  customClass,
  fullWidth = false,
  href,
  icon,
  isDisabled = false,
  onClick,
  prependIcon = false,
  size = "large",
  testId,
  theme = "primary",
  type,
  target,
}: PropsWithChildren<ButtonProps>) => {
  const attrs: ButtonAttributes = {
    "aria-label": ariaLabel,
    className: cx(
      "button",
      customClass,
      { fullWidth, isDisabled, icon, prependIcon },
      size,
      theme
    ),
    disabled: isDisabled,
    onClick,
    "data-testid": testId,
    type,
    target,
  };

  if (isExternalLink(href)) {
    attrs.rel = "noopener noreferrer";
    attrs.target = target || "_blank";
  }

  // eslint-disable-next-line security/detect-object-injection
  const Icon = icons[icon];

  if (!href) {
    return (
      <button {...attrs}>
        <span className={cx("buttonContent")}>{children}</span>
        {icon && <Icon />}
      </button>
    );
  }

  return (
    <Link href={href} {...attrs}>
      <span className={cx("buttonContent")}>{children}</span>
      {icon && <Icon />}
    </Link>
  );
};

export default Button;
