import { useState } from "react";
import classNames from "classnames/bind";
import FormField from "..";
import styles from "./Email.module.scss";

const cx = classNames.bind(styles);

export interface EmailProps {
  errorText: string;
  errorTextTestId: string;
  hasError: boolean;
  isDisabled?: boolean;
  isRequired: boolean;
  label: string;
  labelledby?: string;
  labelOrLabelledby?: "label" | "labelledby";
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  showRequiredText: boolean;
  testId: string;
  value: string;
}

const Email = (props: EmailProps) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange && onChange(e);
    setValue(e.target.value);
  };

  const {
    errorTextTestId,
    hasError = false,
    isDisabled = false,
    isRequired = false,
    labelledby,
    onChange,
    placeholder,
    value: initialValue = "",
    testId,
  } = props;

  const [value, setValue] = useState<string>(initialValue);

  const attrs = {
    ["aria-labelledby"]: labelledby,
    className: cx("input", { hasError, hasValue: value, isDisabled }),
    disabled: isDisabled,
    onChange: handleChange,
    placeholder,
    required: isRequired,
    type: "email",
    value,
    "data-testid": testId,
    errortexttestid: errorTextTestId,
  };

  return (
    <FormField {...props}>
      <input {...attrs} />
    </FormField>
  );
};

export default Email;
