import Head from "next/head";
import PropTypes from "prop-types";

const PreloadFonts = ({ fonts }) => (
  <Head>
    {fonts.map(({ href, type }, i) => (
      <link
        as="font"
        crossOrigin="anonymous"
        href={href}
        key={`font-link-${i}`}
        rel="preload"
        type={type}
      />
    ))}
  </Head>
);

PreloadFonts.propTypes = {
  fonts: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string,
      type: PropTypes.oneOf([
        "font/otf",
        "font/ttf",
        "font/woff",
        "font/woff2",
      ]),
    })
  ).isRequired,
};

export default PreloadFonts;
