import { useRef } from "react";
import { useSession } from "next-auth/react";
import useErrorNoticeVisibility from "hooks/useErrorNoticeVisibility";
import authoriseApplicationModel from "models/oauth/authoriseApplication";
import { Button, ErrorNoticeBanner } from "components";
import classNames from "classnames/bind";
import styles from "./ThirdParty.module.scss";
import {
  CanadaLifeStackedLogo,
  ArrowBack,
  ArrowForward,
  Server,
} from "lib/icons";

const cx = classNames.bind(styles);

interface ThirdPartyProps {
  clientId: string;
  redirectUri: string;
  state?: string;
}

const ThirdParty = (props: ThirdPartyProps) => {
  const { clientId, redirectUri, state } = props;
  const { data: session } = useSession();
  const noticeBanner = useRef<HTMLDivElement>();

  // TODO: update when useErrorNoticeVisibility is TS
  const [errors, setErrors] = useErrorNoticeVisibility(
    noticeBanner
  ) as unknown as [string[], (a: string[]) => void];

  const authoriseButtonHandler = async (e) => {
    e.preventDefault();
    const response = await authoriseApplicationModel({
      clientId,
      redirectUri,
      state,
      session,
    });

    if (response.errors) {
      setErrors(response.errors);
      return;
    }

    window.location.assign(response.redirectUri);
  };

  return (
    <>
      <ErrorNoticeBanner errors={errors} ref={noticeBanner} />
      <div className={cx("container")}>
        <div className={cx("logoGrid")}>
          <div className={cx("logoContainer")}>
            <Server />
          </div>
          <div className={cx("arrowIcons")}>
            <ArrowForward />
            <ArrowBack />
          </div>
          <div className={cx("logoContainer")}>
            <CanadaLifeStackedLogo />
          </div>
        </div>
        <hr />
        <div className={cx("description")}>
          <p>
            Click &ldquo;Authorise&rdquo; if you wish to connect your Canada
            Life account. This will allow you to create KFIs directly on the
            sourcing platform.
          </p>
        </div>
        <div className={cx("actionButtonGroup")}>
          <div className={cx("denyButton")}>
            <Button
              href={denyUrl(redirectUri)}
              theme="secondary"
              type="button"
              fullWidth={true}
              target="_self"
            >
              Deny
            </Button>
          </div>
          <div className={cx("authoriseButton")}>
            <Button
              onClick={authoriseButtonHandler}
              type="button"
              fullWidth={true}
            >
              Authorise
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

function denyUrl(redirectUri: string) {
  if (!redirectUri.startsWith("https")) return redirectUri;

  const redirectUrl = new URL(redirectUri);
  redirectUrl.searchParams.append("error", "access_denied");

  return redirectUrl.toString();
}

export default ThirdParty;
