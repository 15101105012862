import { DATE_FORMAT, CL_DATE_FORMAT } from "lib/constants";
import { capitaliseEachWord, dayjs, getAgeFromDate } from "utils";

const borrowerResolver = (data) => {
  return {
    age: getAgeFromDate(data.date_of_birth),
    birthSex: data.sex,
    dbId: data.id,
    dob: dayjs(data.date_of_birth).format(DATE_FORMAT),
    dobFormatted: dayjs(data.date_of_birth).format(CL_DATE_FORMAT),
    firstName: data.forenames,
    fullName: [data.forenames, data.middle_name, data.surname].join(" "),
    fullNameWithTitle: [
      capitaliseEachWord(data.title),
      data.forenames,
      data.middle_name,
      data.surname,
    ].join(" "),
    id: data.primary_borrower ? "primaryBorrower" : "secondaryBorrower",
    lastName: data.surname,
    middleName: data.middle_name,
    primaryBorrower: data.primary_borrower,
    title: data.title,
  };
};

export default borrowerResolver;
