import classNames from "classnames/bind";
import PropTypes from "prop-types";
import {
  Borrower,
  Button,
  DropdownButton,
  ErrorNoticeBanner,
  Modal,
} from "components";
import { useBorrower } from "context";
import { PRIMARY_BORROWER } from "forms/ClientDetailsForm";
import useErrorNoticeVisibility from "hooks/useErrorNoticeVisibility";
import { useSession } from "next-auth/react";
import { useRouter } from "next/router";
import { useRef, useState } from "react";
import { presentBorrowerForApiResolver } from "resolvers";
import premBotClientCreationService from "services/premBot/clientCreation";
import { linkFor } from "utils";
import { getAccessToken } from "utils/oauth";
import styles from "./Client.module.scss";

const cx = classNames.bind(styles);

const PremBotClient = ({ settings }) => {
  const { data: session } = useSession();
  const router = useRouter();
  const { state } = useBorrower();
  const noticeBanner = useRef();
  const [errors, setErrors] = useErrorNoticeVisibility(noticeBanner);
  const [borrowerModalVisible, setBorrowerModalVisible] = useState(false);

  const handleClientCreation = async () => {
    const accessToken = await getAccessToken(session);
    const response = await premBotClientCreationService({ accessToken });

    if (response.errors) {
      setErrors(response.errors);
      return;
    }

    const { id } = response.data.client;

    return router.push(linkFor("client", { clientId: id }));
  };

  const handleCustomClientCreation = async () => {
    const accessToken = await getAccessToken(session);
    const data = {
      borrower: presentBorrowerForApiResolver(primaryBorrower),
    };

    const response = await premBotClientCreationService({ accessToken, data });

    if (response.errors) {
      setErrors(response.errors);
      return;
    }

    setBorrowerModalVisible(false);

    const { id } = response.data.client;

    return router.push(linkFor("client", { clientId: id }));
  };

  const handleBorrowerCancel = () => {
    setBorrowerModalVisible(false);
  };

  const openBorrowerModal = () => {
    setBorrowerModalVisible(true);
  };

  const getBorrowerProps = (id) => {
    const borrower = state.borrowers.find((borrower) => borrower.id === id);

    if (!borrower) return;

    return {
      ...borrower,
      ...settings,
    };
  };

  const primaryBorrower = getBorrowerProps(PRIMARY_BORROWER);

  return (
    <>
      <div data-testid="prembot-client">
        <DropdownButton
          ariaLabel="premBotClient"
          align="left"
          icon="AddCircle"
          buttonText="Client"
          options={[
            {
              label: "Quick",
              onClick: handleClientCreation,
            },
            {
              label: "Custom",
              onClick: openBorrowerModal,
            },
          ]}
        />
      </div>
      <Modal
        title="Borrower"
        isOpen={borrowerModalVisible}
        onRequestClose={handleBorrowerCancel}
        onCloseButtonClick={handleBorrowerCancel}
      >
        <ErrorNoticeBanner errors={errors} ref={noticeBanner} />
        <Borrower {...primaryBorrower} />
        <Button onClick={handleCustomClientCreation} customClass={cx("button")}>
          Create
        </Button>
      </Modal>
    </>
  );
};

export default PremBotClient;

PremBotClient.propTypes = {
  settings: PropTypes.object.isRequired,
};
