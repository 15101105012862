import { useRef, useState } from "react";
import { useRouter } from "next/router";
import PropTypes from "prop-types";
import { useSession } from "next-auth/react";
import { getAccessToken } from "utils/oauth";
import classNames from "classnames/bind";
import newClientModel from "models/client/newClient";
import updateClientModel from "models/client/updateClient";
import useErrorNoticeVisbility from "hooks/useErrorNoticeVisibility";
import {
  Borrower,
  Button,
  ErrorNoticeBanner,
  FormActions,
  PageHeader,
  Panel,
} from "components";
import { useBorrower } from "context";
import {
  ADD_BORROWER,
  CHANGE_BORROWER_ID,
  REMOVE_BORROWER,
} from "context/Borrower/actions";
import {
  parseSessionStorageFromKeysArray,
  linkFor,
  clearNewClientSessionStorageItems,
} from "utils";
import {
  CLIENT_DETAILS_TYPE_UPDATE,
  CLIENT_DETAILS_TYPE_NEW,
  PROPERTY_DETAILS_TYPE_NEW,
  ADVISER_DETAILS_TYPE_NEW,
} from "lib/constants";

import styles from "./ClientDetailsForm.module.scss";

const cx = classNames.bind(styles);

export const PRIMARY_BORROWER = "primaryBorrower";
export const SECONDARY_BORROWER = "secondaryBorrower";

const ClientDetailsForm = ({ clientId, settings, type }) => {
  const { data: session } = useSession();
  const { dispatch, sessionStorageKey, state } = useBorrower();

  const [formSending, setFormSending] = useState();
  const [key, setKey] = useState(0);
  const noticeBanner = useRef();
  const [errors, setErrors] = useErrorNoticeVisbility(noticeBanner);
  const router = useRouter();
  const formRef = useRef();

  let backLink;
  let cancelLink;
  let continueLabel;

  switch (type) {
    case CLIENT_DETAILS_TYPE_NEW:
      backLink = clientId
        ? linkFor("adviserDetails", {
            clientId,
            type: ADVISER_DETAILS_TYPE_NEW,
          })
        : linkFor("lendingCheck");
      cancelLink = linkFor("dashboard");
      continueLabel = "Continue";
      break;

    case CLIENT_DETAILS_TYPE_UPDATE:
      backLink = cancelLink = linkFor("client", { clientId });
      continueLabel = "Save & Exit";
      break;
  }

  const getBorrrowerProps = (id) => {
    const borrower = state.borrowers.find((borrower) => borrower.id === id);

    if (!borrower) return;

    return {
      ...borrower,
      ...settings,
    };
  };

  const primaryBorrower = getBorrrowerProps(PRIMARY_BORROWER);
  const secondaryBorrower = getBorrrowerProps(SECONDARY_BORROWER);

  const deleteBorrower = (id) => {
    const confirm = window.confirm(
      "Are you sure you want to remove this borrower?"
    );
    if (!confirm) return;

    if (id === PRIMARY_BORROWER) {
      dispatch({
        type: REMOVE_BORROWER,
        payload: PRIMARY_BORROWER,
      });

      // Promote the secondary borrower to become the primary
      dispatch({
        type: CHANGE_BORROWER_ID,
        payload: {
          currentId: SECONDARY_BORROWER,
          newId: PRIMARY_BORROWER,
        },
      });

      // Update the key to ensure that the Borrower component rerenders
      setKey((value) => (value += 1));
    }

    if (id === SECONDARY_BORROWER) {
      dispatch({
        type: REMOVE_BORROWER,
        payload: SECONDARY_BORROWER,
      });
    }
  };

  const handleDeletePrimaryBorrowerClick = (e) => {
    e.preventDefault();
    deleteBorrower(PRIMARY_BORROWER);
  };

  const handleDeleteSecondaryBorrowerClick = (e) => {
    e.preventDefault();
    deleteBorrower(SECONDARY_BORROWER);
  };

  const handleAddBorrowerClick = (e) => {
    e.preventDefault();
    dispatch({
      type: ADD_BORROWER,
      payload: primaryBorrower ? SECONDARY_BORROWER : PRIMARY_BORROWER,
    });
  };

  const handleCancelClick = (e) => {
    e.preventDefault();
    const confirm = window.confirm("Are you sure you want to cancel?");

    if (confirm) {
      switch (type) {
        case CLIENT_DETAILS_TYPE_UPDATE:
          sessionStorage.removeItem(sessionStorageKey);
          break;

        case CLIENT_DETAILS_TYPE_NEW:
          if (clientId) sessionStorage.removeItem(sessionStorageKey);

          clearNewClientSessionStorageItems();
          break;
      }

      router.push({ pathname: cancelLink });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormSending(true);

    const formIsValid = formRef.current.checkValidity();

    if (!formIsValid) {
      formRef.current.reportValidity();
      setFormSending(false);
      return;
    }

    const data = parseSessionStorageFromKeysArray([
      sessionStorageKey,
      "clubId",
    ]);
    const accessToken = await getAccessToken(session);
    const response = clientId
      ? await updateClientModel({ accessToken, clientId, data })
      : await newClientModel({ accessToken, data });

    if (response.errors) {
      setFormSending(false);
      setErrors(response.errors);
      return;
    }

    let continueLink;

    switch (type) {
      case CLIENT_DETAILS_TYPE_UPDATE:
        continueLink = linkFor("client", { clientId });
        break;

      case CLIENT_DETAILS_TYPE_NEW:
        continueLink = linkFor("propertyDetails", {
          clientId: response.clientId,
          type: PROPERTY_DETAILS_TYPE_NEW,
        });

        // Add clientId to 'new client' sessionStorage items
        ["postcode", "exLocalAuthority"].forEach((key) => {
          if (!sessionStorage.getItem(key)) return;

          const newKey = `${key}-${response.clientId}`;

          // Set sessionStorage item with new key
          sessionStorage.setItem(newKey, sessionStorage[key]);

          // Remove sessionStorage item with old key
          sessionStorage.removeItem(key);
        });

        // Remove unrequired sessionStorage items
        ["clientDetails", "clubId"].forEach((key) => {
          sessionStorage.getItem(key) && sessionStorage.removeItem(key);
        });

        break;
    }

    router.push({ pathname: continueLink });
  };

  return (
    <form name="lending-check-form" onSubmit={handleSubmit} ref={formRef}>
      <PageHeader backLink={{ href: backLink, label: "Back" }} />
      <Panel>
        <ErrorNoticeBanner errors={errors} ref={noticeBanner} />
        <section className={cx("container")}>
          <header className={cx("header")}>
            <h1 className={cx("title")}>Client details</h1>
            <p className={cx("description")}>
              Please provide the below client information. We’ll use these
              details to create an illustration so please make sure the
              information is accurate. If you need to update the details at a
              later stage, please use the Adviser Dashboard and go to Clients
              where you will be able to view your clients details.
            </p>
          </header>
          <hr />
          <div className={cx("formFields")}>
            <div
              className={cx("borrower")}
              role="region"
              aria-labelledby="primary_borrower_title"
            >
              <div className={cx("borrowerHeader")}>
                <h2 className={cx("borrowerTitle")} id="primary_borrower_title">
                  First Borrower
                </h2>
                {secondaryBorrower && (
                  <Button
                    ariaLabel="Remove First Borrower"
                    icon="Delete"
                    theme="alternative"
                    onClick={handleDeletePrimaryBorrowerClick}
                  >
                    <span className={cx("removeBorrowerButtonText")}>
                      Remove
                    </span>
                  </Button>
                )}
              </div>
              {primaryBorrower && (
                <div className={cx("formField")}>
                  <Borrower {...primaryBorrower} key={key} />
                </div>
              )}
            </div>
            {secondaryBorrower && (
              <>
                <hr />
                <div
                  className={cx("borrower")}
                  role="region"
                  aria-labelledby="secondary_borrower_title"
                >
                  <div className={cx("borrowerHeader")}>
                    <h2
                      className={cx("borrowerTitle")}
                      id="secondary_borrower_title"
                    >
                      Second Borrower
                    </h2>
                    <Button
                      ariaLabel="Remove Second Borrower"
                      icon="Delete"
                      theme="alternative"
                      onClick={handleDeleteSecondaryBorrowerClick}
                    >
                      <span className={cx("removeBorrowerButtonText")}>
                        Remove
                      </span>
                    </Button>
                  </div>
                  <div className={cx("formField")}>
                    <Borrower {...secondaryBorrower} />
                  </div>
                </div>
              </>
            )}
          </div>
          {!secondaryBorrower && (
            <>
              <hr />
              <div>
                <Button
                  customClass={cx("addBorrowerButton")}
                  icon="AddCircle"
                  theme="alternative"
                  onClick={handleAddBorrowerClick}
                >
                  Add another borrower
                </Button>
              </div>
            </>
          )}
        </section>
      </Panel>
      <FormActions
        onCancelClick={handleCancelClick}
        isDisabled={formSending}
        continueLabel={continueLabel}
      />
    </form>
  );
};

ClientDetailsForm.propTypes = {
  clientId: PropTypes.string,
  settings: PropTypes.object.isRequired,
  type: PropTypes.oneOf([CLIENT_DETAILS_TYPE_UPDATE, CLIENT_DETAILS_TYPE_NEW])
    .isRequired,
};

export default ClientDetailsForm;
