import { forwardRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames/bind";
import FormField from "../";
import { labelOrLabelledby } from "utils/propTypeValidations";
import styles from "./Text.module.scss";

const cx = classNames.bind(styles);

let Text = (props, ref) => {
  const handleChange = (e) => {
    onChange && onChange(e);
    setValue(e.target.value);
  };

  const {
    hasError = false,
    hasWarning = false,
    controlledValue,
    isDisabled = false,
    isRequired = false,
    labelledby,
    onChange,
    placeholder,
    value: initialValue = "",
  } = props;

  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    if (controlledValue) {
      setValue(controlledValue);
    }
  }, [controlledValue]);

  const attrs = {
    ["aria-labelledby"]: labelledby,
    className: cx("input", {
      hasError,
      hasWarning,
      hasValue: value,
      isDisabled,
    }),
    disabled: isDisabled,
    onChange: handleChange,
    placeholder,
    required: isRequired,
    ref,
    type: "text",
    value,
  };

  return (
    <FormField {...props}>
      <input {...attrs} />
    </FormField>
  );
};

Text = forwardRef(Text);

Text.propTypes = {
  labelOrLabelledby,
  controlledValue: PropTypes.string,
  hasError: PropTypes.bool,
  hasWarning: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  labelledby: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.string,
};

export default Text;
