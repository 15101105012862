import { SHORT_DATE_FORMAT } from "lib/constants";
import { dayjs } from "utils";

const propertyDetailsResolver = (property) => {
  return {
    address: property.address,
    valuationInstructed:
      property.valuer_instructed_on &&
      dayjs(property.valuer_instructed_on).format(SHORT_DATE_FORMAT),
    valuationRef: property.valuation_reference,
  };
};

export default propertyDetailsResolver;
