import { useState } from "react";
import PropTypes from "prop-types";
import useBreakpoint from "hooks/useBreakpoint";
import classNames from "classnames/bind";
import { FormField } from "components";
import { useValueIfInArray } from "utils";
import { BREAKPOINTS, OTHER_TITLES } from "lib/constants";
import styles from "./Title.module.scss";

const cx = classNames.bind(styles);

const RADIO_OPTIONS = [
  {
    label: "Mr",
    value: "Mr",
  },
  {
    label: "Mrs",
    value: "Mrs",
  },
  {
    label: "Miss",
    value: "Miss",
  },
  {
    label: "Dr",
    value: "Dr",
  },
];

const DROPDOWN_OPTIONS = OTHER_TITLES.map((title) => {
  return {
    value: title.toLocaleLowerCase(),
    label: title,
  };
});

export const TITLES = [...RADIO_OPTIONS, ...DROPDOWN_OPTIONS];

const Title = (props) => {
  const { maxWidth } = useBreakpoint("xs");
  const isSmallViewport = maxWidth && maxWidth <= BREAKPOINTS.m;

  const { group, isRequired = false, onChange, value } = props;
  const [radioGroupValue, setRadioGroupValue] = useState(
    useValueIfInArray(RADIO_OPTIONS, value)
  );
  const [dropdownValue, setDropdownValue] = useState(
    useValueIfInArray(DROPDOWN_OPTIONS, value)
  );
  const [dropdownOnlyValue, setDropdownOnlyValue] = useState(value);
  const [requiredRadioGroupValue, setRequiredRadioGroupValue] = useState(
    radioGroupValue ? true : isRequired && !dropdownOnlyValue
  );
  const [requiredDropdownValue, setRequiredDropdownValue] = useState(
    dropdownValue ? true : isRequired && !radioGroupValue
  );

  const handleRadioGroupChange = (e) => {
    onChange && onChange(e);
    setDropdownValue("");
    setRadioGroupValue(e.target.value);
    setDropdownOnlyValue(e.target.value);
    setRequiredRadioGroupValue(true);
    setRequiredDropdownValue(false);
  };

  const handleDropdownChange = (e) => {
    onChange && onChange(e);
    setDropdownValue(e.target.value);
    setRadioGroupValue("");
    setDropdownOnlyValue(e.target.value);
    setRequiredDropdownValue(true);
    setRequiredRadioGroupValue(false);
  };

  const handleDropdownOnlyChange = (e) => {
    const { value } = e.target;
    const radioValue = useValueIfInArray(RADIO_OPTIONS, value);
    const dropdownValue = useValueIfInArray(DROPDOWN_OPTIONS, value);

    onChange && onChange(e);
    setDropdownOnlyValue(e.target.value);
    setRadioGroupValue(radioValue);
    setDropdownValue(dropdownValue);
  };

  const radioGroupProps = {
    controlledValue: radioGroupValue,
    group,
    isRequired: requiredRadioGroupValue,
    label: "Title",
    onChange: handleRadioGroupChange,
    radios: RADIO_OPTIONS,
  };

  const dropdownProps = {
    controlledValue: dropdownValue,
    isRequired: requiredDropdownValue,
    labelledby: group,
    pleaseSelect: false,
    onChange: handleDropdownChange,
    options: [
      {
        value: "",
        label: "Other",
        disabled: true,
      },
      ...DROPDOWN_OPTIONS,
    ],
    withoutFormFieldContainer: true,
  };

  const dropdownOnlyProps = {
    controlledValue: dropdownOnlyValue,
    isRequired: isRequired,
    label: "Title",
    onChange: handleDropdownOnlyChange,
    options: TITLES,
  };

  if (isSmallViewport) {
    return (
      <div className={cx("dropdownOnly")}>
        <FormField.Dropdown {...dropdownOnlyProps} />
      </div>
    );
  }

  return (
    <div className={cx("radiosAndDropdown")}>
      <FormField.RadioGroup {...radioGroupProps}>
        <FormField.Dropdown {...dropdownProps} />
      </FormField.RadioGroup>
    </div>
  );
};

Title.propTypes = {
  group: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.oneOf(TITLES.map(({ value }) => value)),
};

export default Title;
