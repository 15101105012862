const clubsResolver = (data) => {
  const clubsMap = (club) => {
    return {
      label: club.name,
      value: club.id,
      default: club.default,
    };
  };

  const nonExclusiveClubs = [
    {
      label: "No club",
      value: "",
    },
  ];

  const hasExclusiveClub = data.exclusive;
  const hasPrivateClubs = data.private?.length > 0;
  const hasPublicClubs = data.public?.length > 0;

  const privateClubs = hasPrivateClubs && {
    label: "Private clubs",
    options: data.private.map(clubsMap),
  };

  const publicClubs = hasPublicClubs && {
    label: "Clubs",
    options: data.public.map(clubsMap),
  };

  const exclusiveClub = data.exclusive && {
    label: "Exclusive club",
    options: data.exclusive.map(clubsMap),
  };

  if (hasPrivateClubs) {
    nonExclusiveClubs.push(privateClubs);
  }

  if (hasPublicClubs) {
    nonExclusiveClubs.push(publicClubs);
  }

  return hasExclusiveClub ? [exclusiveClub] : nonExclusiveClubs;
};

export default clubsResolver;
