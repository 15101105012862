import PropTypes from "prop-types";
import classNames from "classnames/bind";
import { useRouter } from "next/router";
import linkFor from "utils/linkFor";
import { Button } from "components";
import {
  ILLUSTRATION_DETAILS_TYPE_AMENDMENT,
  ILLUSTRATION_DETAILS_TYPE_REPLACEMENT,
} from "lib/constants";
import styles from "./ProductPanel.module.scss";
import { BestProduct, Tick, WarningIconFilled } from "lib/icons";

const cx = classNames.bind(styles);

const ProductPanel = (props) => {
  const {
    availableToBorrow,
    availableToBorrowInsufficient,
    cashback,
    cashReserve,
    cashReserveInsufficient,
    completionFee,
    clientId,
    downsizingProtection,
    earlyRepaymentWaiver,
    eligibility,
    erc,
    ercInfo,
    getIllustration,
    inheritanceGuarantee,
    inheritanceGuaranteeInfo,
    interestRate,
    interestRateInfo,
    ltv,
    mortgageType,
    mortgageTypeInfo,
    productId,
    productName,
    productCode,
    rateToDisplay,
    referenceNo,
    type,
  } = props;

  const buttonsDisabled = eligibility === "ineligible";
  const router = useRouter();
  const queryString = `?${router.asPath.split("?")[1]}`;

  const productInfoBox = (title, value, extraInfo, testId, modifyingClass) => {
    return (
      <li className={cx("productInfo", modifyingClass)}>
        <span
          className={cx("productInfoTitle")}
          data-testid={`${testId}_title`}
        >
          {title}
        </span>
        <span
          className={cx("productInfoValue")}
          data-testid={`${testId}_value`}
        >
          {value}
        </span>
        <span
          className={cx("productInfoExtra")}
          data-testid={`${testId}_extraInfo`}
        >
          {extraInfo}
        </span>
      </li>
    );
  };

  const eligibilityIcon = () => {
    const icons = {
      best: <BestProduct />,
      eligible: <Tick />,
      ineligible: <WarningIconFilled />,
    };

    // eslint-disable-next-line security/detect-object-injection
    return icons[eligibility];
  };

  let productDetailsLink;

  switch (type) {
    case ILLUSTRATION_DETAILS_TYPE_REPLACEMENT:
      productDetailsLink = linkFor("replacementProductDetails", {
        referenceNo,
        productId,
        queryString,
      });
      break;

    case ILLUSTRATION_DETAILS_TYPE_AMENDMENT:
      productDetailsLink = linkFor("amendmentProductDetails", {
        referenceNo,
        productId,
        queryString,
      });
      break;

    default:
      productDetailsLink = linkFor("productDetails", {
        clientId,
        productId,
        queryString,
      });
  }

  const interestRateToDisplay =
    rateToDisplay === "aer" ? interestRate.aer : interestRate.mer;

  return (
    <div className={cx("wrapper")} data-testid={`product-${productCode}`}>
      <header className={cx("header")}>
        <div
          className={cx("productEligibility", eligibility)}
          data-testid={`icon-${eligibility}`}
        >
          {eligibilityIcon()}
        </div>
        <div className={cx("productHeading")}>{productName}</div>
      </header>
      <ul className={cx("productInfoContainer")}>
        {productInfoBox(
          "Mortgage type",
          mortgageType,
          mortgageTypeInfo,
          "mortgageType",
          "mortgageType"
        )}
        {productInfoBox(
          `Interest rate (${rateToDisplay.toUpperCase()})`,
          interestRateToDisplay,
          interestRateInfo,
          "interestRate"
        )}
        {productInfoBox(
          "Max avail to borrow",
          <>
            {availableToBorrow}
            {availableToBorrowInsufficient && (
              <span
                className={cx("warningIcon")}
                data-testid="maxBorrowAmount_warning"
              >
                <WarningIconFilled />
              </span>
            )}
          </>,
          ltv,
          "maxBorrowAmount"
        )}
        {productInfoBox(
          "Cash reserve facility",
          <>
            {cashReserve}
            {cashReserveInsufficient && (
              <span
                className={cx("warningIcon")}
                data-testid="cashReserve_warning"
              >
                <WarningIconFilled />
              </span>
            )}
          </>,
          "",
          "cashReserve"
        )}
        {productInfoBox("Cashback", cashback, "", "cashback")}
        {productInfoBox("ERC", erc, ercInfo, "erc")}
        {productInfoBox(
          "Early repayment waiver",
          earlyRepaymentWaiver,
          "",
          "earlyRepaymentWaiver"
        )}
        {productInfoBox(
          "Downsizing protection",
          downsizingProtection,
          "",
          "downsizingProtection"
        )}
        {productInfoBox(
          "Inheritance guarantee",
          inheritanceGuarantee,
          inheritanceGuaranteeInfo,
          "inheritanceGuarantee"
        )}
      </ul>
      <footer className={cx("footer")}>
        <div className={cx("feeWrapper")}>
          <span className={cx("feeLabel")}>Completion fee</span>{" "}
          <span className={cx("feeValue")}>{completionFee}</span>
        </div>
        <div className={cx("buttonWrapper")}>
          <Button theme="secondary" href={productDetailsLink}>
            More details
          </Button>
          <Button
            isDisabled={buttonsDisabled}
            onClick={() => getIllustration(props)}
          >
            Get illustration
          </Button>
        </div>
      </footer>
    </div>
  );
};

ProductPanel.propTypes = {
  availableToBorrow: PropTypes.string.isRequired,
  availableToBorrowInsufficient: PropTypes.bool.isRequired,
  cashback: PropTypes.string.isRequired,
  cashReserve: PropTypes.string.isRequired,
  cashReserveInsufficient: PropTypes.bool.isRequired,
  clientId: PropTypes.string.isRequired,
  completionFee: PropTypes.string.isRequired,
  downsizingProtection: PropTypes.string.isRequired,
  earlyRepaymentWaiver: PropTypes.string.isRequired,
  eligibility: PropTypes.oneOf(["best", "eligible", "ineligible"]).isRequired,
  erc: PropTypes.string.isRequired,
  ercInfo: PropTypes.string.isRequired,
  getIllustration: PropTypes.func.isRequired,
  inheritanceGuarantee: PropTypes.string,
  inheritanceGuaranteeInfo: PropTypes.string,
  interestRate: PropTypes.object.isRequired,
  interestRateInfo: PropTypes.string.isRequired,
  ltv: PropTypes.string.isRequired,
  mortgageType: PropTypes.string.isRequired,
  mortgageTypeInfo: PropTypes.string.isRequired,
  productId: PropTypes.number.isRequired,
  productName: PropTypes.string.isRequired,
  productCode: PropTypes.string.isRequired,
  rateToDisplay: PropTypes.oneOf(["mer", "aer"]).isRequired,
  referenceNo: PropTypes.string,
  type: PropTypes.oneOf([
    ILLUSTRATION_DETAILS_TYPE_REPLACEMENT,
    ILLUSTRATION_DETAILS_TYPE_AMENDMENT,
  ]),
};

export default ProductPanel;
