import { productResolver } from "resolvers";

const productsResolver = ({ data }) => {
  if (!data?.products) {
    return [];
  }

  return data.products.map(productResolver);
};

export default productsResolver;
