import {
  CL_DATE_FORMAT,
  EXTERNAL_LINKS,
  SHORT_DATE_FORMAT,
  ZERO_AMOUNT,
} from "lib/constants";
import { borrowersResolver } from "resolvers";
import {
  dayjs,
  displayAmountWithCurrency,
  getAnbFromDate,
  linkFor,
  youngestBorrower,
} from "utils";

interface ResolverOptions {
  kfi_request: {
    id: number;
    borrowers: object[];
    do_not_allow_to_load_in_ols: boolean;
    recommended: boolean;
    completion_fee: number;
    completion_fee_on_mortgage: boolean;
    cash_reserve: string;
    created_on: string;
    pdf_key: string;
    illustration_reference: string;
    property_value: number;
    inheritance_guarantee: number;
    initial_advance: string;
    product_name: string;
    age_next_birthday: boolean;
  };
}

const illustrationSummaryPageResolver = (
  { kfi_request }: ResolverOptions,
  clientId: string
) => {
  const displayLoanAmount = (cash_reserve, initial_advance) => {
    const loanAmount = String(
      parseInt(cash_reserve) + parseInt(initial_advance)
    );
    return displayAmountWithCurrency(loanAmount);
  };

  const youngestBorrowerBirthDate = youngestBorrower(
    kfi_request.borrowers
  ).date_of_birth;

  const data = {
    applyButtonDisabled:
      kfi_request.do_not_allow_to_load_in_ols === true ||
      kfi_request.recommended === false,
    applyLink: linkFor("application", { status: "New", id: kfi_request.id }),
    cashReserveAmount: displayAmountWithCurrency(kfi_request.cash_reserve),
    clientId: clientId,
    completionFee: displayAmountWithCurrency(
      kfi_request.completion_fee || ZERO_AMOUNT
    ),
    completionFeeOnMortgage: kfi_request.completion_fee_on_mortgage,
    dateGenerated: {
      dateTime: kfi_request.created_on,
      formatted: dayjs(kfi_request.created_on).format(SHORT_DATE_FORMAT),
    },
    documents: [
      {
        title: "Generated illustration request.pdf",
        createdAt: dayjs(kfi_request.created_on).format(SHORT_DATE_FORMAT),
        size: "(90kb)",
        href: "#", // document is downloaded with pdfKey
        sourceType: "kfiPdfDownload",
        pdfKey: kfi_request.pdf_key,
        referenceNo: kfi_request.illustration_reference,
      },
      {
        title: "Home finance application form.pdf",
        size: "(243kb)",
        href: EXTERNAL_LINKS.applicationFormInitialAdvance,
        sourceType: "externalDownload",
      },
      {
        title: "Equity release explained - customer guide.pdf",
        size: "(640kb)",
        href: EXTERNAL_LINKS.equityReleaseCustomerGuide,
        sourceType: "externalDownload",
      },
      {
        title: "WeCare – customer guide.pdf",
        href: EXTERNAL_LINKS.weCareCustomerGuide,
        sourceType: "externalDownload",
      },
      {
        title: "Terms and conditions",
        href: EXTERNAL_LINKS.termsAndConditions,
        sourceType: "externalLink",
      },
    ],
    estimatedPropertyValue: displayAmountWithCurrency(
      kfi_request.property_value
    ),
    formDownloadLink: EXTERNAL_LINKS.applicationFormInitialAdvance,
    inheritanceGuarantee: `${kfi_request.inheritance_guarantee}%`,
    initialAdvanceAmount: displayAmountWithCurrency(
      kfi_request.initial_advance
    ),
    loanAmount: displayLoanAmount(
      kfi_request.cash_reserve,
      kfi_request.initial_advance
    ),
    productName: kfi_request.product_name,
    pdfKey: kfi_request.pdf_key,
    referenceNo: kfi_request.illustration_reference,
    validFromDate: dayjs(youngestBorrowerBirthDate)
      .year(dayjs().year())
      .format(CL_DATE_FORMAT),
    youngestBorrowerAge: getAnbFromDate(youngestBorrowerBirthDate),
    ageNextBirthday: kfi_request.age_next_birthday,
  };

  return {
    ...data,
    base: {
      meta: { title: "KFI Summary" },
      header: {
        title: { heading: "KFI Summary" },
        breadcrumbs: [
          { title: "Dashboard", href: linkFor("dashboard") },
          { title: "Clients", href: linkFor("clients") },
          {
            title: borrowersResolver(kfi_request.borrowers).fullName,
            href: linkFor("client", { clientId }),
          },
          { title: "KFI Summary", isCurrent: true },
        ],
      },
    },
  };
};

export default illustrationSummaryPageResolver;
