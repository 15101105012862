import clientsService from "services/clients";
import { presentBorrowerForApiResolver } from "resolvers";

const newClientModel = async ({ accessToken, data }) => {
  const requestData = {
    client: {
      borrowers: data.clientDetails.borrowers.map(
        presentBorrowerForApiResolver
      ),
      club_id: data.clubId,
    },
  };

  const response = await clientsService.create({ accessToken, requestData });

  if (response.errors) return response;

  return { clientId: response.data.client.id };
};

export default newClientModel;
