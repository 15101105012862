import { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames/bind";
import FormField from "../";
import styles from "./CheckboxGroup.module.scss";

const cx = classNames.bind(styles);

const Checkbox = (props) => {
  const handleBlur = (e) => {
    onBlur && onBlur(e);
    setIsFocused(false);
  };

  const handleFocus = (e) => {
    onFocus && onFocus(e);
    setIsFocused(true);
  };

  const handleChange = (e) => {
    onChange && onChange(e);
    setChecked(e.target.checked);
  };

  const {
    checked: initialValue,
    group,
    hasError,
    isDisabled,
    isRequired,
    label,
    onBlur,
    onChange,
    onFocus,
    value,
  } = props;

  const [checked, setChecked] = useState(!!initialValue);
  const [isFocused, setIsFocused] = useState(false);

  const attrs = {
    checked,
    className: cx(styles.input, {
      checked,
      hasError,
      isDisabled,
    }),
    disabled: isDisabled,
    id: value,
    name: group,
    onBlur: handleBlur,
    onChange: handleChange,
    onFocus: handleFocus,
    required: isRequired,
    type: "checkbox",
    value,
  };

  return (
    <div
      className={cx(styles.container, {
        checked,
        hasError,
        isDisabled,
        isFocused,
      })}
    >
      <input {...attrs} />
      <label htmlFor={value}>{label}</label>
    </div>
  );
};

Checkbox.propTypes = {
  checked: PropTypes.bool,
  group: PropTypes.string.isRequired,
  hasError: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  value: PropTypes.string.isRequired,
};

const CheckboxGroup = (props) => (
  <FormField {...props} role="group">
    {props.checkboxes.map((checkbox) => (
      <Checkbox {...props} {...checkbox} key={checkbox.value} />
    ))}
  </FormField>
);

CheckboxGroup.propTypes = {
  group: PropTypes.string.isRequired,
  hasError: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  checkboxes: PropTypes.array.isRequired,
};

export { CheckboxGroup as default, Checkbox };
