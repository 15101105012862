import { forwardRef, useState } from "react";
import PropTypes from "prop-types";
import { BracketInfo, FormField } from "components";
import { getAgeFromDate, calculateMaxDateFromAge } from "utils";

let DateOfBirth = (props, ref) => {
  const { gracePeriod, minAge, onChange, value: initialValue = "" } = props;

  const max = calculateMaxDateFromAge({ age: minAge, gracePeriod });

  const handleChange = (e) => {
    onChange && onChange(e);
    setValue(e.target.value);
  };

  const [value, setValue] = useState(initialValue);

  const datePickerProps = {
    ...props,
    label: "Date of birth",
    max,
    onChange: handleChange,
    ref,
    value,
  };

  return (
    <>
      <FormField.DatePicker {...datePickerProps}>
        {value && (
          <BracketInfo
            appendLabel
            label="years old"
            value={getAgeFromDate(value)}
          />
        )}
      </FormField.DatePicker>
    </>
  );
};

DateOfBirth = forwardRef(DateOfBirth);

DateOfBirth.propTypes = {
  gracePeriod: PropTypes.number.isRequired,
  isDisabled: PropTypes.bool,
  isRequired: PropTypes.bool,
  maxAge: PropTypes.number.isRequired,
  minAge: PropTypes.number.isRequired,
  onChange: PropTypes.func,
  value: PropTypes.string,
};

export default DateOfBirth;
