import { API_ROUTES } from "lib/constants";
import client from "services/client";

export const createToken = async (credentials) => {
  const { KFI_API_CLIENT_ID: client_id } = process.env;

  return await client
    .post(API_ROUTES.oauth.token, {
      ...credentials,
      client_id,
      grant_type: "password",
    })
    .catch((error) => error);
};

export const refreshToken = async (refreshToken: string) => {
  const { KFI_API_CLIENT_ID: client_id } = process.env;

  return await client
    .post(API_ROUTES.oauth.token, {
      client_id,
      grant_type: "refresh_token",
      refresh_token: refreshToken,
    })
    .catch((error) => error);
};

export const applicationData = async ({
  clientId,
  accessToken,
}: {
  clientId: string;
  accessToken: string;
}) => {
  client.accessToken = accessToken;

  return await client
    .get(`${API_ROUTES.oauth.applications}/${clientId}`)
    .catch((error) => error);
};

export const authoriseApplication = async ({
  accessToken,
  clientId,
  redirectUri,
  state = "",
}) => {
  client.accessToken = accessToken;

  const params = new URLSearchParams({
    client_id: clientId,
    redirect_uri: redirectUri,
    response_type: "code",
  });
  if (state) {
    params.append("state", state);
  }
  return await client
    .get(`${API_ROUTES.oauth.authorise}?${params}`)
    .catch((error) => error);
};

export default {
  createToken,
  refreshToken,
  applicationData,
  authoriseApplication,
};
