const postcodesLendingCriteriaCheckResolver = ({ data, errors } = {}) => {
  if (data.lending_criteria) {
    return { isValid: true };
  }

  const isInvalidPostcode = errors
    .find(({ attribute }) => attribute === "postcode")
    ?.messages.includes("Postcode is not a valid UK postcode");

  if (isInvalidPostcode) {
    return { isInvalidPostcode };
  }

  return { outsideOfLendingCriteria: true };
};

export default postcodesLendingCriteriaCheckResolver;
