import clientOverviewStub from "stubs/clientOverview";
import { displayAmountWithCurrency, removeFalseyArrayItems } from "utils";
import { booleanToText } from "utils";

const loanPanelResolver = (loanData) => {
  return {
    ...clientOverviewStub.panels.loan,
    details: removeFalseyArrayItems([
      {
        label: "Initial advance",
        value: loanData?.loan_amount_maximum
          ? "Maximum"
          : displayAmountWithCurrency(loanData?.loan_amount),
        incomplete: !loanData,
      },
      {
        label: "Cash reserve",
        value: loanData
          ? booleanToText(loanData.use_cash_reserve_feature)
          : undefined,
        incomplete: !loanData,
      },
      loanData?.use_cash_reserve_feature && {
        label: "Reserve required",
        value: loanData.cash_reserve_maximum
          ? "Maximum"
          : displayAmountWithCurrency(loanData.cash_reserve),
      },
    ]),
  };
};

export default loanPanelResolver;
