import axios, { AxiosInstance } from "axios";
import env from "@beam-australia/react-env";
import { signOut } from "next-auth/react";

const isBrowser = typeof window !== "undefined";

const baseURL = isBrowser ? env("KFI_API_BASE") : process.env.KFI_AUTH_BASE;

// We know accessToken is returned as a property of client in the client.interceptors.request block
// however typescript says it's not a property of AxiosInstance so we are extending the type
// to incorporate accessToken
interface CustomAxiosInstance extends AxiosInstance {
  accessToken: string;
}

const client = axios.create({
  baseURL,
  validateStatus: (status) => status !== 401,
}) as CustomAxiosInstance;

client.interceptors.response.use(
  ({ data }) => data,
  () => {
    return signOut();
  }
);

client.interceptors.request.use((config) => {
  config.headers.common["Authorization"] = `Bearer ${client.accessToken}`;

  return config;
});

export default client;
