import { MAXIMUM_AMOUNT_AVAILABLE, TRUTHY_STRING } from "lib/constants";
import { parseCurrency } from "utils";

export const presentLoanInformationForApiResolver = (data = {}) => {
  const loan_amount = parseCurrency(data.initialAdvanceAmount) || 0;
  const cash_reserve = parseCurrency(data.cashReserve) || 0;
  const truthyCashReserveValues = [TRUTHY_STRING, MAXIMUM_AMOUNT_AVAILABLE];

  const json = JSON.stringify({
    loan_information: {
      cash_reserve,
      cash_reserve_maximum: data.cashReserve === MAXIMUM_AMOUNT_AVAILABLE,
      loan_amount,
      loan_amount_maximum:
        data.initialAdvanceAmount === MAXIMUM_AMOUNT_AVAILABLE,
      use_cash_reserve_feature:
        truthyCashReserveValues.includes(data.cashReserve) || cash_reserve > 0,
    },
    property: {
      value: parseCurrency(data.estimatedPropertyValue),
    },
  });

  return JSON.parse(json);
};

export default presentLoanInformationForApiResolver;
