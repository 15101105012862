import {
  borrowersResolver,
  productCashbackResolver,
  productResolver,
  productSummaryResolver,
  rollupsResolver,
} from "resolvers";
import {
  linkFor,
  displayAmountWithCurrency,
  removeFalseyArrayItems,
} from "utils";
import {
  ILLUSTRATION_DETAILS_TYPE_AMENDMENT,
  ILLUSTRATION_DETAILS_TYPE_REPLACEMENT,
} from "lib/constants";

const productPageResolver = ({
  data,
  estimatedPropertyValue,
  queryString,
  referenceNo,
  type,
}) => {
  const product = productResolver(data.product);
  const totalBorrowing = displayAmountWithCurrency(
    (data.product.user_values.cash_reserve || 0) + data.initial_advance
  );

  const applyAgeNextBirthday =
    queryString && queryString.includes("age_next_birthday");

  const cashback = productCashbackResolver({
    cashback: data.product.product.cashback,
    cashReserve: data.product.cash_reserve,
    initialAdvance: data.initial_advance,
  });

  const optionalRepaymentRows = () => {
    return data.optional_repayment && data.optional_repayment_amount
      ? [
          {
            title: "Optional repayment",
            value: data.optional_repayment,
          },
          {
            title: "Optional repayment amount",
            value: displayAmountWithCurrency(data.optional_repayment_amount),
          },
        ]
      : [];
  };

  let breadcrumbLinks, loanDetailsHref, availableProductsHref;

  switch (type) {
    case ILLUSTRATION_DETAILS_TYPE_AMENDMENT:
      loanDetailsHref = linkFor("amendmentLoanDetails", { referenceNo });
      availableProductsHref = linkFor("amendmentProducts", {
        referenceNo,
        queryString,
      });
      break;

    case ILLUSTRATION_DETAILS_TYPE_REPLACEMENT:
      loanDetailsHref = linkFor("replacementLoanDetails", { referenceNo });
      availableProductsHref = linkFor("replacementProducts", {
        referenceNo,
        queryString,
      });
      break;
  }

  if (type) {
    breadcrumbLinks = [
      { title: "Dashboard", href: linkFor("dashboard") },
      {
        title: `${type} Illustration Request`,
        href: linkFor("application", { referenceNo, status: type }),
      },
      { title: "Loan details", href: loanDetailsHref },
      { title: "Available Products", href: availableProductsHref },
      { title: product.productName, isCurrent: true },
    ];
  } else {
    breadcrumbLinks = [
      { title: "Dashboard", href: linkFor("dashboard") },
      { title: "Clients", href: linkFor("clients") },
      {
        title: borrowersResolver(data.client.borrowers).fullName,
        href: linkFor("client", { clientId: data.client.id }),
      },
      {
        title: "Available Products",
        href: linkFor("products", { clientId: data.client.id, queryString }),
      },
      { title: product.productName, isCurrent: true },
    ];
  }

  return {
    clientId: data.client.id,
    productSummary: removeFalseyArrayItems([
      {
        title: "Product name",
        value: product.productName,
      },
      {
        title: "Interest rate type",
        value: product.interestRateInfo,
      },
      {
        title: "Annual equivalent rate (AER)",
        value: product.interestRate.aer,
      },
      {
        title: "Monthly equivalent rate (MER)",
        value: product.interestRate.mer,
      },
      {
        title: "Early repayment charge (ERC)",
        value: (
          <>
            {product.erc}:
            <br />
            <br />
            {product.ercInfoExpanded}
          </>
        ),
      },
      {
        title: "Downsizing protection",
        value: product.downsizingProtection,
        tooltip: {
          title: "Downsizing waiver",
          body: "If, after 5 years, the customer wants to repay the loan because they are selling their home and moving to a different property, they will not need to pay an ERC.",
        },
      },
      {
        title: "Early repayment waiver",
        value: product.earlyRepaymentWaiver,
        tooltip: {
          title: "Early repayment waiver",
          body: "The customer will not need to pay an ERC if they decide to repay their Lifetime Mortgage within 3 years of the date that the first borrower dies or goes in to long term care.",
        },
      },
      {
        title: "Initial Advance",
        value: displayAmountWithCurrency(data.initial_advance),
      },
      {
        title: "Cash reserve facility",
        value: product.cashReserve,
      },
      {
        title: "Total borrowing / Maximum available to borrow",
        value: `${totalBorrowing} / ${product.availableToBorrow}`,
      },
      cashback?.type === "percentage" && {
        title: "Cashback percentage",
        value: cashback.percentage,
      },
      cashback?.amount && {
        title: "Cashback amount",
        value: cashback.amount,
      },
      {
        title: "Inheritence guarantee",
        value: product.inheritanceGuarantee,
      },
      ...optionalRepaymentRows(),
    ]),
    feeSummary: [
      {
        title: "Our completion fee",
        value: product.completionFee,
      },
    ],
    summary: productSummaryResolver(
      data.client,
      applyAgeNextBirthday,
      estimatedPropertyValue
    ),
    rollup: rollupsResolver(data.roll_up),
    base: {
      meta: { title: product.productName },
      header: {
        title: { heading: product.productName },
        breadcrumbs: breadcrumbLinks,
      },
    },
  };
};

export default productPageResolver;
