const pagedDataMetaResolver = ({ meta }) => {
  return {
    pageOptions: [10, 25, 50],
    currentPage: meta.page,
    totalPages: meta.pages,
    totalItems: meta.count,
    fromItem: meta.from,
    toItem: meta.to,
  };
};

export default pagedDataMetaResolver;
