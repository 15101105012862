import { CL_DATE_FORMAT, SHORT_DATE_FORMAT } from "lib/constants";
import {
  dayjs,
  displayAmountWithCurrency,
  getAnbFromDate,
  linkFor,
  youngestBorrower,
} from "utils";
import {
  EXTERNAL_LINKS,
  ILLUSTRATION_DETAILS_TYPE_AMENDMENT,
  ILLUSTRATION_DETAILS_TYPE_REPLACEMENT,
} from "lib/constants";

const editIllustrationSummaryPageResolver = (
  { kfi_request },
  clientId,
  referenceNo,
  type
) => {
  const cashReserve = kfi_request.cash_reserve || "0";
  const initialAdvance = kfi_request.initial_advance || "0";

  const displayLoanAmount = (cashReserve, initialAdvance) => {
    const loanAmount = String(
      parseInt(cashReserve, 10) + parseInt(initialAdvance, 10)
    );
    return displayAmountWithCurrency(loanAmount);
  };

  const youngestBorrowerBirthDate = youngestBorrower(
    kfi_request.borrowers
  ).date_of_birth;

  const data = {
    ageNextBirthday: kfi_request.age_next_birthday,
    cashReserveAmount: displayAmountWithCurrency(cashReserve),
    clientId: clientId,
    completionFee: displayAmountWithCurrency(kfi_request.completion_fee),
    completionFeeOnMortgage: kfi_request.completion_fee_on_mortgage,
    dateGenerated: {
      dateTime: kfi_request.created_on,
      formatted: dayjs(kfi_request.created_on).format(SHORT_DATE_FORMAT),
    },
    documents: [
      {
        title: "Generated illustration request.pdf",
        size: "(90kb)",
        href: "#", // document is downloaded via pdfKey
        createdAt: dayjs(kfi_request.created_on).format(SHORT_DATE_FORMAT),
        sourceType: "kfiPdfDownload",
        pdfKey: kfi_request.pdf_key,
        referenceNo: kfi_request.illustration_reference,
      },
      {
        title: "Home finance application form.pdf",
        size: "(243kb)",
        href: EXTERNAL_LINKS.applicationFormInitialAdvance,
        sourceType: "externalDownload",
      },
      {
        title: "Equity release explained - customer guide.pdf",
        size: "(640kb)",
        href: EXTERNAL_LINKS.equityReleaseCustomerGuide,
        sourceType: "externalDownload",
      },
      {
        title: "WeCare – customer guide.pdf",
        href: EXTERNAL_LINKS.weCareCustomerGuide,
        sourceType: "externalDownload",
      },
      {
        title: "Terms and conditions",
        href: EXTERNAL_LINKS.termsAndConditions,
        sourceType: "externalLink",
      },
    ],
    estimatedPropertyValue: displayAmountWithCurrency(
      kfi_request.property_value
    ),
    inheritanceGuarantee: `${kfi_request.inheritance_guarantee}%`,
    initialAdvanceAmount: displayAmountWithCurrency(initialAdvance),
    loanAmount: displayLoanAmount(cashReserve, initialAdvance),
    pdfKey: kfi_request.pdf_key,
    productName: kfi_request.product_name,
    referenceNo: kfi_request.illustration_reference,
    validFromDate: dayjs(youngestBorrowerBirthDate)
      .year(dayjs().year())
      .format(CL_DATE_FORMAT),
    youngestBorrowerAge: getAnbFromDate(youngestBorrowerBirthDate),
  };

  let applicationBreadcrumb, loanDetailsBreadcrumb, productsList;

  switch (type) {
    case ILLUSTRATION_DETAILS_TYPE_REPLACEMENT:
      applicationBreadcrumb = {
        title: "Replacement illustration request",
        href: linkFor("application", {
          referenceNo,
          status: type,
        }),
      };
      loanDetailsBreadcrumb = {
        title: "Loan details",
        href: linkFor("replacementLoanDetails", { referenceNo }),
      };
      productsList = {
        title: "Available products",
        href: linkFor("replacementProducts", { referenceNo }),
      };
      break;
    case ILLUSTRATION_DETAILS_TYPE_AMENDMENT:
      applicationBreadcrumb = {
        title: "Amended illustration request",
        href: linkFor("application", {
          referenceNo,
          status: type,
        }),
      };
      loanDetailsBreadcrumb = {
        title: "Loan details",
        href: linkFor("amendmentLoanDetails", { referenceNo }),
      };
      productsList = {
        title: "Available products",
        href: linkFor("amendmentProducts", { referenceNo }),
      };
      break;
  }

  return {
    ...data,
    base: {
      meta: { title: "KFI Summary" },
      header: {
        title: { heading: "KFI Summary" },
        breadcrumbs: [
          { title: "Dashboard", href: linkFor("dashboard") },
          applicationBreadcrumb,
          loanDetailsBreadcrumb,
          productsList,
          { title: "KFI Summary", isCurrent: true },
        ],
      },
    },
  };
};

export default editIllustrationSummaryPageResolver;
