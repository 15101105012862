import clientsService from "services/clients";
import { presentBorrowerForApiResolver } from "resolvers";

const updateClientModel = async ({ accessToken, clientId, data }) => {
  const borrowerDataKey = clientId
    ? `clientDetails-${clientId}`
    : "clientDetails";

  const requestData = {
    client: {
      borrowers: data[borrowerDataKey].borrowers.map(
        presentBorrowerForApiResolver
      ),
    },
  };

  const response = await clientsService.updateBorrowers(accessToken, {
    requestData,
    clientId,
  });

  if (response.errors) return response;

  return { clientId };
};

export default updateClientModel;
