import { API_ROUTES } from "lib/constants";
import client from "services/client";

const settingsService = async (accessToken) => {
  client.accessToken = accessToken;

  return await client.get(API_ROUTES.settings).catch((error) => error);
};

export default settingsService;
