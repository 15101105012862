import PropTypes from "prop-types";
import classNames from "classnames/bind";
import { Tooltip } from "components";
import { Help } from "lib/icons";
import styles from "./FormField.module.scss";

const cx = classNames.bind(styles);

const FormField = (props) => {
  const {
    children,
    containWithinTooltipBounds = false,
    customInputContainerClass,
    errorText,
    errorTextTestId,
    group,
    hasError = false,
    hasWarning = false,
    helperText,
    label,
    showRequiredText = props.isRequired,
    role,
    tooltip,
    warningText,
    withoutFormFieldContainer,
  } = props;

  if (withoutFormFieldContainer) return children;

  const Label = (role && group) || !label ? "div" : "label";

  const helpText = () => {
    if (hasError) {
      return (
        <span
          aria-label={errorText}
          className={cx("errorText")}
          role="alert"
          data-testid={errorTextTestId}
        >
          {errorText}
        </span>
      );
    }

    if (hasWarning) {
      return (
        <span
          aria-label={warningText}
          className={cx("warningText")}
          role="alert"
        >
          {warningText}
        </span>
      );
    }

    if (helperText) {
      return <span className={cx("helperText")}>{helperText}</span>;
    }

    if (showRequiredText) {
      return <span className={cx("helperText")}>Required</span>;
    }
  };

  return (
    <Label
      className={cx("container", { containWithinTooltipBounds })}
      role={role}
      aria-labelledby={group}
      data-testid="FormField"
    >
      {label && (
        <span className={cx("label")} id={group}>
          {label}
        </span>
      )}
      <div className={cx("inputContainer", customInputContainerClass)}>
        {children}
      </div>
      {tooltip && (
        <div className={cx("tooltip")}>
          <Tooltip {...tooltip}>
            <Help />
          </Tooltip>
        </div>
      )}
      {helpText()}
    </Label>
  );
};

FormField.propTypes = {
  containWithinTooltipBounds: PropTypes.bool,
  customInputContainerClass: PropTypes.string,
  children: PropTypes.node.isRequired,
  errorText: PropTypes.string,
  errorTextTestId: PropTypes.string,
  group: PropTypes.string,
  hasError: PropTypes.bool,
  hasWarning: PropTypes.bool,
  helperText: PropTypes.string,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  showRequiredText: PropTypes.bool,
  role: PropTypes.string,
  tooltip: PropTypes.object,
  withoutFormFieldContainer: PropTypes.bool,
  warningText: PropTypes.string,
};

export default FormField;

export { default as CheckboxGroup } from "./CheckboxGroup";
export { default as Currency } from "./Currency";
export { default as DateOfBirth } from "./DateOfBirth";
export { default as DatePicker } from "./DatePicker";
export { default as Dropdown } from "./Dropdown";
export { default as Email } from "./Email";
export { default as Password } from "./Password";
export { default as Percentage } from "./Percentage";
export { default as RadioGroup } from "./RadioGroup";
export { default as Search } from "./Search";
export { default as Switch } from "./Switch";
export { default as Text } from "./Text";
export { default as Title } from "./Title";
