import {
  ACTIVE_STAGES,
  DEFAULT_STAGES,
  REQUIRES_ACTION_STAGES,
  STAGE_IN_PROGRESS,
  STAGE_NOT_STARTED,
  STAGE_REQUIRES_ACTION,
  STAGE_SUCCESSFUL,
  STAGE_UNSUCCESSFUL,
  SUCCESSFUL_STAGES,
  UNSUCCESSFUL_STAGES,
} from "lib/constants";
import { calculateDaysFromDate, shortFormatDate } from "utils";

const stagesResolver = ({ stages, mortgage_application_stage_id }) => {
  const stageHash = (stage) => {
    return {
      label: displayStageLabel(stage.label),
      started: displayStartedAtDate(stage.stage_started_at),
      daysAgo: displayDaysAgo(stage.stage_started_at),
      status: displayProgress(
        stage.stage_started_at,
        stage.stage_completed_at,
        stage.position
      ),
      order: calculatePosition(stage.position),
    };
  };

  const calculatePosition = (position) => {
    if (position === 17) {
      return 4;
    }
    if (position === 18) {
      return 5;
    }
    return position;
  };

  const displayStageLabel = (label) => {
    return label === "Incomplete" ? "Draft" : label;
  };

  const displayStartedAtDate = (date) => {
    return date ? shortFormatDate(date) : "-";
  };

  const displayDaysAgo = (date) => {
    return date ? calculateDaysFromDate(date) : "-";
  };

  // begin calculation of status for each stage
  const getActiveStatus = (position) => {
    if (ACTIVE_STAGES.includes(position)) return STAGE_IN_PROGRESS;
    if (REQUIRES_ACTION_STAGES.includes(position)) return STAGE_REQUIRES_ACTION;
    if (SUCCESSFUL_STAGES.includes(position)) return STAGE_SUCCESSFUL;
    if (UNSUCCESSFUL_STAGES.includes(position)) return STAGE_UNSUCCESSFUL;
  };

  const getFinalStatus = (position) => {
    if (UNSUCCESSFUL_STAGES.includes(position)) return STAGE_UNSUCCESSFUL;

    return STAGE_SUCCESSFUL;
  };

  const displayProgress = (startDate, endDate, position) => {
    if (mortgage_application_stage_id === position)
      return getActiveStatus(position);
    if (!startDate && !endDate) return STAGE_NOT_STARTED;
    if (startDate && !endDate) return getActiveStatus(position);
    if (startDate && endDate) return getFinalStatus(position);
  };
  // end calculation of status for each stage

  // adds positions of stages which are not in the default list BUT have a start_date
  // ie: stages which were required in addition of the default stages
  stages.forEach((stage) => {
    if (stage.stage_started_at && !DEFAULT_STAGES.includes(stage.position)) {
      DEFAULT_STAGES.push(stage.position);
    }
  });

  // adds replacement/ amendment stage if current
  if (REQUIRES_ACTION_STAGES.includes(mortgage_application_stage_id)) {
    DEFAULT_STAGES.push(mortgage_application_stage_id);
  }

  // filters the stages to return only the activeStages
  // ie: the default stages && any additional stages required
  const filteredStages = stages.filter((stage) =>
    DEFAULT_STAGES.includes(stage.position)
  );

  return filteredStages.map(stageHash).sort(function (a, b) {
    return a.order - b.order;
  });
};

export default stagesResolver;
