import React, { ForwardedRef, useState } from "react";
import classNames from "classnames/bind";
import { NoticeBanner } from "components";
import styles from "./ErrorNoticeBanner.module.scss";

const cx = classNames.bind(styles);

type ErrorMessagesItem = { attribute: string; messages: string[] };
type ErrorDetailItem = { message?: string; detail?: string };
type ErrorItem = string | ErrorMessagesItem | ErrorDetailItem;

interface ErrorNoticeBannerProps {
  errors?: ErrorItem[];
  prefixMessage?: string;
  defaultShowErrors?: boolean;
}

const ErrorNoticeBanner = (
  props: ErrorNoticeBannerProps,
  ref: ForwardedRef<HTMLDivElement>
) => {
  const {
    errors,
    prefixMessage = "Sorry, it looks like there was an error. Please try again.",
    defaultShowErrors = false,
  } = props;
  const [showErrors, setShowErrors] = useState(defaultShowErrors);

  const handleShowErrorsClick = () => {
    setShowErrors(!showErrors);
  };

  if (!errors?.length) return null;

  const errorContent = (errorItem: ErrorItem, index: number) => {
    if (typeof errorItem === "object") {
      if ("attribute" in errorItem) {
        return (
          <dl>
            <dt>{errorItem.attribute}</dt>
            {errorItem.messages.map((message, index) => (
              <dd key={index}>{message}</dd>
            ))}
          </dl>
        );
      }

      if (errorItem.message) return errorItem.message;
      if (errorItem.detail) return errorItem.detail;
    } else {
      return (
        <dl>
          <dt key={index}>{errorItem}</dt>
        </dl>
      );
    }
  };

  const errorMessage = errors.map((errorItem, index) => (
    <li className={cx("errorItem")} key={index}>
      <pre className={cx("errorText")}>
        <code>{errorContent(errorItem, index)}</code>
      </pre>
    </li>
  ));

  return (
    <div className={cx("container")} ref={ref}>
      <NoticeBanner id="server_error" type="alert">
        <p>{prefixMessage}</p>
        <button
          className={cx("toggleShowErrorsButton")}
          onClick={handleShowErrorsClick}
          type="button"
        >
          {showErrors ? "Hide" : "Show"} error information
        </button>
        {showErrors && <ul className={cx("errorList")}>{errorMessage}</ul>}
      </NoticeBanner>
    </div>
  );
};

export default React.forwardRef<HTMLDivElement, ErrorNoticeBannerProps>(
  ErrorNoticeBanner
);
