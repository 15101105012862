const presentBorrowerForApiResolver = (borrower) => ({
  title: borrower.title,
  first_name: borrower.firstName?.toUpperCase(),
  last_name: borrower.lastName?.toUpperCase(),
  middle_name: borrower.middleName?.toUpperCase(),
  date_of_birth: borrower.dob,
  id: borrower?.dbId,
  sex: borrower.birthSex,
  primary_borrower: borrower.id === "primaryBorrower",
});

export default presentBorrowerForApiResolver;
