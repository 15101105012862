import { removeFalseyArrayItems } from "utils";

const applicantsResolver = (applicants) => {
  const applicantHash = (applicant) => {
    return {
      name: [applicant.title, applicant.forenames, applicant.surname].join(" "),
      fullName: removeFalseyArrayItems([
        applicant.forenames,
        applicant.middle_name,
        applicant.surname,
      ]).join(" "),
      dob: applicant.date_of_birth,
      age: applicant.age,
    };
  };

  const result = applicants.map(applicantHash);
  result.fullName = result.map(({ fullName }) => fullName).join(" and ");

  return result;
};

export default applicantsResolver;
