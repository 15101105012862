import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames/bind";
import { formatCurrency, parseCurrency } from "utils";
import styles from "./RadioControls.module.scss";
import { Button, FormField } from "components";

const cx = classNames.bind(styles);

const RadioControls = ({
  isDisabled,
  radios,
  setSelected,
  updateRequestData,
  reset,
}) => {
  const [checked, setChecked] = useState("");
  const [specificValue, setSpecificValue] = useState();
  const [valueWarning, setValueWarning] = useState();

  useEffect(() => {
    const selectedRadio = radios.filter((radio) => radio.initialValue)[0];

    if (selectedRadio) {
      const tags = [selectedRadio.tag];

      setChecked(selectedRadio.facet);

      if (selectedRadio.hasSpecificMonetaryValue) {
        const valueAsCurrency = formatCurrency(selectedRadio.initialValue);
        setSpecificValue(valueAsCurrency);
        tags.push(`£${valueAsCurrency}`);
      }

      if (selectedRadio.hasSpecificPercentage) {
        setSpecificValue(selectedRadio.initialValue);
        tags.push(`${selectedRadio.initialValue}%`);
      }

      setSelected(tags);
    }
  }, []);

  useEffect(() => {
    reset && setChecked("");
  }, [reset]);

  useEffect(() => {
    if (isDisabled) {
      setChecked("");
      setSelected([]);
    }
  }, [isDisabled]);

  const radioChangeHandler = (radio) => {
    setChecked(radio.facet);

    if (!radio.hasSpecificMonetaryValue && !radio.hasSpecificPercentage) {
      setSelected([radio.tag]);
      updateRequestData({
        type: radio.group,
        facet: radio.facet,
      });
    }
  };

  const inputChangeHandler = (e, validator) => {
    const value = e.target.value;

    setSpecificValue(value);
    if (validator) {
      setValueWarning(validator(parseCurrency(value)));
    }
  };

  const submitHandler = (radio) => {
    let tagValue;
    let newRequestData = {
      type: radio.group,
      facet: radio.facet,
    };

    if (radio.hasSpecificMonetaryValue) {
      tagValue = `£${specificValue}`;
      newRequestData.value = parseCurrency(specificValue);
    }

    if (radio.hasSpecificPercentage) {
      tagValue = `${specificValue}%`;
      newRequestData.value = parseValueToNumber(specificValue);
    }

    setSelected([radio.tag, tagValue]);
    updateRequestData(newRequestData);
  };

  const parseValueToNumber = (value) => parseInt(value, 10) || undefined;

  return radios.map((radio, index) => (
    <div className={cx("controlWrapper")} key={index}>
      <div>
        <input
          type="radio"
          className={cx("radio", { isDisabled: radio.isDisabled })}
          value={radio.facet}
          id={`${radio.group}_${radio.facet}`}
          data-testid={`${radio.group}_${radio.facet}`}
          name={radio.group}
          checked={checked === radio.facet}
          onChange={() => radioChangeHandler(radio)}
          disabled={radio.isDisabled}
        />
        <label htmlFor={`${radio.group}_${radio.facet}`}>{radio.label}</label>
      </div>
      {radio.hasSpecificMonetaryValue && checked === radio.facet && (
        <div className={cx("specificValueWrapper")}>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              submitHandler(radio);
            }}
          >
            <FormField.Currency
              customInputContainerClass="inputWithButton"
              isRequired={true}
              label={radio.inputLabel}
              onAfterChange={(e) =>
                inputChangeHandler(e, radio.validateSpecificValue)
              }
              value={specificValue}
              hasWarning={!!valueWarning}
              warningText={valueWarning}
            >
              <Button
                size="small"
                isDisabled={!specificValue || specificValue === "0.00"}
              >
                Apply
              </Button>
            </FormField.Currency>
          </form>
        </div>
      )}
      {radio.hasSpecificPercentage && checked === radio.facet && (
        <div className={cx("specificValueWrapper")}>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              submitHandler(radio);
            }}
          >
            <FormField.Percentage
              customInputContainerClass="inputWithButton"
              isRequired={true}
              label={radio.inputLabel}
              onAfterChange={inputChangeHandler}
              value={parseValueToNumber(specificValue)}
            >
              <Button size="small" isDisabled={!specificValue}>
                Apply
              </Button>
            </FormField.Percentage>
          </form>
        </div>
      )}
    </div>
  ));
};

RadioControls.propTypes = {
  isDisabled: PropTypes.bool,
  radios: PropTypes.arrayOf(
    PropTypes.shape({
      group: PropTypes.string.isRequired,
      facet: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      hasSpecificMonetaryValue: PropTypes.bool,
      hasSpecificPercentage: PropTypes.bool,
      inputLabel: PropTypes.string,
      isDisabled: PropTypes.bool,
    })
  ).isRequired,
  setSelected: PropTypes.func.isRequired,
  updateRequestData: PropTypes.func.isRequired,
  reset: PropTypes.number.isRequired,
};

export default RadioControls;
