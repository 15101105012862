import { profileResolver } from "resolvers";

export const profilePageResolver = (data) => {
  const profile = profileResolver(data);

  return {
    profileTableData: [
      {
        title: "Title",
        value: profile.title || "-",
      },
      {
        title: "First name",
        value: profile.firstName || "-",
      },
      {
        title: "Last name",
        value: profile.lastName || "-",
      },
      {
        title: "Position",
        value: profile.position || "-",
      },
      {
        title: "Telephone",
        value: profile.telephone || "-",
      },
      {
        title: "Mobile",
        value: profile.mobile || "-",
      },
      {
        title: "Email",
        value: profile.email || "-",
      },
      {
        title: "Works from",
        value: profile.worksFrom || "-",
      },
      {
        title: "Admin contact name",
        value: profile.adminContactName || "-",
      },
      {
        title: "Admin contact email",
        value: profile.adminContactEmail || "-",
      },
      {
        title: "Admin contact telephone",
        value: profile.adminContactTelephone || "-",
      },
    ],
  };
};

export default profilePageResolver;
