import { forwardRef, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames/bind";
import FormField from "../";
import { labelOrLabelledby } from "utils/propTypeValidations";
import styles from "./DatePicker.module.scss";

const cx = classNames.bind(styles);

let DatePicker = (props, ref) => {
  const handleChange = (e) => {
    onChange && onChange(e);
    setValue(e.target.value);
  };

  const {
    children,
    hasError = false,
    isDisabled = false,
    isRequired = false,
    labelledby,
    max,
    min,
    onChange,
    placeholder,
    value: initialValue = "",
  } = props;

  const [value, setValue] = useState(initialValue);

  const attrs = {
    ["aria-labelledby"]: labelledby,
    className: cx("input", {
      hasError,
      hasValue: value,
      isDisabled,
    }),
    disabled: isDisabled,
    max,
    min,
    onChange: handleChange,
    placeholder,
    required: isRequired,
    ref,
    type: "date",
    value,
  };

  return (
    <FormField {...props}>
      <div className={cx("datePicker", { hasValue: value })}>
        <div className={cx("inputContainer", { isDisabled })}>
          <input {...attrs} />
        </div>
        {children}
      </div>
    </FormField>
  );
};

DatePicker = forwardRef(DatePicker);

DatePicker.propTypes = {
  labelOrLabelledby,
  children: PropTypes.node,
  hasError: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  labelledby: PropTypes.string,
  max: PropTypes.string,
  min: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.string,
};

export default DatePicker;
