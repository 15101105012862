import { FALSY_STRING, ZERO_AMOUNT } from "lib/constants";
import { Fragment } from "react";
import { booleanToText, displayAmountWithCurrency } from "utils";

const productResolver = ({ user_values, product }) => {
  return {
    availableToBorrow: displayAmountWithCurrency(
      user_values.available_to_borrow
    ),
    availableToBorrowInsufficient: user_values.available_to_borrow_insufficient,
    cashback: product.cashback
      ? `${booleanToText(true)} - ${displayAmountWithCurrency(
          user_values.cashback_value
        )}`
      : booleanToText(false),
    cashReserve:
      user_values.cash_reserve === null
        ? FALSY_STRING
        : displayAmountWithCurrency(user_values.cash_reserve),
    cashReserveInsufficient: user_values.cash_reserve_insufficient,
    completionFee: displayAmountWithCurrency(
      user_values.completion_fee || ZERO_AMOUNT
    ),
    downsizingProtection: booleanToText(product.downsizing_protection),
    earlyRepaymentWaiver: booleanToText(product.early_repayment_waiver),
    eligibility: user_values.eligibility,
    erc: `${product.erc.erc_type} - ${product.erc.term} years`,
    ercInfo: product.erc.further_info,
    ercInfoExpanded: product.erc.further_info_expanded
      .split("\n")
      .map((info, i) => (
        <Fragment key={i}>
          {info}
          <br />
        </Fragment>
      )),
    id: product.id,
    inheritanceGuarantee: user_values.inheritance_guarantee
      ? `${user_values.inheritance_guarantee.value}%`
      : FALSY_STRING,
    inheritanceGuaranteeInfo:
      user_values.inheritance_guarantee &&
      user_values.inheritance_guarantee.further_info,
    interestRate: {
      mer: `${product.interest_rate.mer}%`,
      aer: `${product.interest_rate.aer}%`,
    },
    interestRateInfo: product.interest_rate.further_info,
    ltv: `${user_values.ltv}% LTV`,
    maxLoanAmount: displayAmountWithCurrency(product.maximum_loan_amount),
    mortgageType: product.mortgage.mortgage_type,
    mortgageTypeInfo: product.mortgage.further_info,
    productId: product.id,
    productName: product.name,
    productCode: product.code,
  };
};

export default productResolver;
