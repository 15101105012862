const blogDataResolver = ({ data }) => {
  const blogPromo = data?.blog_promo;
  if (!blogPromo) return;

  return {
    id: blogPromo.id,
    title: blogPromo.title,
    url: blogPromo.url,
    imageUrl: blogPromo.image_url,
    altText: blogPromo.alt_text,
  };
};

export default blogDataResolver;
