import { useEffect, useState } from "react";
import Link from "next/link";
import PropTypes from "prop-types";
import classNames from "classnames/bind";
import FocusTrap from "focus-trap-react";
import { Button } from "components";
import { ChevronRight, ArrowDropDown } from "lib/icons";
import useBreakpoint from "hooks/useBreakpoint";
import { BREAKPOINTS } from "lib/constants";
import styles from "./Breadcrumbs.module.scss";

const cx = classNames.bind(styles);

const Breadcrumb = ({ isCurrent, href, title }, i) => (
  <li
    className={cx(styles.breadcrumbItem, { isCurrent })}
    itemProp="itemListElement"
    itemScope
    itemType="https://schema.org/ListItem"
    key={`breadcrumb-${i}`}
  >
    {isCurrent ? (
      <span
        className={styles.breadcrumbCurrent}
        aria-current="page"
        data-testid="current"
      >
        <span itemProp="name">{title}</span>
      </span>
    ) : (
      <Link href={href} className={styles.breadcrumbLink} itemProp="item">
        <span itemProp="name">{title}</span>
      </Link>
    )}
    <figure className={styles.chevron}>
      <ChevronRight />
    </figure>
    <meta itemProp="position" content={i + 1} />
  </li>
);

Breadcrumb.propTypes = {
  isCurrent: PropTypes.bool,
  href: PropTypes.string,
  title: PropTypes.string.isRequired,
};

const Breadcrumbs = ({ breadcrumbs }) => {
  const [isOpen, setIsOpen] = useState(false);
  const currentPage = breadcrumbs.find(({ isCurrent }) => isCurrent);
  const { maxWidth } = useBreakpoint("xs");
  const isSmallViewport = maxWidth <= BREAKPOINTS.m;

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("u-scrollLocked");
    } else {
      document.body.classList.remove("u-scrollLocked");
    }
  }, [isOpen]);

  return (
    <FocusTrap active={isSmallViewport && isOpen}>
      <div>
        <button
          aria-expanded={isOpen}
          aria-controls="breadcrumbs"
          aria-label={`${isOpen ? "Close" : "Open"} breadcrumbs`}
          className={cx(styles.toggleButton, { isOpen })}
          onClick={() => setIsOpen((value) => !value)}
        >
          <span id="breadcrumbsButton">{currentPage.title}</span>
          <figure className={styles.chevron}>
            <ArrowDropDown />
          </figure>
        </button>
        <div
          aria-hidden={isSmallViewport && !isOpen}
          aria-labelledby="breadcrumbsButton"
          className={cx(styles.breadcrumbs, { isOpen })}
          data-testid="breadcrumbsContainer"
          id="breadcrumbs"
        >
          <nav aria-label="Breadcrumb">
            <ol itemScope itemType="https://schema.org/BreadcrumbList">
              {breadcrumbs.map(Breadcrumb)}
            </ol>
          </nav>
          <div className={styles.closeButtonContainer}>
            <Button fullWidth onClick={() => setIsOpen(false)}>
              Close breadcrumbs
            </Button>
          </div>
        </div>
      </div>
    </FocusTrap>
  );
};

Breadcrumbs.propTypes = {
  breadcrumbs: PropTypes.arrayOf(PropTypes.object),
};

export default Breadcrumbs;
