export const profileResolver = (data) => {
  const {
    is_admin: isAdmin,
    first_name: firstName,
    last_name: lastName,
    title,
    position,
    telephone,
    mobile,
    email,
    works_from: worksFrom,
    admin_contact_name: adminContactName,
    admin_contact_email: adminContactEmail,
    admin_contact_telephone: adminContactTelephone,
    can_generate_kfi_requests: canGenerateKfiRequests,
  } = data;

  return {
    isAdmin,
    firstName,
    lastName,
    fullName: [firstName, lastName].join(" "),
    title,
    position,
    telephone,
    mobile,
    email,
    worksFrom,
    adminContactName,
    adminContactEmail,
    adminContactTelephone,
    canGenerateKfiRequests,
  };
};

export default profileResolver;
