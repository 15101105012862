import { borrowersResolver } from "resolvers";
import { formatCurrency, linkFor, removeFalseyArrayItems } from "utils";
import {
  ILLUSTRATION_DETAILS_TYPE_REPLACEMENT,
  ILLUSTRATION_DETAILS_TYPE_AMENDMENT,
  LOAN_DETAILS_TYPE_UPDATE,
  LOAN_DETAILS_TYPE_NEW,
  MAXIMUM_AMOUNT_AVAILABLE,
  FALSY_STRING,
} from "lib/constants";

const loanDetailsPageResolver = ({ client, referenceNo, type }) => {
  let estimatedPropertyValue;
  let initialAdvanceAmount;
  let cashReserve;

  if (client) {
    estimatedPropertyValue = formatCurrency(client.property.value, {
      withDecimal: false,
    });
  }

  if (client?.loan_information) {
    initialAdvanceAmount = client.loan_information.loan_amount_maximum
      ? MAXIMUM_AMOUNT_AVAILABLE
      : formatCurrency(client.loan_information.loan_amount, {
          withDecimal: false,
        });

    cashReserve = FALSY_STRING;

    if (client.loan_information.use_cash_reserve_feature) {
      cashReserve = client.loan_information.cash_reserve_maximum
        ? MAXIMUM_AMOUNT_AVAILABLE
        : formatCurrency(client.loan_information.cash_reserve, {
            withDecimal: false,
          });
    }
  }

  switch (type) {
    case LOAN_DETAILS_TYPE_UPDATE:
      return {
        base: {
          meta: { title: "Loan details" },
          header: {
            breadcrumbs: removeFalseyArrayItems([
              { title: "Dashboard", href: linkFor("dashboard") },
              { title: "Clients", href: linkFor("clients") },
              client && {
                title: borrowersResolver(client.borrowers).fullName,
                href: linkFor("client", { clientId: client.id }),
              },
              { title: "Loan details", isCurrent: true },
            ]),
            title: { heading: "Loan details" },
          },
        },
        ...(cashReserve ? { cashReserve } : {}),
        ...(client && client.id ? { clientId: client.id } : {}),
        ...(estimatedPropertyValue ? { estimatedPropertyValue } : {}),
        ...(initialAdvanceAmount ? { initialAdvanceAmount } : {}),
      };

    case LOAN_DETAILS_TYPE_NEW:
      return {
        base: {
          meta: { title: "New client" },
          header: {
            breadcrumbs: [
              { title: "Dashboard", href: linkFor("dashboard") },
              { title: "New client", isCurrent: true },
            ],
            title: { heading: "New client" },
            progress: 80,
          },
        },
        ...(estimatedPropertyValue ? { estimatedPropertyValue } : {}),
      };

    case ILLUSTRATION_DETAILS_TYPE_AMENDMENT:
    case ILLUSTRATION_DETAILS_TYPE_REPLACEMENT:
      return {
        base: {
          meta: { title: `${type} Illustration Request` },
          header: {
            title: { heading: `${type} Illustration Request` },
            breadcrumbs: [
              { title: "Dashboard", href: linkFor("dashboard") },
              {
                title: `${type} Illustration Request`,
                href: linkFor("application", { referenceNo, status: type }),
              },
              { title: "Loan details", isCurrent: true },
            ],
          },
        },
      };
  }
};

export default loanDetailsPageResolver;
