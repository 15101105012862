import { displayAmountWithCurrency } from "utils";

export const productCashbackResolver = ({
  cashback,
  cashReserve = 0,
  initialAdvance,
}) => {
  if (!cashback) return;

  switch (cashback.cashback_type) {
    case "fixed":
      return {
        type: cashback.cashback_type,
        amount: displayAmountWithCurrency(cashback.amount),
      };

    case "percentage":
      var totalBorrowing = cashReserve + initialAdvance;

      return {
        type: cashback.cashback_type,
        percentage: `${cashback.percentage}%`,
        amount: displayAmountWithCurrency(
          totalBorrowing * (cashback.percentage / 100)
        ),
      };
  }
};

export default productCashbackResolver;
