import { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames/bind";
import Tippy from "@tippyjs/react";
import { Button } from "components";
import { CloseCircleIconFilledOutlined } from "lib/icons";
import styles from "./Tooltip.module.scss";
import { BREAKPOINTS } from "lib/constants";

const cx = classNames.bind(styles);

export const PLACEMENTS = [
  "top-start",
  "top-end",
  "right",
  "right-start",
  "right-end",
  "bottom",
  "bottom-start",
  "bottom-end",
  "left",
  "left-start",
  "left-end",
  "auto",
  "auto-start",
  "auto-end",
];

const Tooltip = ({
  body,
  children,
  isVisible: initialIsVisibleValue,
  placement = "right-start",
  title,
}) => {
  const [isVisible, setIsVisible] = useState(initialIsVisibleValue);
  const [tooltipButtonIsFocused, setTooltipButtonIsFocused] = useState(false);
  const isSmallScreen = window.matchMedia(
    `(max-width: ${BREAKPOINTS["<l"]}px)`
  ).matches;

  const handleClose = (e) => {
    e.preventDefault();
    setIsVisible(false);
  };

  const getPlacement = isSmallScreen ? "bottom" : placement;

  const content = (
    <>
      {title && <h3 className={cx("title")}>{title}</h3>}
      {body}
      <div className={cx("closeButtonContainer")}>
        <Button fullWidth onClick={handleClose}>
          Close
        </Button>
      </div>
      <button
        className={cx("closeIconContainer")}
        onClick={handleClose}
        type="button"
      >
        <CloseCircleIconFilledOutlined />
      </button>
    </>
  );

  const tippyArgs = {
    appendTo: "parent",
    animation: "shift-away",
    className: cx("tippy"),
    content,
    interactive: true,
    maxWidth: 375,
    offset: [-15, 15],
    placement: getPlacement,
    popperOptions: {
      strategy: "fixed",
      modifiers: [
        {
          name: "flip",
          options: {
            fallbackPlacements: "bottom",
          },
        },
        {
          name: "preventOverflow",
          options: {
            padding: 0,
          },
        },
      ],
    },
    visible: isVisible,
  };

  return (
    <Tippy {...tippyArgs}>
      <span className={cx("container")} id="parent">
        <button
          className={cx("toggleButton", { tooltipButtonIsFocused })}
          data-testid={`tooltip-${title}`}
          onFocus={() => setTooltipButtonIsFocused(true)}
          onBlur={() => setTooltipButtonIsFocused(false)}
          onClick={() => setIsVisible((value) => !value)}
          type="button"
        >
          {children}
        </button>
      </span>
    </Tippy>
  );
};

Tooltip.propTypes = {
  body: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  isVisible: PropTypes.bool,
  placement: PropTypes.oneOf(PLACEMENTS),
  title: PropTypes.string,
};

export default Tooltip;
