import { useEffect } from "react";
import PropTypes from "prop-types";
import Image from "next/image";
import Link from "next/link";
import env from "@beam-australia/react-env";
import { Panel } from "components";
import { ChevronRight } from "lib/icons";
import TagManager from "react-gtm-module";
import classNames from "classnames/bind";
import styles from "./BlogPanel.module.scss";

const cx = classNames.bind(styles);

const BlogPanel = ({
  id,
  title,
  url,
  imageUrl,
  altText,
  previewMode = false,
}) => {
  const imageLoader = ({ src }) => {
    return `${src}?f=faces&fit=fill&w=800&h=350`;
  };

  const emptyImageUrl = imageUrl === "";

  useEffect(() => {
    if (!previewMode && env("GOOGLE_TAG_MANAGER_ID")) {
      TagManager.dataLayer({
        dataLayer: {
          event: "promo_impression",
          promoId: id,
          promoTitle: title,
          promoUrl: url,
        },
      });
    }
  }, [id, previewMode]);

  return (
    <Panel size="blog">
      <div className={cx("imageContainer", { emptyImageUrl })}>
        {!emptyImageUrl && (
          <Image
            data-testid="blogImage"
            loader={imageLoader}
            src={imageUrl}
            fill={true}
            alt={altText}
          />
        )}
      </div>
      <div className={cx("copyContainer")}>
        <h3 className={cx("title")}>{title}</h3>
        <div>
          <Link href={url} className={cx("link")} target="_blank">
            <span>
              Read More <ChevronRight />
            </span>
          </Link>
        </div>
      </div>
    </Panel>
  );
};

BlogPanel.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  altText: PropTypes.string.isRequired,
  previewMode: PropTypes.bool,
};

export default BlogPanel;
