import { useState, useEffect } from "react";

const useErrorNoticeVisibility = (ref) => {
  const [errors, setErrors] = useState();

  useEffect(() => {
    if (errors) {
      ref.current.scrollIntoView({
        behavior: "smooth",
      });
    }
  }, [errors]);

  return [errors, setErrors];
};

export default useErrorNoticeVisibility;
