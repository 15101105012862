const panelCompletionResolver = (panels) => {
  const panelValues = Object.values(panels)
    .map(({ details }) => details)
    .flat();

  const anyIncomplete = panelValues.find(({ incomplete }) => incomplete);

  return !anyIncomplete;
};

export default panelCompletionResolver;
