import PropTypes from "prop-types";
import styles from "./Progress.module.scss";

const Progress = ({ progress }) => {
  if (!progress) return null;

  return (
    <progress
      className={styles.progress}
      data-testid="progress"
      max="100"
      value={progress}
    />
  );
};

Progress.propTypes = {
  progress: PropTypes.number,
};

export default Progress;
