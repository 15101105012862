import { linkFor } from "utils";

const applicationSummaryItemResolver = (data) => {
  const { count, label } = data;

  const descriptionMap = {
    closed: "Closed",
    draft: "Draft",
    in_progress: "In progress",
    requires_action: "Require action",
    withdrawn: "Withdrawn",
  };

  const linkFilterMap = {
    closed: "closed",
    draft: "draft",
    in_progress: "in_progress",
    withdrawn: "withdrawn",
  };

  const testIdFilterMap = {
    closed: "dashboard_closed_button",
    draft: "dashboard_draft_button",
    in_progress: "dashboard_in_progress_button",
    requires_action: "dashboard_requires_action_button",
    withdrawn: "dashboard_withdrawn_button",
  };

  return {
    count,
    description: descriptionMap[label],
    isHighlighted: label === "requires_action",
    link:
      label === "requires_action"
        ? "#applicationsList"
        : linkFor("applications", {
            filter: linkFilterMap[label],
          }),
    testId: testIdFilterMap[label],
  };
};

export default applicationSummaryItemResolver;
