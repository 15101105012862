import { forwardRef, useEffect, useState } from "react";
import classNames from "classnames/bind";
import FormField from "..";
import { Visibility, VisibilityOff } from "lib/icons";
import styles from "./Password.module.scss";

const cx = classNames.bind(styles);

export interface PasswordProps {
  autoComplete?: "on" | "off" | "new-password" | "current-password";
  errorText: string;
  errorTextTestId: string;
  hasError: boolean;
  isDisabled?: boolean;
  isRequired: boolean;
  label: string;
  labelledby?: string;
  labelOrLabelledby?: "label" | "labelledby";
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onVisibilityButtonClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  placeholder?: string;
  showPassword?: boolean;
  showRequiredText: boolean;
  testId: string;
  value: string;
}

export type PasswordRef = HTMLInputElement;

const Password = forwardRef<PasswordRef, PasswordProps>((props, ref) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange && onChange(e);
    setValue(e.target.value);
  };

  const handleVisibilityButtonClick = (
    e: React.MouseEvent<HTMLButtonElement>
  ) => {
    onVisibilityButtonClick && onVisibilityButtonClick(e);
    e.preventDefault();
    setShowPassword((value) => !value);
  };

  const {
    autoComplete = "on",
    errorTextTestId,
    hasError = false,
    isDisabled = false,
    isRequired = false,
    labelledby,
    onChange,
    onVisibilityButtonClick,
    placeholder,
    showPassword: initialShowPasswordValue = false,
    value: initialValue = "",
    testId,
  } = props;

  const [value, setValue] = useState(initialValue);
  const [showPassword, setShowPassword] = useState(initialShowPasswordValue);

  useEffect(
    () => setShowPassword(initialShowPasswordValue),
    [initialShowPasswordValue]
  );

  const attrs = {
    ["aria-labelledby"]: labelledby,
    autoComplete,
    className: cx("input", {
      hasError,
      hasValue: value,
      isDisabled,
    }),
    disabled: isDisabled,
    errortexttestid: errorTextTestId,
    onChange: handleChange,
    placeholder,
    required: isRequired,
    type: showPassword ? "text" : "password",
    value,
    "data-testid": testId,
  };

  return (
    <div>
      <button
        aria-label={showPassword ? "Hide password" : "Show password"}
        className={cx("toggleVisiblityButton")}
        onClick={handleVisibilityButtonClick}
        type="button"
      >
        {showPassword ? (
          <>
            <VisibilityOff />
            Hide
          </>
        ) : (
          <>
            <Visibility />
            Show
          </>
        )}
      </button>
      <FormField {...props}>
        <input {...attrs} ref={ref} />
      </FormField>
    </div>
  );
});

Password.displayName = "Password";

export default Password;
