import { forwardRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames/bind";
import FormField from "../";
import { labelOrLabelledby } from "utils/propTypeValidations";
import styles from "./Dropdown.module.scss";

const cx = classNames.bind(styles);

const Option = ({ disabled, value, label }, i) => (
  <option
    key={`option-${value}-${i}`}
    disabled={disabled}
    value={value}
    data-testid={value}
  >
    {label}
  </option>
);

Option.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
};

const Optgroup = ({ label, options }, i) => (
  <optgroup
    label={label}
    key={`optgroup-${label}-${i}`}
    data-testid={`optgroup-${label}`}
  >
    {options.map(Option)}
  </optgroup>
);

Optgroup.propTypes = {
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(Option.propTypes),
};

let Dropdown = (props, ref) => {
  const handleChange = (e) => {
    onChange && onChange(e);
    setValue(e.target.value);
  };

  const {
    controlledValue,
    onChange,
    hasError = false,
    isDisabled = false,
    isRequired = false,
    labelledby,
    options = [],
    placeholder,
    pleaseSelect = true,
    value: initialValue = "",
  } = props;

  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    // Allows us to use "" as a value
    if (typeof controlledValue === "string") {
      setValue(controlledValue);
    }
  }, [controlledValue]);

  const attrs = {
    ["aria-labelledby"]: labelledby,
    className: cx("input", {
      hasError,
      hasValue: value,
      isDisabled,
    }),
    disabled: isDisabled,
    onChange: handleChange,
    placeholder,
    ref,
    required: isRequired,
    value,
  };

  return (
    <FormField {...props}>
      <select {...attrs}>
        {pleaseSelect && (
          <option disabled value="">
            Please select
          </option>
        )}
        {options.map((option) =>
          option.options ? Optgroup(option) : Option(option)
        )}
      </select>
    </FormField>
  );
};

Dropdown = forwardRef(Dropdown);

Dropdown.propTypes = {
  labelOrLabelledby,
  controlledValue: PropTypes.string,
  onChange: PropTypes.func,
  hasError: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  labelledby: PropTypes.string,
  options: PropTypes.array.isRequired,
  pleaseSelect: PropTypes.bool,
  placeholder: PropTypes.string,
  value: PropTypes.string,
};

export default Dropdown;
