const adviserResolver = ({
  first_name: firstName,
  last_name: lastName,
  email,
  title,
  id,
}) => {
  return {
    id,
    email,
    fullName: [firstName, lastName].join(" "),
    fullNameWithTitle: [title, firstName, lastName].join(" "),
    title,
  };
};

export default adviserResolver;
