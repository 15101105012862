import { useEffect, useState } from "react";
import Link from "next/link";
import PropTypes from "prop-types";
import classNames from "classnames/bind";
import { useSession } from "next-auth/react";
import { Email, Help, Phone, User } from "lib/icons";
import { isWithinOpeningHours } from "utils";
import styles from "./Mast.module.scss";

const cx = classNames.bind(styles);

const Mast = (props) => {
  const { data: session } = useSession();
  const { email, link, openingHours, phoneNumber } = props.support;
  const { contactUsLink, loginLink, registerLink } = props;

  const [isOpen, setIsOpen] = useState(isWithinOpeningHours());

  useEffect(() => {
    const checkIsOpen = setInterval(() => {
      setIsOpen(isWithinOpeningHours());
    }, 60000);

    return () => clearInterval(checkIsOpen);
  }, []);

  const AuthLinks = () => (
    <ul>
      <li>
        <a href={link}>
          <Help />
          <span className={styles.linkLabel}>Need support</span>
        </a>
      </li>
      <li>
        <a href={`mailto:${email}`}>
          <Email />
          <span className={styles.linkLabel}>Email us</span>
        </a>
      </li>
      <li>
        <a href={`tel:${phoneNumber}`}>
          <Phone />
          <span className={styles.linkLabel}>{phoneNumber}</span>
        </a>
      </li>
    </ul>
  );

  const NonAuthLinks = () => (
    <ul>
      <li>
        <Link href={contactUsLink}>
          <Email />
          <span className={styles.linkLabel}>Contact us</span>
        </Link>
      </li>
      <li>
        <a href={loginLink}>
          <User />
          <span className={styles.linkLabel}>Login</span>
        </a>
        <span className={styles.registerLinkContainer}>
          |
          <a href={registerLink}>
            <span className={styles.linkLabel}>Register</span>
          </a>
        </span>
      </li>
    </ul>
  );

  return (
    <div className={styles.mast} data-testid="mast">
      <dl>
        <dt className={styles.adviserSupport}>Our adviser support is</dt>
        <dd className={cx(styles.openState, { isOpen })}>
          {isOpen ? "open" : "closed"}
        </dd>
        {!isOpen && <dt className={styles.openingHoursTitle}>Opening Hours</dt>}
        <dd className={styles.openingHoursContent}>{openingHours}</dd>
      </dl>
      {session ? <AuthLinks /> : <NonAuthLinks />}
    </div>
  );
};

Mast.propTypes = {
  contactUsLink: PropTypes.string.isRequired,
  loginLink: PropTypes.string.isRequired,
  registerLink: PropTypes.string.isRequired,
  support: PropTypes.shape({
    openingHours: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    phoneNumber: PropTypes.string.isRequired,
  }).isRequired,
};

export default Mast;
