import { useState } from "react";
import PropTypes from "prop-types";
import { useRouter } from "next/router";
import classNames from "classnames/bind";
import Button from "../Button";
import Search from "../FormField/Search";
import styles from "./TableSearchBox.module.scss";

const cx = classNames.bind(styles);

const TableSearchBox = ({
  heading,
  hintText,
  isDisabled,
  onClearButtonClick,
  onSubmit,
}) => {
  const router = useRouter();
  const [value, setValue] = useState(router.query.search);

  const inputChangeHandler = (e) => {
    setValue(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit({ search: value });
  };

  return (
    <form onSubmit={handleSubmit}>
      <label
        id="searchPanelLabel"
        htmlFor="tableSearchBox"
        className={cx("heading")}
      >
        {heading}
      </label>
      <div className={cx("gridWrapper")} data-testid="tableSearchBox">
        <div className={cx("inputWrapper")}>
          <Search
            value={value}
            id="tableSearchBox"
            onChange={inputChangeHandler}
            onClearButtonClick={onClearButtonClick}
            labelledby="searchPanelLabel"
            isDisabled={isDisabled}
          />
        </div>
        <div className={cx("buttonWrapper")}>
          <Button isDisabled={isDisabled}>Search</Button>
        </div>
        <div className={cx("hintWrapper")}>
          <p className={cx("hint")}>{hintText}</p>
        </div>
      </div>
    </form>
  );
};

TableSearchBox.propTypes = {
  heading: PropTypes.string.isRequired,
  hintText: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  onClearButtonClick: PropTypes.func.isRequired,
};

export default TableSearchBox;
