import { birthdayCalculator, dayjs, findYoungestBorrower } from "utils";
import borrowerResolver from "./borrower";

const ageNextBirthdayResolver = (borrowers) => {
  const qualifies = (borrower) => {
    return birthdayCalculator({ dob: borrower.date_of_birth, today: dayjs() });
  };

  const youngestBorrower = findYoungestBorrower(borrowers);

  let ageNextBirthdayBorrower = {};

  if (qualifies(youngestBorrower)) {
    ageNextBirthdayBorrower = {
      ...borrowerResolver(youngestBorrower),
      qualifiesForANB: true,
    };
  }

  return ageNextBirthdayBorrower;
};

export default ageNextBirthdayResolver;
