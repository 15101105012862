import { dayjs } from "utils";

const dateResolver = (data) => {
  return {
    formatted: dayjs(data, "YYYY-MM-DD").format("DD MMM YYYY"),
    dateTime: data,
  };
};

export default dateResolver;
