import { pagedDataMetaResolver } from "resolvers";

const productListPageMetaResolver = ({ meta }) => {
  return {
    ...pagedDataMetaResolver({ meta }),
    totalProducts: meta.count,
    totalEligibleProducts: meta.eligible_count,
    totalIneligibleProducts: meta.ineligible_count,
  };
};

export default productListPageMetaResolver;
