export default {
  panels: {
    adviser: {
      title: "Adviser details",
      description:
        "Initial postcode check, as well as your advice fee and choice of club.",
    },
    client: {
      title: "Client details",
      description:
        "Details about your client name, date of birth and birth sex.",
    },
    property: {
      title: "Property details",
      description:
        "Details of the property including the property type and estimated value.",
    },
    loan: {
      title: "Loan details",
      description:
        "Details of the loan amount and cash reserve facility required.",
    },
  },
};
