import { authoriseApplication } from "services/oauth";
import { getAccessToken } from "utils/oauth";

const authoriseApplicationModel = async ({
  clientId,
  redirectUri,
  state,
  session,
} = {}) => {
  if (!clientId || !redirectUri || !session) {
    return { errors: ["Invalid authorization data"] };
  }

  const accessToken = await getAccessToken(session);

  const response = await authoriseApplication({
    accessToken,
    clientId,
    redirectUri,
    state,
  });

  if (response.error) {
    return {
      errors: [response.error_description],
    };
  }

  return {
    redirectUri: response.redirect_uri,
  };
};

export default authoriseApplicationModel;
