import { NoticeBanner } from "components";
import classNames from "classnames/bind";
import styles from "./DashboardMessageBanner.module.scss";

export const dashboardMessageBanner = ({ error_message, message }) => {
  // message is an ID provided by the BE that can be mapped to the list below

  const cx = classNames.bind(styles);

  if (error_message) {
    return (
      <div className={cx("container")}>
        <NoticeBanner id="error_message" title={error_message} type="alert" />
      </div>
    );
  }

  const validIds = ["1", "2", "3", "4", "5"];
  const messagesMap = {
    1: {
      id: "two_live_applications",
      messageString:
        "Two live applications for the same client are not allowed.",
      type: "alert",
    },
    2: {
      id: "applications_submitted",
      messageString: "Applications already submitted.",
      type: "alert",
    },
    3: {
      id: "failed_to_submit",
      messageString: "Failed to submit application, please try again.",
      type: "alert",
    },
    4: {
      id: "application_received",
      messageString: "Application was successfully received.",
      type: "information",
    },
    5: {
      id: "application_updated",
      messageString: "Application was successfully updated.",
      type: "information",
    },
  };

  if (message && validIds.includes(message)) {
    // eslint-disable-next-line security/detect-object-injection
    const { id, messageString, type } = messagesMap[message];

    return (
      <div className={cx("container")}>
        <NoticeBanner id={id} title={messageString} type={type} />
      </div>
    );
  }

  return null;
};

export default dashboardMessageBanner;
