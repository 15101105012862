import { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useRouter } from "next/router";
import classNames from "classnames/bind";
import FormField from "../";
import { CloseCircleIcon } from "lib/icons";
import { labelOrLabelledby } from "utils/propTypeValidations";
import styles from "./Search.module.scss";

const cx = classNames.bind(styles);

const Search = (props) => {
  const router = useRouter();

  const handleChange = (e) => {
    onChange && onChange(e);
    setValue(e.target.value);
  };

  const handleClearButtonClick = (e) => {
    onClearButtonClick && onClearButtonClick(e);
    setValue("");
    inputRef.current.focus();
  };

  const {
    hasError = false,
    isDisabled = false,
    isRequired = false,
    labelledby,
    onChange,
    onClearButtonClick,
    placeholder,
    id,
    value: initialValue = router.query.search || "",
  } = props;

  useEffect(() => {
    if (initialValue) return;

    setValue(router.query.search || "");
  }, [router.query.search]);

  const inputRef = useRef(null);
  const [value, setValue] = useState(initialValue);

  const attrs = {
    ["aria-labelledby"]: labelledby,
    className: cx("input", {
      hasError,
      hasValue: value,
      isDisabled,
    }),
    disabled: isDisabled,
    onChange: handleChange,
    placeholder,
    required: isRequired,
    type: "search",
    value,
    id,
  };

  return (
    <FormField {...props}>
      <div className={cx("container")}>
        <input {...attrs} ref={inputRef} />
        {value && (
          <button
            aria-label="Clear search"
            className={cx("clearButton")}
            onClick={handleClearButtonClick}
            type="button"
          >
            <CloseCircleIcon />
          </button>
        )}
      </div>
    </FormField>
  );
};

Search.propTypes = {
  labelOrLabelledby,
  hasError: PropTypes.bool,
  id: PropTypes.string,
  isDisabled: PropTypes.bool,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  labelledby: PropTypes.string,
  onChange: PropTypes.func,
  onClearButtonClick: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.string,
};

export default Search;
