import PropTypes from "prop-types";
import classNames from "classnames/bind";
import { Life } from "lib/icons";
import styles from "./Footer.module.scss";

const cx = classNames.bind(styles);

const Footer = ({ children, isAuthenticated }) => {
  return (
    <footer className={cx(styles.footer, { isAuthenticated })}>
      <Life />
      <div
        className={styles.container}
        dangerouslySetInnerHTML={{ __html: children }}
      />
    </footer>
  );
};

Footer.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  children: PropTypes.string.isRequired,
};

export default Footer;
