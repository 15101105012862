import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames/bind";
import { Panel, Tooltip } from "components";
import {
  ChevronDown,
  ChevronUp,
  Help,
  Tick,
  WarningIconFilled,
} from "lib/icons";
import RadioControls from "./RadioControls";
import CheckboxControls from "./CheckboxControls";
import styles from "./ProductFilter.module.scss";
import AgeNextBirthday from "./AgeNextBirthday";

const cx = classNames.bind(styles);

const ProductFilter = ({
  title,
  controls,
  isDisabled,
  tooltip,
  updateRequestData,
  reset,
}) => {
  const [filterKey, setFilterKey] = useState();
  const [collapsed, setCollapsed] = useState(false);
  const [tags, setTags] = useState([]);
  const { type, options } = controls;

  useEffect(() => {
    switch (type) {
      case "checkbox":
        setFilterKey(options[0].group);
        break;
      case "radio":
        setFilterKey(options[0].group);
        break;
    }
  }, []);

  const collapseIcon = () => (
    <button
      onClick={() => setCollapsed((value) => !value)}
      className={cx("collapseButton")}
    >
      {collapsed ? <ChevronDown /> : <ChevronUp />}
      <span className={cx("visually-hidden")}>
        {collapsed ? "Show panel" : "Hide panel"}
      </span>
    </button>
  );

  useEffect(() => {
    if (reset) {
      setTags([]);
    }
  }, [reset]);

  const addNewTags = (newTags) => setTags(newTags);

  const renderControls = () => {
    switch (type) {
      case "radio": {
        return (
          <RadioControls
            isDisabled={isDisabled}
            radios={options}
            setSelected={addNewTags}
            updateRequestData={updateRequestData}
            reset={reset}
          />
        );
      }
      case "checkbox": {
        return (
          <CheckboxControls
            checkboxes={options}
            setSelected={addNewTags}
            updateRequestData={updateRequestData}
            reset={reset}
            isDisabled={isDisabled}
          />
        );
      }
      case "ageNextBirthday": {
        return (
          <AgeNextBirthday
            borrower={options}
            setSelected={addNewTags}
            updateRequestData={updateRequestData}
            reset={reset}
          />
        );
      }
      default: {
        console.error(`Invalid control type for ${title}`);
        return null;
      }
    }
  };

  const tag = (item, index) => (
    <span className={cx("tag")} key={index}>
      {item} <Tick />
    </span>
  );

  const content = () => (
    <div
      data-testid={`${filterKey}_filter_content`}
      className={cx({ isHidden: isDisabled })}
      aria-hidden={isDisabled}
    >
      <hr className={cx("divider")} />
      {renderControls()}
    </div>
  );

  const renderHeader = () => {
    const icons = isDisabled ? (
      <div className={cx("iconsWrapper")}>
        <WarningIconFilled data-testid={`${filterKey}_disabled`} />
      </div>
    ) : (
      <div className={cx("iconsWrapper")}>
        {tooltip && (
          <Tooltip title={tooltip.title} body={tooltip.body}>
            <Help />
          </Tooltip>
        )}
        {collapseIcon()}
      </div>
    );

    return (
      <header className={cx("header")}>
        <h3 className={cx("title")}>{title}</h3>
        {icons}
      </header>
    );
  };

  return (
    <Panel size="small">
      <div className={cx("container")}>
        <div className={cx("contentCell")}>
          {renderHeader()}
          {tags.length > 0 && (
            <div className={cx("tagsWrapper")}>{tags.map(tag)}</div>
          )}
          {!collapsed && content()}
        </div>
      </div>
    </Panel>
  );
};

ProductFilter.propTypes = {
  title: PropTypes.string.isRequired,
  controls: PropTypes.shape({
    type: PropTypes.string.isRequired,
    options: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  }).isRequired,
  isDisabled: PropTypes.bool,
  tooltip: PropTypes.shape({
    title: PropTypes.string.isRequired,
    body: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  }),
  updateRequestData: PropTypes.func.isRequired,
  reset: PropTypes.number.isRequired,
};

export default ProductFilter;
