import { applicationSummaryItemResolver } from "resolvers";

const applicationSummaryResolver = ({ data }) => {
  const sortByLabel = (a, b) => {
    const APPLICATION_SUMMARY_ORDER = [
      "requires_action",
      "draft",
      "in_progress",
      "withdrawn",
      "closed",
    ];

    const getMegaMenuIndex = ({ label }) =>
      APPLICATION_SUMMARY_ORDER.indexOf(label);

    return getMegaMenuIndex(a) - getMegaMenuIndex(b);
  };

  return data.application_categories
    .sort(sortByLabel)
    .map(applicationSummaryItemResolver);
};

export default applicationSummaryResolver;
