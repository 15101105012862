import PropTypes from "prop-types";
import classNames from "classnames/bind";
import styles from "./Panel.module.scss";

const cx = classNames.bind(styles);

export const SIZES = ["small", "blog"];

const Panel = ({ children, size }) => (
  <div className={cx("panel", size)}>{children}</div>
);

Panel.propTypes = {
  children: PropTypes.node.isRequired,
  size: PropTypes.oneOf(SIZES),
};

export default Panel;
