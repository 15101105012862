import headerStub from "stubs/header";

const headerResolver = (data = {}) => {
  return {
    ...headerStub,
    ...data,
  };
};

export default headerResolver;
