import PropTypes from "prop-types";
import classNames from "classnames/bind";
import { Button } from "components";
import { oneOfProps } from "utils/propTypeValidations";
import styles from "./FormActions.module.scss";

const cx = classNames.bind(styles);

const FormActions = ({
  cancelLink,
  continueLabel = "Continue",
  isDisabled,
  onCancelClick,
  onContinueClick,
  onSaveAndExitClick,
  saveAndExit,
}) => (
  <footer className={cx("container", { saveAndExit })}>
    {saveAndExit ? (
      <>
        <div className={cx("saveAndExit")}>
          <Button
            isDisabled={isDisabled}
            theme="secondary"
            onClick={onSaveAndExitClick}
            type="button"
          >
            Save &amp; Exit
          </Button>
        </div>
        <div className={cx("cancel")}>
          <Button
            isDisabled={isDisabled}
            theme="text"
            onClick={onCancelClick}
            href={cancelLink}
            type="button"
          >
            Cancel &amp; don&apos;t save
          </Button>
        </div>
      </>
    ) : (
      <div className={cx("cancel")}>
        <Button
          isDisabled={isDisabled}
          theme="secondary"
          onClick={onCancelClick}
          href={cancelLink}
          type="button"
        >
          Cancel
        </Button>
      </div>
    )}
    <div className={cx("continue")}>
      <Button isDisabled={isDisabled} onClick={onContinueClick}>
        {continueLabel}
      </Button>
    </div>
  </footer>
);

FormActions.propTypes = {
  _customValidation: (...args) =>
    oneOfProps(args, ["cancelLink", "onCancelClick"], true),
  cancelLink: PropTypes.string,
  continueLabel: PropTypes.string,
  isDisabled: PropTypes.bool,
  onCancelClick: PropTypes.func,
  onContinueClick: PropTypes.func,
  onSaveAndExitClick: PropTypes.func,
  saveAndExit: PropTypes.bool,
};

export default FormActions;
