import { borrowersResolver } from "resolvers";
import {
  booleanToText,
  findYoungestBorrower,
  formatCurrency,
  getAgeFromDate,
  linkFor,
  removeFalseyArrayItems,
} from "utils";
import {
  PROPERTY_DETAILS_TYPE_UPDATE,
  PROPERTY_DETAILS_TYPE_NEW,
  SHELTERED_ACCOMMODATION_ID,
} from "lib/constants";

const propertyDetailsPageResolver = ({ client, clientId, type }) => {
  let propertyData = {};
  let youngestBorrowerAge;

  if (client?.borrowers) {
    const youngestBorrower = findYoungestBorrower(client.borrowers);
    youngestBorrowerAge = getAgeFromDate(youngestBorrower.date_of_birth);
  }

  if (client?.property) {
    propertyData = {
      exLocalAuthority: booleanToText(client.property.ex_local_authority),
      independentValuation: booleanToText(
        client.property.valuation_increase_contact
      ),
      postcode: client.property.postcode,
      propertyType: client.property.usage_type,
      propertyValue: formatCurrency(client.property.value, {
        withDecimal: false,
      }),
      remainingLease:
        client.property.lease_term_remaining > 0
          ? String(client.property.lease_term_remaining)
          : "",
      shelteredAccommodation: booleanToText(
        client.property.residence_status?.id === SHELTERED_ACCOMMODATION_ID
      ),
      tenure: client.property.tenure,
    };
  }

  switch (type) {
    case PROPERTY_DETAILS_TYPE_UPDATE:
      return {
        base: {
          meta: { title: "Property details" },
          header: {
            breadcrumbs: removeFalseyArrayItems([
              { title: "Dashboard", href: linkFor("dashboard") },
              { title: "Clients", href: linkFor("clients") },
              client && {
                title: borrowersResolver(client.borrowers).fullName,
                href: linkFor("client", { clientId: client.id }),
              },
              { title: "Property details", isCurrent: true },
            ]),
            title: { heading: "Property details" },
          },
        },
        clientId,
        ...propertyData,
        ...(youngestBorrowerAge ? { borrowerAge: youngestBorrowerAge } : {}),
      };

    case PROPERTY_DETAILS_TYPE_NEW:
      return {
        base: {
          meta: { title: "New client" },
          header: {
            breadcrumbs: [
              { title: "Dashboard", href: linkFor("dashboard") },
              { title: "New client", isCurrent: true },
            ],
            title: { heading: "New client" },
            progress: 60,
          },
        },
        clientId,
        ...propertyData,
        ...(youngestBorrowerAge ? { borrowerAge: youngestBorrowerAge } : {}),
      };
  }
};

export default propertyDetailsPageResolver;
