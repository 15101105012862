import PropTypes from "prop-types";
import classNames from "classnames/bind";
import styles from "./ProductAvailability.module.scss";

const cx = classNames.bind(styles);

const ProductAvailability = ({ eligibleCount, ineligibleCount, fullWidth }) => {
  return (
    <div
      className={cx("wrapper", { fullWidth })}
      data-testid="productAvailabilityWrapper"
    >
      <div className={cx("item", "itemPrimary")}>
        {eligibleCount} products available
      </div>
      <div className={cx("item", "itemSecondary")}>
        {ineligibleCount} outside criteria
      </div>
    </div>
  );
};

ProductAvailability.propTypes = {
  eligibleCount: PropTypes.number.isRequired,
  fullWidth: PropTypes.bool,
  ineligibleCount: PropTypes.number.isRequired,
};

export default ProductAvailability;
