import PropTypes from "prop-types";
import Link from "next/link";
import Breadcrumbs from "../Breadcrumbs";
import Menu from "../Menu";
import { CanadaLifeMainLogo, CanadaLifeStackedLogo } from "lib/icons";
import menuStub from "stubs/menu";
import { linkFor } from "utils";
import styles from "./Main.module.scss";

const Main = ({ breadcrumbs }) => (
  <div className={styles.main} data-testid="main">
    <Link
      href={linkFor("dashboard")}
      className={styles.logo}
      data-testid="logo"
    >
      <CanadaLifeMainLogo />
      <CanadaLifeStackedLogo />
    </Link>
    {breadcrumbs && <Breadcrumbs breadcrumbs={breadcrumbs} />}
    <Menu {...menuStub} />
  </div>
);

Main.propTypes = {
  breadcrumbs: PropTypes.array,
};

export default Main;
