import PropTypes from "prop-types";
import classNames from "classnames/bind";
import useDropdownVisible from "hooks/useDropdownVisible";
import { Button } from "components";
import styles from "./DropdownControl.module.scss";

const cx = classNames.bind(styles);

const DropdownControl = ({
  align = "left",
  buttonAriaLabel,
  buttonText,
  buttonTheme,
  dropdownTitle,
  formAriaLabel,
  fullWidth,
  icon,
  isDisabled,
  onSubmit,
  optionItems,
  submitIsDisabled,
}) => {
  const { ref, isVisible, toggleVisibility } = useDropdownVisible();

  const buttonClickHandler = (e) => {
    e.preventDefault();
    toggleVisibility();
  };

  const submitHandler = (event) => {
    event.preventDefault();
    toggleVisibility();
    onSubmit();
  };

  return (
    <div
      className={cx("wrapper", { fullWidth })}
      ref={ref}
      data-testid="dropdownControl"
      aria-expanded={isVisible}
    >
      <Button
        ariaLabel={`${isVisible ? "Close" : "Open"} ${buttonAriaLabel}`}
        fullWidth={fullWidth}
        icon={icon}
        isDisabled={isDisabled}
        onClick={buttonClickHandler}
        theme={buttonTheme}
      >
        {buttonText}
      </Button>
      <form aria-label={formAriaLabel} onSubmit={submitHandler}>
        {isVisible && (
          <div className={cx("panel", align)}>
            <header className={cx("panelHeader")}>
              <span className={cx("panelTitle")}>{dropdownTitle}</span>
            </header>
            {optionItems}
            <footer className={cx("panelFooter")}>
              <button className={cx("panelSubmit")} disabled={submitIsDisabled}>
                Update
              </button>
            </footer>
          </div>
        )}
      </form>
    </div>
  );
};

DropdownControl.propTypes = {
  align: PropTypes.oneOf(["left", "right"]),
  buttonAriaLabel: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  buttonTheme: PropTypes.oneOf(["primary", "secondary"]),
  formAriaLabel: PropTypes.string.isRequired,
  fullWidth: PropTypes.bool,
  icon: PropTypes.string.isRequired,
  dropdownTitle: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  optionItems: PropTypes.node.isRequired,
  submitIsDisabled: PropTypes.bool,
};

export default DropdownControl;
