import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import FocusTrap from "focus-trap-react";
import env from "@beam-australia/react-env";
import { useStore } from "context";
import Mast from "./Mast";
import Main from "./Main";
import Progress from "./Progress";
import Title from "./Title";
import styles from "./Header.module.scss";
import classNames from "classnames/bind";
import PremBot from "./PremBot";

const cx = classNames.bind(styles);
const MINIMIZED_THRESHOLD = 100;
let prevScroll = 0;

const Header = (props) => {
  const { breadcrumbs, isAuthenticated, progress, title } = props;
  const { menuIsOpen } = useStore().state;
  const [isMinimised, setIsMinimised] = useState(false);

  const toggleMinimised = () => {
    const hasScrolledPastThreshold = window.scrollY >= MINIMIZED_THRESHOLD;
    const isScrollingUp = prevScroll > window.scrollY;
    prevScroll = window.scrollY;

    if (isScrollingUp) {
      setIsMinimised(false);
      return;
    }

    if (hasScrolledPastThreshold) {
      setIsMinimised(true);
      return;
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleMinimised);
    window.addEventListener("resize", toggleMinimised);

    return () => {
      window.removeEventListener("scroll", toggleMinimised);
      window.removeEventListener("resize", toggleMinimised);
    };
  }, []);

  if (!isAuthenticated) {
    return (
      <header className={styles.top}>
        <Mast {...props} />
      </header>
    );
  }

  return (
    <>
      <FocusTrap active={menuIsOpen}>
        <header
          className={cx(styles.top, {
            isAuthenticated,
            isMinimised,
          })}
        >
          {(env("APP_ENV") === "staging" ||
            env("APP_ENV") === "development" ||
            env("APP_ENV") === "test") && (
            <div className={cx("envBanner")}>
              <div className={cx("envName")} data-testid="envName">
                {env("APP_ENV")}
              </div>
              <PremBot />
            </div>
          )}
          <Mast {...props} />
          <Main breadcrumbs={breadcrumbs} />
        </header>
      </FocusTrap>
      <div className={styles.bottom}>
        <Title {...title} />
        <Progress progress={progress} />
      </div>
    </>
  );
};

Header.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  breadcrumbs: PropTypes.array,
  support: PropTypes.object.isRequired,
  title: PropTypes.object,
  progress: PropTypes.number,
};

export default Header;
