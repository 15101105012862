import { applicationResolver } from "resolvers";

const applicationsResolver = ({
  canGenerateKfiRequests,
  data,
  isAdminAdviser,
}) => {
  return data.applications.map((application) => {
    return applicationResolver({
      canGenerateKfiRequests,
      data: application,
      isAdminAdviser,
    });
  });
};

export default applicationsResolver;
