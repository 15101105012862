import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames/bind";
import styles from "./CheckboxControls.module.scss";

const cx = classNames.bind(styles);

const CheckboxControls = ({
  checkboxes,
  isDisabled,
  reset,
  setSelected,
  updateRequestData,
}) => {
  const [checked, setChecked] = useState(
    checkboxes.map((checkbox) => checkbox.initialValue || false)
  );

  useEffect(() => {
    const newTags = checkboxes
      .filter((checkbox) => checkbox.initialValue)
      .map((checkbox) => checkbox.tag);
    setSelected(newTags);
  }, []);

  useEffect(() => {
    reset && setChecked(new Array(checkboxes.length).fill(false));
  }, [reset]);

  useEffect(() => {
    isDisabled && setChecked(new Array(checkboxes.length).fill(false));
    isDisabled && setSelected([]);
  }, [isDisabled]);

  const getLabelsForCheckedItems = (array) =>
    array
      // eslint-disable-next-line security/detect-object-injection
      .map((value, index) => (value ? checkboxes[index].tag : null))
      .filter((tag) => tag);

  return checkboxes.map((checkbox, index) => {
    /* eslint-disable security/detect-object-injection */
    const onChange = () => {
      const newCheckedState = [...checked];
      const newValue = !checked[index];
      newCheckedState[index] = newValue;
      setChecked(newCheckedState);

      const newTags = getLabelsForCheckedItems(newCheckedState);
      setSelected(newTags);

      updateRequestData({
        type: checkbox.group,
        facet: checkbox.facet,
        value: newValue,
      });
    };

    const isChecked = checked[index];
    /* eslint-enable security/detect-object-injection */

    return (
      <div className={cx("controlWrapper")} key={index}>
        <input
          type="checkbox"
          className={cx("checkbox")}
          value={checkbox.value}
          id={`${checkbox.group}_${checkbox.facet}`}
          name={checkbox.group}
          checked={isChecked}
          onChange={onChange}
        />
        <label
          htmlFor={`${checkbox.group}_${checkbox.facet}`}
          className={cx("label")}
        >
          {checkbox.label}
        </label>
      </div>
    );
  });
};

CheckboxControls.propTypes = {
  checkboxes: PropTypes.arrayOf(
    PropTypes.shape({
      initialValue: PropTypes.bool,
      group: PropTypes.string.isRequired,
      facet: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  isDisabled: PropTypes.bool,
  reset: PropTypes.number.isRequired,
  setSelected: PropTypes.func.isRequired,
  updateRequestData: PropTypes.func.isRequired,
};

export default CheckboxControls;
