/* eslint-disable no-unused-vars */
import {
  applicationsResolver,
  applicationSummaryResolver,
  blogDataResolver,
} from "resolvers";

const dashboardPageResolver = ({
  canGenerateKfiRequests,
  data = {},
  isAdminAdviser,
}) => {
  return {
    applications:
      data.applications &&
      applicationsResolver({
        canGenerateKfiRequests,
        ...data.applications,
        isAdminAdviser,
      }),
    applicationSummary:
      data.applicationSummary &&
      applicationSummaryResolver(data.applicationSummary),
    blogData: data.blogData && blogDataResolver(data.blogData),
  };
};

export default dashboardPageResolver;
