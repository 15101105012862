import { SHORT_DATE_FORMAT } from "lib/constants";
import { dayjs } from "utils";

const furtherAdvancesListPageResolver = (data) => {
  const displayDate = (date) => {
    if (!date) return "-";

    return dayjs(date).format(SHORT_DATE_FORMAT);
  };

  const furtherAdvance = ({ request }) => {
    return {
      referenceNo: request.reference_number.toString(),
      borrowers: request.client_name,
      illustrationId: request.illustration_reference,
      illustrationDate: displayDate(request.illustration_date),
      applicationReceived: displayDate(request.offer.application_received),
      requestId: request.id.toString(),
    };
  };

  return {
    furtherAdvances: data
      ? data.further_advance.requests.map(furtherAdvance)
      : [],
  };
};

export default furtherAdvancesListPageResolver;
