import {
  displayAmountWithCurrency,
  findYoungestBorrower,
  getAgeFromDate,
} from "utils";

const borrowerAgeAndBirthSex = ({
  date_of_birth,
  sex,
  applyAgeNextBirthday,
}) => {
  let age = getAgeFromDate(date_of_birth);
  if (applyAgeNextBirthday) ++age;
  return `${age} year old ${sex.toLowerCase()}`;
};

export const productSummaryResolver = (
  client,
  applyAgeNextBirthday,
  estimatedPropertyValue
) => {
  // If there is an active application:
  // Applicants should be used in favour of borrowers
  // The property details linked to the active application should be used
  // in favour of the client's property
  const activeApplication = client.active_application;
  const property = activeApplication
    ? client.active_application.property_details
    : client.property;
  const activeBorrowers = activeApplication
    ? client.active_application.applicants_data
    : client.borrowers;

  activeBorrowers.forEach((borrower) => {
    borrower.applyAgeNextBirthday =
      borrower === findYoungestBorrower(activeBorrowers) &&
      applyAgeNextBirthday;
  });
  const orderedBorrowers = activeBorrowers.sort((_a, b) =>
    b.primary_borrower ? 1 : -1
  );

  const borrowersAgeAndBirthSex = orderedBorrowers
    .map((borrower) => borrowerAgeAndBirthSex(borrower, applyAgeNextBirthday))
    .join(" and ");

  const oneOrTwoApplicants =
    activeBorrowers.length === 1 ? "one applicant" : "two applicants";

  const formattedPropertyValue = displayAmountWithCurrency(
    estimatedPropertyValue || property.value
  );

  return `This summary is based on ${oneOrTwoApplicants} (a ${borrowersAgeAndBirthSex}) with a property valued at ${formattedPropertyValue}.`;
};

export default productSummaryResolver;
