import { EXTERNAL_LINKS } from "lib/constants";
import { EMAILS } from "lib/constants";

export default {
  support: {
    email: EMAILS.adviserSupport,
    link: EXTERNAL_LINKS.adviserSupport,
    openingHours: "Monday to Friday, 9am-5pm",
    phoneNumber: "0800 068 0212",
  },
  contactUsLink: EXTERNAL_LINKS.contact,
  registerLink: EXTERNAL_LINKS.register,
  loginLink: EXTERNAL_LINKS.login,
};
