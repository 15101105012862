import {
  applicantsResolver,
  borrowersResolver,
  filtersResolver,
} from "resolvers";
import qs from "qs";
import { linkFor } from "utils";
import {
  COMPLETE_CLIENT,
  VIEW_APPLICATION,
  VIEW_PRODUCTS,
} from "lib/constants";

const clientResolver = ({ canGenerateKfiRequests, data }) => {
  const clientHasApplication = data.applications.length > 0;

  const applicationReferenceNo = () => {
    if (data.has_active_applications)
      return parseInt(data.active_application.application_reference);

    if (clientHasApplication)
      return parseInt(data.applications[0].application_reference);

    return "-";
  };

  const applicationFormId = () =>
    data.has_active_applications
      ? data.active_application.application_form_id
      : data.applications[0]?.application_form_id;

  const applicationStatus = () =>
    data.has_active_applications
      ? data.active_application.status
      : data.applications[0]?.status;

  const applicationLink = () =>
    linkFor("application", {
      applicationFormId: applicationFormId(),
      referenceNo: applicationReferenceNo(),
      status: applicationStatus(),
    });

  const actionLabel = () => {
    if (clientHasApplication) {
      return VIEW_APPLICATION;
    }
    if (!data.details_complete) {
      return COMPLETE_CLIENT;
    }
    return VIEW_PRODUCTS;
  };

  const filters = filtersResolver(data.loan_information);

  const clientActionLink = () => {
    if (clientHasApplication) return applicationLink();

    if (!data.details_complete) {
      return linkFor("client", {
        clientId: data.id,
      });
    }

    if (!canGenerateKfiRequests) {
      return "/";
    }

    let queryString = filters && `?${qs.stringify(filters)}`;

    return linkFor("products", { clientId: data.id, queryString });
  };

  const disableActionClass =
    !canGenerateKfiRequests && actionLabel(data) !== VIEW_APPLICATION
      ? "disabledLink"
      : "";

  const borrowers = data.has_active_applications
    ? applicantsResolver(data.active_application.applicants_data).fullName
    : borrowersResolver(data.borrowers).fullName;

  const linkHref = clientHasApplication
    ? applicationLink()
    : linkFor("client", { clientId: data.id });

  return {
    referenceNo: data.id,
    borrowers: borrowers,
    postcode: data.property?.postcode,
    illustrations: data.illustrations.length,
    applicationNo: applicationReferenceNo(),
    hasCompleteDetails: data.details_complete || clientHasApplication,
    action: {
      label: actionLabel(),
      href: clientActionLink(),
      disableClass: disableActionClass,
    },
    link: {
      label: "View Client",
      href: linkHref,
    },
  };
};

export default clientResolver;
