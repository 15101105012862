import { NONE, ZERO_AMOUNT } from "lib/constants";
import { parseAmountToInteger } from "utils";

const presentIllustrationRequestForApi = ({
  productsRawApiResponse,
  product,
  fees,
  filtersData,
  propertyValue,
  recommended,
  club,
}) => {
  const activeApplication =
    productsRawApiResponse.data.client.active_application;

  return {
    kfi_request: {
      product_id: product.id,
      postcode: activeApplication
        ? activeApplication.property_details.postcode
        : productsRawApiResponse.data.client.property?.postcode,
      property_value: parseAmountToInteger(propertyValue),
      ex_local_authority: activeApplication
        ? activeApplication.property_details.ex_local_authority
        : productsRawApiResponse.data.client.property.ex_local_authority,
      residence_status_id: activeApplication
        ? activeApplication.property_details.residence_status_id
        : productsRawApiResponse.data.client.property.residence_status.id,
      equity_protection:
        filtersData?.inheritance_guarantee?.percentage || ZERO_AMOUNT,
      adviser_fee: parseAmountToInteger(fees.adviceFee),
      append_completion_fee_to_mortgage: fees.appendCompletionFee,
      borrow_max_amount: activeApplication
        ? activeApplication.property_details.valuation_increase_contact
        : productsRawApiResponse.data.client.property
            .valuation_increase_contact,
      revaluation: productsRawApiResponse.data.client.active_application?.status
        ? true
        : false,
      initial_advance_type: filtersData.initial_advance.type,
      initial_advance_value: filtersData.initial_advance?.value,
      cash_reserve_type: filtersData.cash_reserve.type,
      cash_reserve_value:
        filtersData.cash_reserve.type === NONE
          ? ZERO_AMOUNT
          : filtersData.cash_reserve?.value,
      product_recommended: recommended,
      age_next_birthday: filtersData?.age_next_birthday
        ? filtersData.age_next_birthday.active
        : false,
      club_id: club,
    },
  };
};

export default presentIllustrationRequestForApi;
