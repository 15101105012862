import { borrowersResolver } from "resolvers";
import { linkFor, removeFalseyArrayItems } from "utils";
import {
  CLIENT_DETAILS_TYPE_UPDATE,
  CLIENT_DETAILS_TYPE_NEW,
} from "lib/constants";

const clientDetailsPageResolver = ({ client, settings, type }) => {
  let borrowers;
  let clientId;

  if (client) {
    borrowers = borrowersResolver(client.borrowers);
    clientId = client.id;
  }

  switch (type) {
    case CLIENT_DETAILS_TYPE_UPDATE:
      return {
        base: {
          meta: { title: "Client details" },
          header: {
            breadcrumbs: removeFalseyArrayItems([
              { title: "Dashboard", href: linkFor("dashboard") },
              { title: "Clients", href: linkFor("clients") },
              client && {
                title: borrowers.fullName,
                href: linkFor("client", { clientId: client.id }),
              },
              { title: "Client details", isCurrent: true },
            ]),
            title: { heading: "Client details" },
          },
        },
        clientId,
        borrowers,
        settings,
        type,
      };

    case CLIENT_DETAILS_TYPE_NEW:
      return {
        base: {
          meta: { title: "New client" },
          header: {
            breadcrumbs: [
              { title: "Dashboard", href: linkFor("dashboard") },
              { title: "New client", isCurrent: true },
            ],
            title: {
              heading: "New client",
            },
            progress: 40,
          },
        },
        // Only include items if present
        ...(clientId ? { clientId } : {}),
        ...(borrowers ? { borrowers } : {}),
        ...(settings ? { settings } : {}),
        type,
      };
  }
};

export default clientDetailsPageResolver;
