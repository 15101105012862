import { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames/bind";
import {
  Close,
  NoticeIconInfo,
  NoticeIconWarning,
  Tick,
  WarningIconOutlined,
} from "lib/icons";
import styles from "./NoticeBanner.module.scss";

export const TYPES = ["alert", "warning", "success", "information"];
const cx = classNames.bind(styles);

const NoticeBanner = (props) => {
  const {
    children,
    hasCloseButton,
    open: initialOpenValue = true,
    title,
    type,
  } = props;

  const id = `notice_banner_${props.id}`;
  const contentId = `${id}_content`;
  const titleId = `${id}_title`;

  const [open, setIsOpen] = useState(initialOpenValue);

  if (!open) return null;

  const iconByNoticeTypeMap = {
    alert: NoticeIconWarning,
    warning: WarningIconOutlined,
    success: Tick,
    information: NoticeIconInfo,
  };

  // eslint-disable-next-line security/detect-object-injection
  const Icon = iconByNoticeTypeMap[type];

  const getAttrs = () => {
    const attrs = {
      className: cx("notice", type),
      id,
      role: hasCloseButton ? "dialog" : "alert",
    };

    if (type === "alert") {
      attrs.role = hasCloseButton ? "alertdialog" : "alert";
    }

    if (title) {
      return {
        ...attrs,
        "aria-labelledby": titleId,
        "aria-describedby": contentId,
      };
    }

    return {
      ...attrs,
      "aria-labelledby": contentId,
    };
  };

  return (
    <div {...getAttrs()}>
      <figure className={cx("iconContainer")}>
        <Icon />
      </figure>
      <div
        id={contentId}
        data-testid={contentId}
        className={cx("contentContainer", { hasCloseButton })}
      >
        {title && (
          <span className={cx("title")} id={titleId} data-testid={titleId}>
            {title}
          </span>
        )}
        {children && (
          <span className={cx("content", { hasTitle: title })}>{children}</span>
        )}
      </div>
      {hasCloseButton && (
        <button className={cx("closeButton")} onClick={() => setIsOpen(false)}>
          <Close />
        </button>
      )}
    </div>
  );
};

NoticeBanner.propTypes = {
  children: PropTypes.node,
  hasCloseButton: PropTypes.bool,
  id: PropTypes.string.isRequired,
  open: PropTypes.bool,
  title: PropTypes.string,
  type: PropTypes.oneOf(TYPES).isRequired,
};

export default NoticeBanner;
