const noticeTextResolver = (borrowerName, panels) => {
  const isPanelIncomplete = (panel) =>
    panel.details.some((item) => item.incomplete);

  const panelStates = {
    adviser: {
      incomplete: isPanelIncomplete(panels.adviser),
      text: "Adviser details",
    },
    client: {
      incomplete: isPanelIncomplete(panels.client),
      text: "Client details",
    },
    property: {
      incomplete: isPanelIncomplete(panels.property),
      text: "Property details",
    },
    loan: {
      incomplete: isPanelIncomplete(panels.loan),
      text: "Loan details",
    },
  };

  const allPanelsAreComplete = Object.keys(panelStates).reduce((acc, key) => {
    return panelStates[key].incomplete ? false : acc;
  }, true);

  if (allPanelsAreComplete) {
    return `You can view the products available to ${borrowerName} below. Click ‘Edit’ to change any of the details you’ve provided.`;
  }

  const incompleteTextArray = Object.keys(panelStates)
    .filter((key) => panelStates[key].incomplete)
    .map((key) => panelStates[key].text);

  const incompletePanelsText = incompleteTextArray
    .join(", ")
    .replace(/, ([^,]*)$/, " and $1");

  const singularCondition = incompleteTextArray.length === 1 ? "has" : "have";

  const propertyDetailsIncomplete = panelStates.property.incomplete
    ? " You must complete Property details before you can complete Loan details."
    : "";

  return `You can view the products available to ${borrowerName} once ${incompletePanelsText} ${singularCondition} been completed. Click ‘Edit’ to change any of the details you’ve provided.${propertyDetailsIncomplete}`;
};

export default noticeTextResolver;
