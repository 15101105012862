import { SHORT_DATE_FORMAT } from "lib/constants";
import prettyBytes from "pretty-bytes";
import { dayjs } from "utils";

const documentResolver = (data) => {
  return {
    title: data.download_name,
    size: `(${prettyBytes(data.size)})`,
    href: data.pdf_path,
    createdAt: dayjs(data.created_at).format(SHORT_DATE_FORMAT),
    type: data.type,
    sourceType: "furtherAdvanceDownload",
  };
};

export default documentResolver;
