import { adviserResolver } from "resolvers";
import { displayAmountWithCurrency } from "utils";

const applicationDetailsResolver = (application) => {
  return {
    illustrationNo: application.illustration.illustration_reference,
    accountNo: application.application_reference,
    product: application.illustration.product_name,
    loanAmount: displayAmountWithCurrency(
      application.illustration.initial_advance
    ),
    adviserFullName: adviserResolver(application.illustration.adviser).fullName,
    adviserCommission: parseFloat(application.illustration.commission_fee)
      ? displayAmountWithCurrency(application.illustration.commission_fee)
      : "£0",
  };
};

export default applicationDetailsResolver;
