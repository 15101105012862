import { displayAmountWithCurrency } from "utils";

const rollupResolver = (data) => {
  return {
    year: data.year,
    startingBalance: displayAmountWithCurrency(data.balance),
    interestCharge: displayAmountWithCurrency(data.interest, true),
    feesAmount: displayAmountWithCurrency(data.fees, true),
    endOfYearBalance: displayAmountWithCurrency(data.total),
  };
};

export default rollupResolver;
