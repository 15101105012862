import { useEffect } from "react";
import Link from "next/link";
import { useSession, signOut } from "next-auth/react";
import PropTypes from "prop-types";
import classNames from "classnames/bind";
import { useStore } from "context";
import { Menu as MenuIcon, Close, LogOut } from "lib/icons";
import { FURTHER_ADVANCES } from "lib/constants";
import styles from "./Menu.module.scss";
import { linkFor } from "utils";
import { useRouter } from "next/router";

const cx = classNames.bind(styles);

const Menu = ({ links }) => {
  const router = useRouter();
  const { data: session } = useSession();
  const user = session.user;
  const canGenerateKfiRequests = user.canGenerateKfiRequests;

  const linksToDisplay = !canGenerateKfiRequests
    ? links.filter(({ label }) => label !== FURTHER_ADVANCES)
    : links;

  const {
    state: { menuIsOpen },
    dispatch,
  } = useStore();
  const tabIndex = menuIsOpen ? null : "-1";

  useEffect(() => {
    const handleRouteChange = () => {
      dispatch({ type: "SET_MENU_OPEN", payload: false });
    };

    router.events.on("routeChangeStart", handleRouteChange);

    return () => {
      router.events.off("routeChangeStart", handleRouteChange);
    };
  }, []);

  const toggleMenu = () => {
    dispatch({ type: "SET_MENU_OPEN", payload: !menuIsOpen });
  };

  const handleLogOutClick = async (e) => {
    e.preventDefault();
    dispatch({ type: "SET_MENU_OPEN", payload: false });
    await signOut({ callbackUrl: linkFor("logout") });
  };

  useEffect(() => {
    if (menuIsOpen) {
      document.body.classList.add("u-scrollLocked");
    } else {
      document.body.classList.remove("u-scrollLocked");
    }
  }, [menuIsOpen]);

  return (
    <>
      <button
        aria-expanded={menuIsOpen}
        aria-label={`${menuIsOpen ? "Close" : "Open"} menu`}
        aria-controls="menu"
        className={cx("toggleButton")}
        id="menuButton"
        onClick={toggleMenu}
      >
        <span>{menuIsOpen ? "Close" : "Menu"}</span>
        {menuIsOpen ? <Close /> : <MenuIcon />}
      </button>
      <button
        aria-hidden="true"
        className={cx("overlay", { menuIsOpen })}
        onClick={toggleMenu}
        tabIndex="-1"
      />
      <nav
        aria-labelledby="menuButton"
        aria-hidden={!menuIsOpen}
        className={cx("menu", { menuIsOpen })}
        data-testid="menu"
        id="menu"
      >
        <header className={cx("header")}>
          Welcome back{" "}
          <span className={cx("userFullName")}>{user.fullName}</span>
        </header>
        <ul className={cx("nav")}>
          {linksToDisplay.map(({ href, label }) => (
            <li key={label}>
              <Link href={href} className={cx("link")} tabIndex={tabIndex}>
                {label}
              </Link>
            </li>
          ))}
        </ul>
        <footer className={cx("footer")}>
          <button
            className={cx("link", "logOutLink")}
            onClick={handleLogOutClick}
            tabIndex={tabIndex}
          >
            <span>Log Out</span>
            <LogOut />
          </button>
        </footer>
      </nav>
    </>
  );
};

Menu.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default Menu;
