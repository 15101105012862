import PropTypes from "prop-types";
import classNames from "classnames/bind";
import styles from "./HighlightPanel.module.scss";

const cx = classNames.bind(styles);

export const TYPES = ["primary", "secondary", "tertiary"];

const HighlightPanel = ({ description, title, type, value }) => (
  <div className={cx("container", type)}>
    <h3 className={cx("title")}>{title}</h3>
    <p className={cx("description")}>{description}</p>
    <span className={cx("value")}>{value}</span>
  </div>
);

HighlightPanel.propTypes = {
  description: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.oneOf(TYPES).isRequired,
  value: PropTypes.string.isRequired,
};

export default HighlightPanel;
