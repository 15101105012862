import { useBorrower } from "context";
import {
  SET_TITLE,
  SET_FIRST_NAME,
  SET_LAST_NAME,
  SET_DOB,
  SET_BIRTH_SEX,
  SET_MIDDLE_NAME,
} from "context/Borrower/actions";
import PropTypes from "prop-types";
import classNames from "classnames/bind";
import { FormField, NoticeBanner } from "components";
import birthdayCalculator from "utils/birthdayCalculator";
import styles from "./Borrower.module.scss";

const cx = classNames.bind(styles);

const Borrower = ({
  ageNextBirthdayGracePeriod: gracePeriod,
  borrowerMaximumAge: maxAge,
  borrowerMinimumAge: minAge,
  id,
  title,
  firstName,
  middleName,
  lastName,
  dob,
  birthSex,
}) => {
  const { dispatch } = useBorrower();

  const handleTitleChange = (e) => {
    dispatch({
      type: SET_TITLE,
      payload: { value: e.target.value, id },
    });
  };

  const handleFirstNameChange = (e) => {
    dispatch({
      type: SET_FIRST_NAME,
      payload: { value: e.target.value, id },
    });
  };

  const handleMiddleNameChange = (e) => {
    dispatch({
      type: SET_MIDDLE_NAME,
      payload: { value: e.target.value, id },
    });
  };

  const handleLastNameChange = (e) => {
    dispatch({
      type: SET_LAST_NAME,
      payload: { value: e.target.value, id },
    });
  };

  const handleDateOfBirthChange = (e) => {
    dispatch({
      type: SET_DOB,
      payload: { value: e.target.value, id },
    });
  };

  const handleBirthSexChange = (e) => {
    dispatch({
      type: SET_BIRTH_SEX,
      payload: { value: e.target.value, id },
    });
  };

  const titleProps = {
    group: `${id}_title`,
    isRequired: true,
    onChange: handleTitleChange,
    value: title,
  };

  const firstNameProps = {
    isRequired: true,
    label: "First name",
    onChange: handleFirstNameChange,
    value: firstName,
  };

  const middleNameProps = {
    isRequired: false,
    label: "Middle name",
    onChange: handleMiddleNameChange,
    value: middleName,
  };

  const lastNameProps = {
    isRequired: true,
    label: "Last name",
    onChange: handleLastNameChange,
    value: lastName,
  };

  const dobProps = {
    gracePeriod,
    isRequired: true,
    maxAge,
    minAge,
    onChange: handleDateOfBirthChange,
    value: dob,
  };

  const birthSexProps = {
    group: `${id}_birth_sex`,
    initialValue: birthSex,
    isRequired: true,
    label: "Birth sex",
    onChange: handleBirthSexChange,
    radios: [
      {
        label: "Male",
        value: "Male",
      },
      {
        label: "Female",
        value: "Female",
      },
    ],
  };

  const NoticeBanners = () => {
    const ageNextBirthday = dob && birthdayCalculator({ dob, gracePeriod });

    if (ageNextBirthday) {
      return (
        <div className={cx("dobNoticeBannerContainer")}>
          <NoticeBanner
            id={`${id}_age_next_birthday`}
            title="Age next birthday"
            type="information"
          >
            Your client has a birthday within the next six weeks. If you would
            like to use their age next birthday, please continue the journey and
            choose the “Use age next birthday” option on the product list
            screen.
          </NoticeBanner>
        </div>
      );
    }

    return null;
  };

  return (
    <div className={cx("container")}>
      <div className={cx("title")}>
        <FormField.Title {...titleProps} />
      </div>
      <div className={cx("firstName")}>
        <FormField.Text {...firstNameProps} />
      </div>
      <div className={cx("middleName")}>
        <FormField.Text {...middleNameProps} />
      </div>
      <div className={cx("lastName")}>
        <FormField.Text {...lastNameProps} />
      </div>
      <div className={cx("dob")}>
        <FormField.DateOfBirth {...dobProps} />
      </div>
      <NoticeBanners />
      <div className={cx("birthSex")}>
        <FormField.RadioGroup {...birthSexProps} />
      </div>
    </div>
  );
};

Borrower.propTypes = {
  ageNextBirthdayGracePeriod: PropTypes.number.isRequired,
  birthSex: PropTypes.oneOf(["Male", "Female"]),
  borrowerMaximumAge: PropTypes.number.isRequired,
  borrowerMinimumAge: PropTypes.number.isRequired,
  dob: PropTypes.string,
  firstName: PropTypes.string,
  id: PropTypes.oneOf(["primaryBorrower", "secondaryBorrower"]).isRequired,
  lastName: PropTypes.string,
  middleName: PropTypes.string,
  title: PropTypes.string,
};

export default Borrower;
