import client from "services/client";
import { createQueryStringFromObject } from "utils";

const get = async (accessToken, { query, clientId } = {}) => {
  client.accessToken = accessToken;

  const url = clientId
    ? `/clients/${clientId}`
    : `/clients${createQueryStringFromObject(query)}`;

  return await client.get(url).catch((error) => error);
};

const create = async ({ accessToken, requestData }) => {
  client.accessToken = accessToken;

  return await client.post("/clients", requestData).catch((error) => error);
};

const updateBorrowers = async (accessToken, { clientId, requestData }) => {
  client.accessToken = accessToken;

  return await client
    .post(`/clients/${clientId}/borrowers`, requestData)
    .catch((error) => error);
};

const updateClub = async (accessToken, { clientId, requestData }) => {
  client.accessToken = accessToken;

  return await client
    .patch(`/clients/${clientId}/club`, requestData)
    .catch((error) => error);
};

export default { create, get, updateBorrowers, updateClub };
