import PropTypes from "prop-types";
import Link from "next/link";
import classNames from "classnames/bind";
import useDropdownVisible from "hooks/useDropdownVisible";
import Button from "../Button";
import styles from "./DropdownButton.module.scss";

const cx = classNames.bind(styles);

const DropdownButton = ({ align, ariaLabel, buttonText, icon, options }) => {
  const { ref, isVisible, toggleVisibility } = useDropdownVisible();

  const buttonClickHandler = (e) => {
    e.preventDefault();
    toggleVisibility();
  };

  const optionItem = (option, index) => {
    return (
      <li key={index}>
        {option.href ? (
          <Link href={option.href} className={styles.dropdownLink}>
            {option.label}
          </Link>
        ) : (
          <Button customClass={styles.dropdownButton} onClick={option.onClick}>
            {option.label}
          </Button>
        )}
      </li>
    );
  };

  return (
    <div
      className={styles.wrapper}
      ref={ref}
      data-testid="dropdownButton"
      aria-expanded={isVisible}
    >
      <Button
        ariaLabel={`${isVisible ? "Close" : "Open"} ${ariaLabel}`}
        icon={icon}
        onClick={buttonClickHandler}
      >
        {buttonText}
      </Button>
      {isVisible && (
        <ul className={cx(styles.dropdownWrapper, align)}>
          {options.map(optionItem)}
        </ul>
      )}
    </div>
  );
};

DropdownButton.propTypes = {
  align: PropTypes.oneOf(["left", "right"]),
  ariaLabel: PropTypes.string,
  buttonText: PropTypes.string,
  icon: PropTypes.oneOf([null, "AddCircle"]),
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        label: PropTypes.string,
        href: PropTypes.string,
      }),
      PropTypes.shape({
        label: PropTypes.string,
        onClick: PropTypes.func,
      }),
    ]).isRequired
  ).isRequired,
};

export default DropdownButton;
