import clientOverviewStub from "stubs/clientOverview";
import {
  booleanToText,
  displayAmountWithCurrency,
  removeFalseyArrayItems,
} from "utils";

const propertyPanelResolver = (propertyData) => {
  const SHELETERED_ACCOMODATION_ID = 10;

  const usageTypes = {
    primary_residence: "Primary Residence",
    buy_to_let: "Buy to Let",
    second_home: "Second Home",
  };
  const tenures = {
    freehold: "Freehold",
    leasehold: "Leasehold",
  };

  return {
    ...clientOverviewStub.panels.property,
    details: removeFalseyArrayItems([
      {
        label: "Postcode of property",
        value: propertyData?.postcode || "",
        incomplete: !propertyData?.postcode,
      },
      {
        label: "Ex-local authority property",
        value: propertyData
          ? booleanToText(propertyData.ex_local_authority)
          : "",
        incomplete: typeof propertyData?.ex_local_authority !== "boolean",
      },
      {
        label: "Property type",
        value: usageTypes[propertyData?.usage_type] || "",
        incomplete: !propertyData?.usage_type,
      },
      {
        label: "Tenure",
        value: tenures[propertyData?.tenure] || "",
        incomplete: !propertyData?.tenure,
      },
      propertyData?.tenure === "leasehold" && {
        label: "Lease remaining",
        value: propertyData?.lease_term_remaining || "",
        incomplete: !propertyData?.lease_term_remaining,
      },
      {
        label: "Sheltered accomodation",
        value: propertyData
          ? booleanToText(
              propertyData.residence_status.id === SHELETERED_ACCOMODATION_ID
            )
          : "",
        incomplete: !propertyData,
      },
      {
        label: "Estimated value",
        value: propertyData
          ? displayAmountWithCurrency(propertyData.value)
          : "",
        incomplete: !propertyData?.value,
      },
      {
        label: "Independant valuation alert",
        value: propertyData
          ? booleanToText(propertyData.valuation_increase_contact)
          : "",
        incomplete: !propertyData,
      },
    ]),
    href: "",
  };
};

export default propertyPanelResolver;
