import { adviserResolver, borrowersResolver, dateResolver } from "resolvers";
import { disabledActionLink, linkFor, linkLabelMap, statusMap } from "utils";
import { DRAFT } from "lib/constants";

const applicationResolver = ({
  canGenerateKfiRequests,
  data,
  isAdminAdviser,
}) => {
  const getLink = {
    href: linkFor("applicationDetails", {
      referenceNo: data.application_reference,
    }),
  };

  const getActionLink = {
    href: linkFor("application", {
      id: data.id,
      applicationFormId: data.application_form_id,
      referenceNo: data.application_reference,
      status: statusMap(data),
    }),
    label: linkLabelMap(data),
  };

  return {
    actionLink: getActionLink,
    adviserFullName: adviserResolver(data.illustration.adviser).fullName,
    isDisabled: disabledActionLink(
      data.aasm_state,
      isAdminAdviser,
      canGenerateKfiRequests
    ),
    date: dateResolver(data.date_received),
    id: data.id,
    isDeletable: data.aasm_state === DRAFT,
    illustrationNo: data.illustration.illustration_reference,
    link: getLink,
    name: borrowersResolver(data.applicants).fullName,
    postcode: data.property.postcode,
    referenceNo: data.application_reference,
    aasmState: statusMap(data),
    status: data.status,
  };
};

export default applicationResolver;
