import PropTypes from "prop-types";
import { Button } from "components";
import styles from "./Steps.module.scss";
import { ICONS } from "lib/constants";

const Steps = ({ steps }) => {
  const Cta = (cta, i) => {
    const props = {
      children: cta.label,
      href: cta.href,
      onClick: cta.onClick,
      icon: cta.icon,
      isDisabled: cta.isDisabled,
    };
    return (
      <div key={i}>
        <Button {...props} />
      </div>
    );
  };

  return (
    <ol className={styles.steps}>
      {steps.map(({ content, ctas, title }, i) => (
        <li className={styles.step} key={i}>
          <h3 className={styles.title}>{title}</h3>
          <div className={styles.content}>{content}</div>
          {ctas && <div className={styles.ctas}>{ctas.map(Cta)}</div>}
        </li>
      ))}
    </ol>
  );
};

Steps.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.node.isRequired,
      content: PropTypes.node.isRequired,
      ctas: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,
          href: PropTypes.string,
          onClick: PropTypes.func,
          icon: PropTypes.oneOf(ICONS),
          isDisabled: PropTypes.bool,
        })
      ),
    }).isRequired
  ),
};

export default Steps;
