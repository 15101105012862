import PropTypes from "prop-types";
import classNames from "classnames/bind";
import { Button } from "components";
import styles from "./ProductListPagination.module.scss";

const cx = classNames.bind(styles);

const ProductListPagination = ({ pageMeta, updateRequestData }) => {
  return (
    <div className={cx("paginationWrapper")}>
      <Button
        onClick={() =>
          updateRequestData({ type: "page", value: pageMeta.currentPage - 1 })
        }
        isDisabled={pageMeta.currentPage === 1}
      >
        Show less
      </Button>
      <span className={cx("paginationText")}>
        Displaying {pageMeta.totalItems} of {pageMeta.totalProducts} products
      </span>
      <Button
        onClick={() =>
          updateRequestData({ type: "page", value: pageMeta.currentPage + 1 })
        }
        isDisabled={pageMeta.currentPage === pageMeta.totalPages}
      >
        Show more
      </Button>
    </div>
  );
};

ProductListPagination.propTypes = {
  pageMeta: PropTypes.shape({
    currentPage: PropTypes.number.isRequired,
    totalItems: PropTypes.number.isRequired,
    totalPages: PropTypes.number.isRequired,
    totalProducts: PropTypes.number.isRequired,
  }).isRequired,
  updateRequestData: PropTypes.func.isRequired,
};

export default ProductListPagination;
