import PropTypes from "prop-types";
import { Button } from "components";
import { CanadaLifeMainLogo } from "lib/icons";
import { EXTERNAL_LINKS } from "lib/constants";
import styles from "./Unauthenticated.module.scss";
import { useRouter } from "next/router";

interface UnauthenticatedLayoutProps {
  children: React.ReactNode;
}

const UnauthenticatedLayout = ({ children }: UnauthenticatedLayoutProps) => {
  const router = useRouter();
  const path = router.asPath;

  const showRegistration = !path.includes("/forgot-password?token=");

  return (
    <div className={styles.container}>
      <div className={styles.intro}>
        <CanadaLifeMainLogo />
        <h1>Welcome</h1>
        <h2>Home Finance Adviser Portal</h2>
        {showRegistration && (
          <>
            <p>
              Create new quotes and submit cases. First time here? Please{" "}
              <a
                className={styles.link}
                href={EXTERNAL_LINKS.register}
                rel="noopener noreferrer"
                target="_blank"
              >
                register to get started
              </a>{" "}
              or{" "}
              <a
                className={styles.link}
                href={EXTERNAL_LINKS.faqs}
                rel="noopener noreferrer"
                target="_blank"
              >
                read our FAQs
              </a>
              .
            </p>
            <Button
              icon="Apply"
              theme="negative"
              href={EXTERNAL_LINKS.register}
            >
              Register with us
            </Button>
          </>
        )}
      </div>
      {children}
    </div>
  );
};

UnauthenticatedLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default UnauthenticatedLayout;
