import PropTypes from "prop-types";
import classNames from "classnames/bind";
import Link from "next/link";
import { Panel } from "components";
import { ArrowForward } from "lib/icons";
import { linkFor } from "utils";
import styles from "./ApplicationListOverview.module.scss";

const cx = classNames.bind(styles);

const ListItem = ({ count, description, isHighlighted, link, testId }, i) => (
  <li className={cx("listItem")} key={i}>
    <Link
      href={link}
      className={cx("link", { isHighlighted })}
      data-testid={testId}
    >
      <span className={cx("count")}>{count}</span>
      <span>{description}</span>
    </Link>
  </li>
);

ListItem.propTypes = {
  count: PropTypes.number.isRequired,
  description: PropTypes.string.isRequired,
  isHighlighted: PropTypes.bool.isRequired,
  link: PropTypes.string.isRequired,
  testId: PropTypes.string,
};

const ApplicationListOverview = ({ applicationSummary }) => (
  <Panel size="small">
    <div className={cx("container")}>
      <header className={cx("header")}>
        <h3 className={cx("title")}>Applications</h3>
        <Link
          href={linkFor("applications")}
          aria-label="Go to your applications"
        >
          <ArrowForward data-testid="dashboard_applications_button" />
        </Link>
      </header>
      <ol className={cx("list")}>{applicationSummary.map(ListItem)}</ol>
    </div>
  </Panel>
);

ApplicationListOverview.propTypes = {
  applicationSummary: PropTypes.array.isRequired,
};

export default ApplicationListOverview;
