import { applicationsResolver, pagedDataMetaResolver } from "resolvers";

const applicationsPageResolver = ({
  canGenerateKfiRequests,
  data,
  isAdminAdviser,
}) => {
  return {
    applications: applicationsResolver({
      canGenerateKfiRequests,
      ...data,
      isAdminAdviser,
    }),
    meta: pagedDataMetaResolver(data),
  };
};

export default applicationsPageResolver;
