import PropTypes from "prop-types";
import classNames from "classnames/bind";
import { Button } from "components";
import styles from "./IllustrationCta.module.scss";

const cx = classNames.bind(styles);

const IllustrationCta = ({
  lead = "You can generate another illustration by returning to the list of available products and amending your filtered preferences.",
  link,
  title = "Get another illustration",
}) => (
  <div className={cx("container")}>
    <h3 className={cx("title")}>{title}</h3>
    {/* eslint-disable-next-line @next/next/no-img-element */}
    <img
      className={cx("image")}
      alt="Binoculars Illustration"
      src="/images/binoculars-illustration.svg"
    />
    <p className={cx("lead")}>{lead}</p>
    <Button href={link.href} icon="ArrowForward" theme="negative">
      {link.label}
    </Button>
  </div>
);

IllustrationCta.propTypes = {
  lead: PropTypes.string,
  link: PropTypes.shape({
    href: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  }).isRequired,
  title: PropTypes.string,
};

export default IllustrationCta;
