const filtersResolver = (loanInformation) => {
  if (
    !loanInformation ||
    loanInformation.loan_amount == null ||
    loanInformation.cash_reserve == null
  )
    return;

  return {
    initial_advance: {
      type: loanInformation.loan_amount_maximum ? "maximum" : "specific",
      value: loanInformation.loan_amount,
    },
    cash_reserve: {
      type: loanInformation.cash_reserve_maximum
        ? "maximum"
        : loanInformation.cash_reserve === 0
        ? "none"
        : "specific",
      value: loanInformation.cash_reserve,
    },
  };
};

export default filtersResolver;
