import {
  ageNextBirthdayResolver,
  productListPageMetaResolver,
  productsResolver,
} from "resolvers";
import { linkFor } from "utils";

const productListPageResolver = ({ productResponse, clientId }) => {
  return {
    meta: {
      title: "Available products",
    },
    header: {
      title: {
        heading: "Available products",
      },
      breadcrumbs: [
        { title: "Dashboard", href: linkFor("dashboard") },
        { title: "Clients", href: linkFor("clients") },
        // TODO: Add Client name - To come from API
        { title: "Client overview", href: linkFor("client", { clientId }) },
        { title: "Available products", isCurrent: true },
      ],
    },
    products: productsResolver(productResponse),
    ageNextBirthday: ageNextBirthdayResolver(
      productResponse.data.client.borrowers
    ),
    pageMeta: productListPageMetaResolver(productResponse),
  };
};

export default productListPageResolver;
