import { documentResolver } from "resolvers";
import { displayAmountWithCurrency, linkFor } from "utils";

const furtherAdvanceDetailsPageResolver = ({ further_advance_detail }) => {
  const data = {
    illustrationReference: further_advance_detail.illustration_reference,
    referenceNo: further_advance_detail.account_number,
    product: further_advance_detail.product_name,
    loanAmount: displayAmountWithCurrency(
      further_advance_detail.loan_amount,
      true
    ),
    directDebitAmount: displayAmountWithCurrency(
      further_advance_detail.monthly_interest_payment,
      true
    ),
    adviserFee: displayAmountWithCurrency(
      further_advance_detail.advice_fee,
      true
    ),
    documents: further_advance_detail.documents.map(documentResolver),
  };

  return {
    data,
    meta: {
      title: `Further advance ${further_advance_detail.account_number}`,
    },
    header: {
      title: {
        heading: further_advance_detail.client_names,
        applicationNumber: further_advance_detail.account_number,
      },
      breadcrumbs: [
        { title: "Dashboard", href: linkFor("dashboard") },
        { title: "Further advances", href: linkFor("furtherAdvances") },
        { title: further_advance_detail.account_number, isCurrent: true },
      ],
    },
  };
};

export default furtherAdvanceDetailsPageResolver;
