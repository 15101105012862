import {
  borrowersResolver,
  filtersResolver,
  illustrationsResolver,
} from "resolvers";
import noticeTextResolver from "./noticeText";
import panelCompletionResolver from "./panelCompletion";
import propertyPanelResolver from "./propertyPanel";
import loanPanelResolver from "./loanPanel";
import clientOverviewStub from "stubs/clientOverview";
import { FALSY_STRING } from "lib/constants";
import { linkFor } from "utils";

const borrowerDetailsResolver = (
  { age, dobFormatted, birthSex, fullNameWithTitle },
  index,
  array
) => [
  {
    label: array.length > 1 ? `Borrower ${index + 1}` : "Borrower",
    value: fullNameWithTitle,
  },
  {
    label: "Date of Birth",
    value: `${dobFormatted} (${age})`,
  },
  {
    label: "Birth sex",
    value: birthSex,
  },
];

const clientOverviewPageResolver = ({ canGenerateKfiRequests, data }) => {
  const borrowers = borrowersResolver(data.client.borrowers);
  const illustrations = Array.isArray(data.client.illustrations)
    ? data.client.illustrations
    : [];
  const disabledLink = () => {
    return !data.client.property?.value || !canGenerateKfiRequests;
  };

  const panels = {
    adviser: {
      ...clientOverviewStub.panels.adviser,
      details: [{ label: "Club choice", value: data.client.club.name }],
      href: "",
      isDisabled: !canGenerateKfiRequests,
    },
    client: {
      ...clientOverviewStub.panels.client,
      details: borrowers.map(borrowerDetailsResolver).flat(),
      href: "",
      isDisabled: !canGenerateKfiRequests,
    },
    property: {
      ...propertyPanelResolver(data.client.property),
      isDisabled: !canGenerateKfiRequests,
    },
    loan: {
      ...loanPanelResolver(data.client.loan_information),
      isDisabled: disabledLink(),
    },
  };

  const filters = filtersResolver(data.client.loan_information);
  const borrowerNames = borrowers
    .map((borrower) => borrower.fullNameWithTitle)
    .join(" and ");

  const productsButtonDisabled = () => {
    return !panelCompletionResolver(panels) || !canGenerateKfiRequests;
  };

  return {
    meta: { title: borrowerNames },
    header: {
      title: { heading: borrowerNames },
      breadcrumbs: [
        { title: "Dashboard", href: linkFor("dashboard") },
        { title: "Clients", href: linkFor("clients") },
        { title: borrowerNames, isCurrent: true },
      ],
    },
    filters,
    panels,
    illustrations: illustrationsResolver(illustrations).map((illustration) => ({
      ...illustration,
      applyHref: data.client.has_active_applications
        ? null
        : linkFor("application", {
            id: illustration.id,
            status: "New",
          }),
      applyButtonDisabled:
        data.client.has_active_applications ||
        illustration.doNotAllowToLoadInOls === true ||
        illustration.recommended === FALSY_STRING,
      clientId: data.client.id,
    })),
    noticeText: noticeTextResolver(borrowerNames, panels),
    productsButtonDisabled: productsButtonDisabled(),
  };
};

export default clientOverviewPageResolver;
