import { Fragment, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames/bind";
import { Button, Panel } from "components";
import { ArrowDropDown, ArrowDropUp } from "lib/icons";
import styles from "./OverviewPanel.module.scss";

const cx = classNames.bind(styles);

const OverviewPanel = ({ button, description, details, isDisabled, title }) => {
  const [collapsed, setCollapsed] = useState(false);

  const collapseIcon = () => (
    <button
      onClick={() => setCollapsed((value) => !value)}
      className={cx("collapseButton")}
    >
      {collapsed ? <ArrowDropDown /> : <ArrowDropUp />}
    </button>
  );

  const buttonAttributes = () =>
    isDisabled ? { isDisabled } : { href: button.href };

  return (
    <Panel size="small">
      <div className={cx("container")}>
        <div className={cx("contentCell")}>
          <header className={cx("header")}>
            <h3 className={cx("title")}>{title}</h3>
            {collapseIcon()}
          </header>
          {!collapsed && (
            <>
              <div className={cx("descriptionWrapper")}>
                <p className={cx("description")}>{description}</p>
              </div>
              <ul className={cx("list")}>
                {details.map((item, index) => (
                  <Fragment key={index}>
                    {item.label === "Borrower 2" && (
                      <hr className={cx("divider")} />
                    )}
                    <li className={cx("listItem")}>
                      <span className={cx("itemLabel")}>{item.label}:</span>
                      <span
                        className={cx("itemValue", {
                          itemIncomplete: item.incomplete,
                        })}
                      >
                        {item.incomplete ? "Update" : item.value}
                      </span>
                    </li>
                  </Fragment>
                ))}
              </ul>
            </>
          )}
        </div>
        {!collapsed && (
          <footer className={cx("actionCell")}>
            <Button {...buttonAttributes()}>{button.label}</Button>
          </footer>
        )}
      </div>
    </Panel>
  );
};

OverviewPanel.propTypes = {
  button: PropTypes.shape({
    label: PropTypes.string,
    href: PropTypes.string,
  }).isRequired,
  description: PropTypes.string.isRequired,
  details: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string,
      incomplete: PropTypes.bool,
    })
  ),
  isDisabled: PropTypes.bool,
  title: PropTypes.string.isRequired,
};

export default OverviewPanel;
