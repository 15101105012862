import { META_TITLE_FALLBACK } from "lib/constants";

const metaResolver = ({ title, description } = {}) => {
  const array = {
    title: title ? `${title} | ${META_TITLE_FALLBACK}` : META_TITLE_FALLBACK,
    // Only include items if present
    ...(description !== undefined ? { description } : {}),
  };

  return array;
};

export default metaResolver;
