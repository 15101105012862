import { forwardRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames/bind";
import FormField from "../";
import { parseCurrency, formatCurrency } from "utils";
import { labelOrLabelledby } from "utils/propTypeValidations";
import styles from "./Currency.module.scss";

const cx = classNames.bind(styles);

let Currency = (props, ref) => {
  const getValueWithinBounds = (value) => {
    const parsedValue = parseCurrency(value);

    if (min > max || parsedValue < min) {
      return formatCurrency(min, { withDecimal });
    }

    if (parsedValue > max) {
      return formatCurrency(max, { withDecimal });
    }

    return value;
  };

  useEffect(() => {
    setValue(props.controlledValue);
  }, [props.controlledValue]);

  const handleChange = (e) => {
    onBeforeChange && onBeforeChange(e);

    if (!e.target.value) {
      setValue(e.target.value);
      onAfterChange && onAfterChange(e);
      return;
    }

    const valueWithinBounds = getValueWithinBounds(e.target.value);
    const formattedValue = formatCurrency(valueWithinBounds, { withDecimal });

    const getCommaCount = (string) => (string.match(/,/g) || []).length;

    const commaCountBeforeFormatting = getCommaCount(e.target.value);
    const commaCountAfterFormatting = getCommaCount(formattedValue);
    const selectionPosition =
      e.target.selectionStart +
      commaCountAfterFormatting -
      commaCountBeforeFormatting;

    e.target.value = formattedValue;
    e.target.selectionStart = selectionPosition;
    e.target.selectionEnd = selectionPosition;

    setValue(formattedValue);

    onAfterChange && onAfterChange(e);
  };

  const handleKeyPress = (e) => {
    onKeyPress && onKeyPress(e);

    !/[0-9]|(Enter)/.test(e.key) && e.preventDefault();
  };

  const handleKeyDown = (e) => {
    onBeforeChange && onBeforeChange(e);

    if (!["ArrowUp", "ArrowDown"].includes(e.key)) return;

    e.preventDefault();

    let asNumber = parseCurrency(value) || 0;

    if (e.key === "ArrowUp") {
      asNumber += 1;
    } else {
      asNumber -= 1;
    }

    const valueWithinBounds = getValueWithinBounds(asNumber);
    const formattedNumber = formatCurrency(valueWithinBounds, { withDecimal });

    e.target.value = formattedNumber;
    setValue(formattedNumber);

    onAfterChange && onAfterChange(e);
  };

  const {
    onAfterChange,
    onBeforeChange,
    onKeyPress,
    children,
    hasError = false,
    hasWarning = false,
    isDisabled = false,
    isRequired = false,
    labelledby,
    max,
    min = 0,
    placeholder,
    value: initialValue = "",
    withDecimal = true,
  } = props;

  useEffect(() => setValue(getValueWithinBounds(value)), [max, min]);

  const [value, setValue] = useState(initialValue);

  const attrs = {
    className: cx(styles.input, {
      hasError,
      hasWarning,
      hasValue: value,
      isDisabled,
    }),
    disabled: isDisabled,
    ["aria-labelledby"]: labelledby,
    onChange: handleChange,
    onKeyDown: handleKeyDown,
    onKeyPress: handleKeyPress,
    pattern: "^[0-9,.]+",
    placeholder,
    ref,
    required: isRequired,
    type: "text",
    value,
  };

  return (
    <FormField {...props}>
      <div className={styles.container}>
        <input {...attrs} />
      </div>
      {children}
    </FormField>
  );
};

Currency = forwardRef(Currency);

Currency.propTypes = {
  labelOrLabelledby,
  onAfterChange: PropTypes.func,
  onBeforeChange: PropTypes.func,
  onKeyPress: PropTypes.func,
  hasError: PropTypes.bool,
  hasWarning: PropTypes.bool,
  children: PropTypes.node,
  controlledValue: PropTypes.string,
  isDisabled: PropTypes.bool,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  labelledby: PropTypes.string,
  max: PropTypes.number,
  min: PropTypes.number,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  withDecimal: PropTypes.bool,
};

export default Currency;
