import Link from "next/link";
import PropTypes from "prop-types";
import classNames from "classnames/bind";
import { hrefOrOnClick } from "utils/propTypeValidations";
import { ChevronLeft } from "lib/icons";
import styles from "./PageHeader.module.scss";

const cx = classNames.bind(styles);

const PageHeader = ({ backLink, children }) => (
  <header className={cx("container")}>
    {backLink.href && (
      <Link href={backLink.href} className={cx("backLink")}>
        <ChevronLeft />
        {backLink.label}
      </Link>
    )}
    {backLink.onClick && (
      <button className={cx("backLink")} onClick={backLink.onClick}>
        <ChevronLeft />
        {backLink.label}
      </button>
    )}

    {children && <div className={cx("content")}>{children}</div>}
  </header>
);

PageHeader.propTypes = {
  backLink: PropTypes.shape({
    href: PropTypes.string,
    onClick: PropTypes.func,
    label: PropTypes.string.isRequired,
    hrefOrOnClick,
  }).isRequired,
  children: PropTypes.node,
};

export default PageHeader;
