import * as oauth from "./oauth";
import { getSession } from "next-auth/react";
import env from "@beam-australia/react-env";
import { createToken, refreshToken } from "services/oauth";
import profileService from "services/profile";
import { profileResolver } from "resolvers";
import { dayjs } from "utils";
import { Session } from "next-auth";

export const tokenResolver = (data) => ({
  accessToken: data.access_token,
  accessTokenExpires: dayjs.utc(
    data.created_at * 1000 + data.expires_in * 1000
  ),
  refreshToken: data.refresh_token,
});

/**
 * Takes a token, and returns a new token with updated
 * `accessToken` and `accessTokenExpires`. If an error occurs,
 * returns the old token and an error property
 */
export async function refreshAccessToken(token) {
  try {
    const refreshedToken = await refreshToken(token.refreshToken);

    if (refreshedToken.isAxiosError) throw refreshedToken;

    return {
      ...token,
      ...oauth.tokenResolver(refreshedToken),
    };
  } catch (error) {
    return {
      ...token,
      error: "RefreshAccessTokenError",
    };
  }
}

export const authorize = async (credentials) => {
  const res = await createToken(credentials);

  // Return for any response without an `access_token`
  // This is treated as 401 unauthorized by next-auth
  if (!res.access_token) return;

  return res;
};

export const jwt = async ({ token, user }) => {
  // Initial sign in
  if (user) return oauth.tokenResolver(user);

  // Return previous token if the access token has not expired yet
  if (!hasExpired(token.accessTokenExpires)) return token;

  // Access token has expired, try to update it
  return oauth.refreshAccessToken(token);
};

export const redirect = ({ url, baseUrl }) => {
  if (
    // allow redirects to both v2 and v1 respectively
    url.startsWith(baseUrl) ||
    url.startsWith(env("APPLICATIONS_APP"))
  ) {
    return url;
  }

  return baseUrl;
};

export const session = async ({ session, token }) => {
  if (token && token.accessToken) {
    const {
      data: { profile },
    } = await profileService.get(token.accessToken);

    session.accessToken = token.accessToken;
    session.accessTokenExpires = token.accessTokenExpires;
    session.error = token.error;
    session.user = profileResolver(profile);
  }

  return session;
};

const hasExpired = (iso8601) => {
  const soon = dayjs.utc().add(3, "minutes");
  const expires = dayjs.utc(iso8601);

  return expires && expires.isValid() && soon >= expires;
};

const reloadClientSessionState = () => {
  const event = new Event("visibilitychange");

  document.dispatchEvent(event);
};

export const getAccessToken = async (currentSession) => {
  if (!currentSession) {
    return;
  }

  if (!hasExpired(currentSession.accessTokenExpires)) {
    return currentSession.accessToken;
  }

  const newSession: Session & { accessToken?: string } = await getSession();

  // getSession is supposed to update the session state for all
  // windows but this does not work as intended because storage events
  // do not fire for the active window so we need to manually fire a
  // visibilitychange event in order to update the session state for
  // the active window; otherwise, the session state would still
  // contain the expired access token
  //
  // https://github.com/nextauthjs/next-auth/issues/596
  reloadClientSessionState();

  if (!newSession) {
    return;
  }

  return newSession.accessToken;
};

export const signIn = async ({ user }) => {
  if (user && user.otp) {
    return `${process.env.NEXT_PUBLIC_FRONTEND_URL}/login?otp=1`;
  }

  return user && Boolean(user.access_token);
};
