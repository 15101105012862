import {
  MAIN_RESIDENCE_ID,
  SHELTERED_ACCOMMODATION_ID,
  TRUTHY_STRING,
} from "lib/constants";
import { parseCurrency } from "utils";

export const presentPropertyForApiResolver = (data) => {
  return {
    property: {
      postcode: data.postcode,
      ex_local_authority: data.exLocalAuthority === TRUTHY_STRING,
      usage_type: data.propertyType,
      tenure: data.tenure,
      lease_term_remaining: Number(data.remainingLease),
      value: parseCurrency(data.propertyValue),
      valuation_increase_contact: data.independentValuation === TRUTHY_STRING,
      residence_status_id:
        data.shelteredAccommodation === TRUTHY_STRING
          ? SHELTERED_ACCOMMODATION_ID
          : MAIN_RESIDENCE_ID,
    },
  };
};

export default presentPropertyForApiResolver;
