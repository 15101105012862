import PropTypes from "prop-types";
import classNames from "classnames/bind";
import styles from "./BracketInfo.module.scss";

const cx = classNames.bind(styles);

const BrackInfo = ({ appendLabel = false, label, value }) => {
  const Label = () => (
    <span className={cx("label")} data-testid="BracketInfo-label">
      {label}
    </span>
  );
  const Value = () => (
    <span className={cx("value")} data-testid="BracketInfo-value">
      {value}
    </span>
  );

  return (
    <div className={cx("bracketInfo")}>
      <div className={cx("container")}>
        {appendLabel ? (
          <>
            <Value />
            <Label />
          </>
        ) : (
          <>
            <Label />
            <Value />
          </>
        )}
      </div>
    </div>
  );
};

BrackInfo.propTypes = {
  appendLabel: PropTypes.bool,
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default BrackInfo;
