import PropTypes from "prop-types";
import { Button, DropdownButton } from "components";
import styles from "./Title.module.scss";

const Title = ({ applicationNumber, cta, heading, testId }) => {
  const Cta = cta?.options ? DropdownButton : Button;

  return (
    <section className={styles.title} data-testid="title">
      <div className={styles.heading} data-testid={testId}>
        <h1>{heading}</h1>
        {applicationNumber && (
          <span
            aria-label={`Application Number ${applicationNumber}`}
            className={styles.applicationNumber}
          >
            {applicationNumber}
          </span>
        )}
      </div>
      {cta && (
        <div>
          <Cta {...cta} />
        </div>
      )}
    </section>
  );
};

Title.propTypes = {
  cta: PropTypes.shape({
    options: PropTypes.array,
  }),
  applicationNumber: PropTypes.number,
  heading: PropTypes.string.isRequired,
  testId: PropTypes.string,
};

export default Title;
