import { clientsResolver, pagedDataMetaResolver } from "resolvers";

const clientsPageResolver = ({ canGenerateKfiRequests, clientsResponse }) => {
  return {
    clients: clientsResolver({
      canGenerateKfiRequests,
      data: clientsResponse.data,
    }),
    meta: pagedDataMetaResolver({ meta: clientsResponse.meta }),
  };
};

export default clientsPageResolver;
