import { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { ChevronLeft, ChevronRight } from "lib/icons";
import styles from "./TablePagination.module.scss";

const TablePagination = ({
  pageOptions = [10, 25, 50],
  totalItems,
  fromItem,
  toItem,
  currentPage,
  totalPages,
  requestNewPageData,
  isDisabled,
}) => {
  const [perPage, setPerPage] = useState(pageOptions[0]);

  const previousPage = () => handlePageChangeByValue(-1);
  const nextPage = () => handlePageChangeByValue(+1);

  const handlePageChangeByValue = (value) => {
    const newPage = currentPage + value;
    handleRequestNewPageData(newPage, perPage);
  };

  const handlePerPageChange = (event) => {
    setPerPage(event.target.value);
    handleRequestNewPageData(1, event.target.value);
  };

  const handleRequestNewPageData = (pageNo, noOfPages) => {
    requestNewPageData({ page: pageNo, items: noOfPages });
  };

  return (
    <div
      className={classNames(styles.wrapper, {
        [styles.disabled]: isDisabled,
      })}
    >
      <div>
        <label className={styles.controlWrapper}>
          <span className={styles.text}>Rows per page:</span>
          <select
            className={styles.select}
            value={perPage}
            onChange={handlePerPageChange}
            disabled={isDisabled}
          >
            {pageOptions.map((value) => (
              <option key={`pageOption${value}`} value={value}>
                {value}
              </option>
            ))}
          </select>
        </label>
      </div>
      <div className={styles.controlWrapper}>
        <span className={styles.text}>
          Showing {fromItem}-{toItem} of {totalItems}
        </span>
        <button
          className={styles.button}
          onClick={previousPage}
          aria-label="See Previous Data"
          disabled={currentPage === 1 || isDisabled}
        >
          <ChevronLeft />
        </button>
        <button
          className={styles.button}
          onClick={nextPage}
          aria-label="See Next Data"
          disabled={currentPage === totalPages || isDisabled}
        >
          <ChevronRight />
        </button>
      </div>
    </div>
  );
};

TablePagination.propTypes = {
  pageOptions: PropTypes.arrayOf(PropTypes.number),
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  totalItems: PropTypes.number.isRequired,
  fromItem: PropTypes.number.isRequired,
  toItem: PropTypes.number.isRequired,
  requestNewPageData: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
};

export default TablePagination;
