import settingsService from "services/settings";
import { settingsResolver } from "resolvers";
import { NETWORK_ERROR_IDENTIFIER } from "lib/constants";

const settingsModel = async (accessToken) => {
  const settingsResponse = await settingsService(accessToken);

  if (settingsResponse?.message === NETWORK_ERROR_IDENTIFIER) return;

  const {
    data: { settings },
  } = settingsResponse;

  return settingsResolver(settings);
};

export default settingsModel;
