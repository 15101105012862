import PropTypes from "prop-types";
import classNames from "classnames/bind";
import styles from "./EmptyResults.module.scss";
import { EmptySearch, ClearSearch } from "lib/icons";

const cx = classNames.bind(styles);

const EmptyResults = ({ onClearButtonClick, searchedTerm }) => {
  return (
    <div className={cx("container")}>
      <div className={cx("searchLogo")}>
        <EmptySearch />
      </div>
      <div className={cx("resultsText")}>No results found for</div>
      <div className={cx("searchedTerm")}>{searchedTerm}</div>
      <div className={cx("hint")}>
        Please try adjusting your search to find what you are looking for.
      </div>
      <button
        className={cx("clearSearch")}
        type="button"
        onClick={onClearButtonClick}
      >
        Clear search
        <ClearSearch />
      </button>
    </div>
  );
};

EmptyResults.propTypes = {
  onClearButtonClick: PropTypes.func.isRequired,
  searchedTerm: PropTypes.string.isRequired,
};

export default EmptyResults;
