const clientPropertyResolver = ({ client }, referenceNo) => {
  const propertyValue = referenceNo
    ? sessionStorage.getItem(`estimatedPropertyValue-${referenceNo}`)
    : client.property.value;

  return {
    propertyValue: propertyValue,
  };
};

export default clientPropertyResolver;
