import PropTypes from "prop-types";
import classNames from "classnames/bind";
import { BracketInfo } from "components";
import styles from "./AgeNextBirthday.module.scss";
import { useEffect, useState } from "react";

const cx = classNames.bind(styles);

const AgeNextBirthday = ({
  borrower,
  updateRequestData,
  reset,
  setSelected,
}) => {
  const { age, dob, fullName, initialValue } = borrower;
  const [checked, setChecked] = useState(initialValue);
  const [newAge, setNewAge] = useState(initialValue ? age + 1 : age);
  const tag = ["use ANB products"];

  const changeHandler = (event) => {
    setChecked(event.target.checked);

    const label = event.target.checked ? tag : [];
    setSelected(label);

    const anb = event.target.checked ? age + 1 : age;
    setNewAge(anb);

    updateRequestData({
      type: "age_next_birthday",
      value: event.target.checked,
    });
  };

  useEffect(() => {
    if (initialValue) {
      setSelected(tag);
    }
  }, [initialValue]);

  useEffect(() => {
    reset && setChecked(false);
  }, [reset]);

  return (
    <div>
      <div className={cx("checkboxWrapper")}>
        <input
          type="checkbox"
          name="useAgeNextBirthday"
          id="age-next-birthday"
          checked={checked}
          onChange={changeHandler}
        />
        <label htmlFor="age-next-birthday">Use age next birthday</label>
      </div>
      <hr />
      <div className={cx("borrowerInfo")}>
        <div>
          <div>{fullName}</div>
          <div className={cx("dob")}>{dob}</div>
        </div>
        <BracketInfo appendLabel={true} label={"years old"} value={newAge} />
      </div>
    </div>
  );
};

AgeNextBirthday.propTypes = {
  borrower: PropTypes.shape({
    age: PropTypes.number.isRequired,
    dob: PropTypes.string.isRequired,
    fullName: PropTypes.string.isRequired,
    initialValue: PropTypes.bool,
  }).isRequired,
  updateRequestData: PropTypes.func.isRequired,
  reset: PropTypes.number.isRequired,
  setSelected: PropTypes.func.isRequired,
};

export default AgeNextBirthday;
