import PropTypes from "prop-types";
import { useState } from "react";
import classNames from "classnames/bind";
import DropdownControl from "../DropdownControl";
import styles from "./FilterControl.module.scss";

const cx = classNames.bind(styles);

const FilterControl = ({
  align,
  buttonAriaLabel = "Filter",
  buttonText = "Filter",
  buttonTheme = "secondary",
  dropdownTitle,
  formAriaLabel = "Filter Form",
  isDisabled,
  options,
  onSubmit,
}) => {
  const initialState = () => {
    const stateObject = {};
    options.forEach((item) => (stateObject[item.key] = false));
    return stateObject;
  };

  const [value, setValue] = useState(initialState());

  const optionItem = (option, index) => {
    return (
      <li key={index} className={cx("listItem")}>
        <input
          type="checkbox"
          id={option.key}
          checked={value[option.key]}
          onChange={(event) => {
            setValue({ ...value, [option.key]: event.target.checked });
          }}
        />
        <label className={cx("checkboxLabel")} htmlFor={option.key}>
          {option.label}
        </label>
      </li>
    );
  };

  const optionItems = () => {
    return <ul className={cx("controlsWrapper")}>{options.map(optionItem)}</ul>;
  };

  const submitHandler = () => {
    const queryObject = {
      filter: Object.keys(value)
        // eslint-disable-next-line security/detect-object-injection
        .filter((key) => value[key])
        .join(),
    };

    onSubmit(queryObject);
  };

  // eslint-disable-next-line security/detect-object-injection
  const submitIsDisabled = !Object.keys(value).some((key) => value[key]);

  return (
    <DropdownControl
      align={align}
      buttonAriaLabel={buttonAriaLabel}
      buttonText={buttonText}
      buttonTheme={buttonTheme}
      formAriaLabel={formAriaLabel}
      icon="Filter"
      isDisabled={isDisabled}
      dropdownTitle={dropdownTitle}
      onSubmit={submitHandler}
      optionItems={optionItems()}
      options={options}
      optionType="multiple"
      submitIsDisabled={submitIsDisabled}
    />
  );
};

FilterControl.propTypes = {
  align: PropTypes.string,
  buttonAriaLabel: PropTypes.string,
  buttonText: PropTypes.string,
  buttonTheme: PropTypes.oneOf(["primary", "secondary"]),
  dropdownTitle: PropTypes.string.isRequired,
  formAriaLabel: PropTypes.string,
  isDisabled: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({ key: PropTypes.string, label: PropTypes.string })
  ).isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default FilterControl;
