import { linkFor } from "utils";
import {
  APPLICATIONS,
  CHANGE_PASSWORD,
  CLIENTS,
  DASHBOARD,
  DETAILS,
  FURTHER_ADVANCES,
} from "lib/constants";

export default {
  links: [
    {
      href: linkFor("dashboard"),
      label: DASHBOARD,
    },
    {
      href: linkFor("clients"),
      label: CLIENTS,
    },
    {
      href: linkFor("applications"),
      label: APPLICATIONS,
    },
    {
      href: linkFor("furtherAdvances"),
      label: FURTHER_ADVANCES,
    },
    {
      href: linkFor("profile"),
      label: DETAILS,
    },
    {
      href: linkFor("changePassword"),
      label: CHANGE_PASSWORD,
    },
  ],
};
