import { useState, useEffect, useRef } from "react";

export default function useDropdownVisible(initialVisibility) {
  const [isVisible, setIsVisible] = useState(initialVisibility);
  const ref = useRef(null);

  const toggleVisibility = () => {
    setIsVisible((value) => !value);
  };

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  });

  return { ref, isVisible, toggleVisibility };
}
