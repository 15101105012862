/* eslint-disable react/display-name */
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import ReactModal from "react-modal";
import classNames from "classnames/bind";
import env from "@beam-australia/react-env";
import { CloseCircleIcon } from "lib/icons";
import { oneOfProps } from "utils/propTypeValidations";
import styles from "./Modal.module.scss";

const cx = classNames.bind(styles);
env("APP_ENV") !== "test" && ReactModal.setAppElement("#__next");

const Modal = (props) => {
  const {
    contentLabel,
    title,
    isOpen: initialIsOpenValue,
    onAfterClose,
    onAfterOpen,
    onCloseButtonClick,
    onRequestClose,
  } = props;

  const [isOpen, setIsOpen] = useState(initialIsOpenValue);
  const [isClosing, setIsClosing] = useState(false);

  const handleCloseButtonClick = (e) => {
    onCloseButtonClick && onCloseButtonClick(e);
    closeModal();
  };

  const handleAfterOpen = (e) => {
    onAfterOpen && onAfterOpen(e);
    openModal();
  };

  const handleAfterClose = (e) => {
    onAfterClose && onAfterClose(e);
    setIsOpen(false);
    setIsClosing(false);
  };

  const handleRequestClose = (e) => {
    onRequestClose && onRequestClose(e);
    setIsOpen(false);
    setIsClosing(true);
  };

  const openModal = () => {
    setIsOpen(true);
    setIsClosing(false);
  };

  const closeModal = () => {
    setIsOpen(false);
    setIsClosing(true);
  };

  const args = {
    className: styles.content,
    closeTimeoutMS: 300,
    contentLabel: contentLabel || title,
    overlayClassName: styles.overlay,
    onAfterClose: handleAfterClose,
    onAfterOpen: handleAfterOpen,
    onRequestClose: handleRequestClose,
    ...props,
  };

  useEffect(
    () => (initialIsOpenValue ? openModal() : closeModal()),
    [initialIsOpenValue]
  );

  return (
    <ReactModal
      {...args}
      isOpen={isOpen}
      portalClassName={cx(styles.modal, { isOpen, isClosing, hasTitle: title })}
    >
      <div className={styles.header}>
        {props.title && <span className={styles.title}>{props.title}</span>}
        <button
          aria-label="Close modal"
          className={styles.closeButton}
          onClick={handleCloseButtonClick}
        >
          <span className="hover">Close</span>
          <CloseCircleIcon />
        </button>
      </div>
      {props.children}
    </ReactModal>
  );
};

Modal.propTypes = {
  contentLabelOrTitle: (...args) =>
    oneOfProps(args, ["contentLabel", "title"], true),
  children: PropTypes.node.isRequired,
  contentLabel: PropTypes.string,
  isOpen: PropTypes.bool,
  onAfterClose: PropTypes.func,
  onAfterOpen: PropTypes.func,
  onCloseButtonClick: PropTypes.func,
  onRequestClose: PropTypes.func,
  title: PropTypes.string,
};

export default Modal;
