import classNames from "classnames/bind";
import { BorrowerProvider } from "context";
import settingsModel from "models/settings";
import { useSession } from "next-auth/react";
import { useEffect, useState } from "react";
import { getAccessToken } from "utils/oauth";
import PremBotClient from "./Client";
import styles from "./PremBot.module.scss";

const cx = classNames.bind(styles);

const PremBot = () => {
  const { data: session } = useSession();
  const [settings, setSettings] = useState();

  const sessionSettings = async () => {
    const accessToken = await getAccessToken(session);
    setSettings(await settingsModel(accessToken));
  };

  useEffect(() => {
    sessionSettings();
  }, [session]);

  return !settings ? (
    "Loading PremBot"
  ) : (
    <div className={cx("container")} data-testid="prembot-container">
      <BorrowerProvider sessionStorageKey={"clientDetails-premBot"}>
        <PremBotClient settings={settings} />
      </BorrowerProvider>
    </div>
  );
};

export default PremBot;
