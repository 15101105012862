import { SHORT_DATE_FORMAT } from "lib/constants";
import { dayjs } from "utils";
import prettyBytes from "pretty-bytes";

const additionalDocumentsResolver = (documents) => {
  let documentsArray = [];

  const documentHash = (document) => {
    return {
      title: document.label,
      size:
        document.document_size && `(${prettyBytes(document.document_size)})`,
      href: document.document,
      createdAt: dayjs.utc(document.created_at).format(SHORT_DATE_FORMAT),
      documentId: document.id,
      sourceType: "internalDownload",
    };
  };

  documents.map((document) => {
    if (document.document) {
      documentsArray.push(documentHash(document));
    }
  });

  return documentsArray;
};

export default additionalDocumentsResolver;
