import { borrowersResolver } from "resolvers";
import { linkFor, removeFalseyArrayItems } from "utils";
import {
  ADVISER_DETAILS_TYPE_UPDATE,
  ADVISER_DETAILS_TYPE_NEW,
  ADVISER_DETAILS_TYPE_NEW_CLIENT_EDIT,
} from "lib/constants";

const adviserDetailsPageResolver = ({ data, type }) => {
  switch (type) {
    case ADVISER_DETAILS_TYPE_UPDATE:
    case ADVISER_DETAILS_TYPE_NEW_CLIENT_EDIT:
      return {
        base: {
          meta: { title: "Adviser details" },
          header: {
            breadcrumbs: removeFalseyArrayItems([
              { title: "Dashboard", href: linkFor("dashboard") },
              { title: "Clients", href: linkFor("clients") },
              data && {
                title: borrowersResolver(data.client.borrowers).fullName,
                href: linkFor("client", { clientId: data.client.id }),
              },
              { title: "Adviser details", isCurrent: true },
            ]),
            title: { heading: "Adviser details" },
          },
        },
        clientId: data && data.client.id,
        type,
        clubId: data && data.client.club.id?.toString(),
      };

    case ADVISER_DETAILS_TYPE_NEW:
      return {
        base: {
          meta: { title: "New client" },
          header: {
            breadcrumbs: [
              { title: "Dashboard", href: linkFor("dashboard") },
              { title: "New client", isCurrent: true },
            ],
            title: { heading: "New client" },
            progress: 20,
          },
        },
        type,
      };
  }
};

export default adviserDetailsPageResolver;
