import { booleanToText, linkFor } from "utils";

const lendingCheckPageResolver = ({ clientId, property } = {}) => {
  let exLocalAuthority;
  let postcode;

  if (property) {
    exLocalAuthority = booleanToText(property.ex_local_authority);
    postcode = property.postcode;
  }

  return {
    base: {
      meta: { title: "New client" },
      header: {
        breadcrumbs: [
          { title: "Dashboard", href: linkFor("dashboard") },
          { title: "New client", isCurrent: true },
        ],
        title: {
          heading: "New client",
          testId: "new_client_lending_check_title",
        },
        progress: 0.5,
      },
    },
    // Only include items if present
    ...(clientId ? { clientId } : {}),
    ...(exLocalAuthority ? { exLocalAuthority } : {}),
    ...(postcode ? { postcode } : {}),
  };
};

export default lendingCheckPageResolver;
